import type { BoxProps } from "@chakra-ui/react";
import { Box, chakra, Flex, Skeleton } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import useApiQuery from "lib/api/useApiQuery";
import getCurrencyValue from "lib/utils/getCurrencyValue";
import _ from "lodash";
import { memo, useMemo, type ReactNode } from "react";
import type { OSType } from "types/base";
import TruncatedTextTooltip from "./truncate/TruncatedTextTooltip";

type Props = {
  value?: string | undefined | null | BigNumber | number;
  currency?: ReactNode | null;
  className?: string;
  isLoading?: boolean;
  accuracyUsd?: number | "full" | null;
  decimals?: string | number | null | BigNumber;
  exchangeRate?: string | null;
  fiatBalance?: string | number | BigNumber | null | undefined;
  osType?: OSType;
  prefix?: ReactNode;
  postfix?: ReactNode;
  accuracy?: number | "full";
  defaultIsTruncated?: boolean;
  fallback?: ReactNode;
  fallbackUsd?: ReactNode;
  stickyCurrency?: boolean;
  usdProps?: BoxProps;
  contentProps?: BoxProps;
  usdHasParenthesis?: boolean;
  hasParenthesis?: boolean;
  autoPrice?: string | null | undefined | true;
  hideValue?: boolean;
  getExchangeRate?: boolean;
  usdCurrency?: ReactNode;
} & Omit<BoxProps, "prefix" | "postfix">;

const CurrencyValue = ({
  value,
  osType,
  decimals,
  isLoading,
  accuracyUsd,
  exchangeRate,
  currency = "",
  isTruncated,
  prefix,
  postfix,
  accuracy,
  defaultIsTruncated,
  fallback = "-",
  fallbackUsd = "-",
  stickyCurrency = true,
  fiatBalance,
  usdProps,
  contentProps,
  usdHasParenthesis,
  hasParenthesis,
  autoPrice,
  hideValue,
  getExchangeRate,
  usdCurrency = "$",
  ...props
}: Props) => {
  const { data: usdPrice, isFetching } = useApiQuery("asset_info", {
    queryParams: {
      identifier: autoPrice === true ? "usei" : autoPrice || undefined,
    },
    queryOptions: {
      select: (data) => {
        return data.usd_price;
      },
      staleTime: 300000, // 5 minutes
      enabled: Boolean(
        autoPrice &&
          isNaN(Number(exchangeRate ?? NaN)) &&
          isNaN(Number(fiatBalance ?? NaN)) &&
          !isLoading,
      ),
    },
  });

  const {
    valueFull,
    valueFormated,
    usdFull,
    usdFormated,
    valueIsTruncated,
    usdIsTruncated,
    value: valueBn,
    isFallbackUsd,
  } = useMemo(() => {
    if (isLoading)
      return {
        valueFormated: "",
        usdFormated: "",
        valueFull: "",
        valueIsTruncated: false,
        usdIsTruncated: false,
        usdFull: "",
        value: undefined,
        usd: undefined,
        isFallbackValue: false,
        isFallbackUsd: false,
      };
    const pickedDecimals = _.chain(null)
      .thru(() => {
        const decimalsBn = BigNumber(decimals ?? NaN);
        if (!decimalsBn.isNaN()) return decimalsBn;

        if (osType === "EVM") return BigNumber(18);
        if (osType === "Cosmos") return BigNumber(6);

        return BigNumber(0);
      })
      .value();

    return getCurrencyValue({
      value: value,
      accuracyUsd,
      exchangeRate: exchangeRate || usdPrice,
      decimals: pickedDecimals,
      accuracy,
      fiatBalance,
      fallback: fallback,
      fallbackUsd: fallbackUsd,
      hideValue,
      getExchangeRate,
      usdCurrency,
    });
  }, [
    value,
    accuracyUsd,
    exchangeRate,
    decimals,
    osType,
    accuracy,
    fiatBalance,
    accuracyUsd,
    fallback,
    fallbackUsd,
    isLoading,
    usdPrice,
    hideValue,
    getExchangeRate,
    usdCurrency,
  ]);

  if (isLoading || isFetching) {
    return (
      <Skeleton {...props} display="inline-block">
        <span>0.000000</span>
      </Skeleton>
    );
  }

  if (!valueBn) {
    return (
      <Box as="span" {...props}>
        <span>{fallback}</span>
      </Box>
    );
  }

  return (
    <Flex
      display="inline-flex"
      gap={1}
      alignItems="center"
      overflow="hidden"
      maxWidth="full"
      borderColor="neutral.light.4"
      {...props}
    >
      {!hideValue && (
        <Flex alignItems="center" overflow="hidden" gap={1} {...contentProps}>
          {Boolean(prefix) && <span>{prefix}</span>}
          <TruncatedTextTooltip
            highPriorityIsTruncated={valueIsTruncated}
            defaultIsTruncated={defaultIsTruncated}
            isTruncated
            label={
              <Flex alignItems="center">
                {valueFull}
                {currency && (
                  <>
                    {stickyCurrency && " "}
                    {currency}
                  </>
                )}
              </Flex>
            }
          >
            <chakra.span
              alignItems="center"
              wordBreak="break-word"
              color="inherit"
              flexWrap="wrap"
              justifyContent="flex-end"
              display={isTruncated ? "inline-block" : "inline-flex"}
              isTruncated={isTruncated}
            >
              {hasParenthesis && "("}
              {valueFormated}
              {currency && (
                <>
                  {stickyCurrency && " "}
                  {currency}
                </>
              )}
              {hasParenthesis && ")"}
            </chakra.span>
          </TruncatedTextTooltip>
          {Boolean(postfix) && <span>{postfix}</span>}
        </Flex>
      )}

      {usdFormated && (
        <TruncatedTextTooltip
          highPriorityIsTruncated={
            accuracyUsd === "full" ? undefined : usdIsTruncated
          }
          defaultIsTruncated={defaultIsTruncated}
          isDisabled={isFallbackUsd}
          label={usdFull}
        >
          <chakra.span
            textStyle="875"
            color="neutral.light.6"
            display={isTruncated ? "inline-block" : "inline-flex"}
            isTruncated={isTruncated}
            {...usdProps}
          >
            {usdHasParenthesis && "("}
            {usdFormated}
            {usdHasParenthesis && ")"}
          </chakra.span>
        </TruncatedTextTooltip>
      )}
    </Flex>
  );
};

export default memo(CurrencyValue);
