import { Flex, VStack } from "@chakra-ui/react";
import { memo, useCallback, useContext } from "react";
import type {
  SidebarItem,
  SideBarSectionItem,
} from "ui/shared/layout/LayoutSidebar/types";
import { LayoutContext } from "ui/shared/layout/components/LayoutProvider";
import { useSnapshot } from "valtio";
import ProfileDivider from "./components/ProfileDivider";
import ProfileNavButton from "./components/ProfileNavButton";
import ProfileSignOutButton from "./components/ProfileSignOutGroup";

type Props = {
  onClose: AnyFunction;
  navs: SideBarSectionItem[];
};

const ProfileMenuItems = ({ navs, onClose }: Props) => {
  const { store } = useContext(LayoutContext);
  const snap = useSnapshot(store);

  const onClick = useCallback((item: SidebarItem) => {
    if (!item.href) return;
    onClose();
    store.onChange({ tabId: item.id, href: item.href });
  }, []);

  return (
    <Flex
      flexDirection="column"
      alignItems="stretch"
      width="100%"
      overflow="hidden"
      gap={4}
    >
      <VStack alignItems="stretch" gap={1}>
        <ProfileDivider></ProfileDivider>
        {navs.map((section) =>
          section.items
            ?.filter((item) => item.isHidden !== true)
            .map((item) => {
              return (
                <ProfileNavButton
                  key={item.id}
                  title={item.title}
                  icon={item.icon}
                  isDisabled={item.isDisabled}
                  height="3rem"
                  padding={3}
                  gap={3}
                  variant="unstyled"
                  color="neutral.light.7"
                  borderRadius="0.5rem"
                  display="flex"
                  alignItems="center"
                  isSelected={
                    item.id === "account" && snap.tab_id === "default"
                      ? true
                      : snap.tab_id === item.id
                  }
                  onClick={() => onClick(item)}
                ></ProfileNavButton>
              );
            }),
        )}
        <ProfileDivider></ProfileDivider>
        <ProfileSignOutButton></ProfileSignOutButton>
      </VStack>
    </Flex>
  );
};

export default memo(ProfileMenuItems, (prev, next) => {
  return prev.navs === next.navs;
});
