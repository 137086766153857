import { useToast } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import useApiFetch from "lib/api/useApiFetch";
import { useCallback, useEffect } from "react";
import type { SubmitErrorHandler, SubmitHandler } from "react-hook-form";
import { Controller, FormProvider, useForm } from "react-hook-form";
import type { WorkspaceUserInfo } from "types/api/account";
import TextInputFloating from "ui/shared/forms/TextInputFloating";
import ProfileAccountSection from "../ProfileAccountSection";
import type { IProfileUserSettingsForm } from "./types";
import { userSettingsSchema } from "./utils";

type Props = {
  data?: WorkspaceUserInfo;
  isLoading?: boolean;
  isSocialLogin?: boolean;
  onSubmitted?: (data: any) => void;
};

const ProfileUserSettingsForm = ({
  data,
  isLoading,
  isSocialLogin,
  onSubmitted,
}: Props) => {
  // const modalRef = useRef<ProfileContentState | null>(null);
  const formApi = useForm<IProfileUserSettingsForm>({
    defaultValues: {
      fullname: data?.name || "",
      email: data?.email || "",
    },
    resolver: zodResolver(userSettingsSchema),
  });
  const toast = useToast();

  const { control } = formApi;
  const { handleSubmit, reset } = formApi;
  const apiFetch = useApiFetch();

  useEffect(() => {
    reset({
      fullname: data?.name || "",
      email: data?.email || "",
    });
  }, [data]);

  const onValid: SubmitHandler<IProfileUserSettingsForm> = useCallback(
    async (data) => {
      try {
        const body = {
          name: data.fullname,
          username: "",
        };

        const response = await apiFetch("workspace_update_userinfo", {
          fetchParams: { method: "PATCH", body },
        });
        console.info(`Updated user info: ${response}`);
        onSubmitted?.(true);
        toast({
          position: "top-right",
          title: "Success",
          description: "Updated your profile successfully.",
          status: "success",
          variant: "subtle",
          isClosable: true,
        });
      } catch (error) {
        console.warn(`Error updating user info: ${error}`);
        toast({
          position: "top-right",
          title: "Error",
          description: (
            <>
              Can not update your profile.
              <br />
              Please try again.
            </>
          ),
          status: "error",
          variant: "subtle",
          isClosable: true,
        });
      }
    },
    [],
  );
  const onError: SubmitErrorHandler<IProfileUserSettingsForm> = useCallback(
    (errors) => {
      console.log(errors);
    },
    [],
  );

  return (
    <FormProvider {...formApi}>
      <ProfileAccountSection
        title="User settings"
        actionTitle="Save changes"
        onSubmit={handleSubmit(onValid, onError)}
        position="relative"
        isLoading={isLoading}
        isSocialLogin={isSocialLogin}
      >
        <Controller
          control={control}
          name="fullname"
          render={({
            field: { value: fullname, onChange, ...fieldProps },
            fieldState: { error },
            formState: { isSubmitting },
          }) => {
            return (
              <TextInputFloating
                {...fieldProps}
                labelProps={{ height: "3.375rem" }}
                placeholder="Name"
                isRequired
                onChange={onChange}
                value={fullname}
                error={error}
                isSubmitting={isSubmitting}
                isDisabled={isLoading}
              ></TextInputFloating>
            );
          }}
        ></Controller>

        <Controller
          control={control}
          name="email"
          render={({
            field: { value: email, onChange, ...fieldProps },
            fieldState: { error },
            formState: { isSubmitting },
          }) => {
            return (
              <TextInputFloating
                {...fieldProps}
                labelProps={{ height: "3.375rem" }}
                onChange={onChange}
                value={email}
                error={error}
                isSubmitting={isSubmitting}
                isRequired
                placeholder="Email address"
                isDisabled={true}
              ></TextInputFloating>
            );
          }}
        ></Controller>
      </ProfileAccountSection>
    </FormProvider>
  );
};

export default ProfileUserSettingsForm;
