import { Box, Flex, Heading } from "@chakra-ui/react";

import { memo } from "react";
import LatestBlocks from "ui/home/LatestBlocks";
import LatestTxs from "ui/home/LatestTxs";
import Stats from "ui/home/Stats";
import ChainIndicatorsV3 from "ui/home/indicators/ChainIndicatorsV3";
import Promotion from "ui/promotion/Promotion";
import OptimizationImage from "ui/shared/OptimizationImage";
import SearchBarTop from "ui/snippets/searchBar/SearchBarTop";

const Home = () => {
  return (
    <>
      <Flex
        flexDirection={{ base: "column", "2lg": "row" }}
        columnGap={5}
        rowGap={4}
      >
        <Flex flex={1} display="flex" flexDirection="column" gap={4}>
          <Flex
            position="relative"
            flexDirection="column"
            width="full"
            height={{ lg: "9rem" }}
            borderRadius="0.5rem"
          >
            <Box
              boxSize="full"
              position="absolute"
              inset={0}
              overflow="hidden"
              borderRadius="0.5rem"
            >
              <OptimizationImage
                objectFit="cover"
                background="linear-gradient(to right, #FF4500, #FF6347, #8B4513, #4B0082, #00008B)"
                alt="home_background"
                src="/images/home_background.jpeg"
                hasWrapper
                wrapperProps={{
                  maxWidth: "full",
                  maxHeight: "full",
                  width: "full",
                  height: "full",
                  transform: "scale(1.5) translateZ(0)",
                  position: "relative",
                }}
              ></OptimizationImage>
            </Box>

            <Flex
              flexDirection="column"
              // position="absolute"
              zIndex={2}
              gap={3}
              padding={5}
              inset={0}
              justifyContent="space-between"
            >
              <Flex justifyContent="space-between" alignItems="center">
                <Heading
                  as="h1"
                  fontSize="2.25rem"
                  lineHeight="2.75rem"
                  fontWeight={500}
                  letterSpacing="-0.0675rem"
                  color="neutral.light.1"
                >
                  SEI Network Explorer
                </Heading>
              </Flex>
              <SearchBarTop
                contentProps={{
                  minHeight: {
                    base: "18rem",
                    lg: "20rem",
                  },
                  maxHeight: {
                    base: "30rem",
                    lg: "30rem",
                  },
                }}
                searchInputProps={{
                  onClick: (e) => {
                    const current = e.currentTarget;
                    if (!current) return;
                    setTimeout(() => {
                      current?.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });
                    }, 300);
                  },
                  groupProps: {
                    width: "full",
                    height: "3rem",
                    _hover: {
                      borderColor: "inherit",
                    },
                  },
                  placeholder:
                    "Search by Address / Txn Hash / Block / Token  / NFT collection",
                }}
                isFullscreen={false}
              />
            </Flex>
          </Flex>
          <Promotion></Promotion>
          <Stats />
        </Flex>
        <Flex width={{ base: "full", "2lg": "27.5rem" }}>
          <ChainIndicatorsV3 />
        </Flex>
      </Flex>
      <Flex
        mt={8}
        direction={{ base: "column", lg: "row" }}
        columnGap={4}
        rowGap={5}
      >
        <LatestBlocks />

        <LatestTxs />
      </Flex>
    </>
  );
};

export default memo(Home, () => true);
