import type { ButtonProps, FlexProps } from "@chakra-ui/react";
import { Button, Flex, Popover, Text, Tooltip } from "@chakra-ui/react";
import type { MouseEventHandler, ReactNode } from "react";
import type { FormState } from "react-hook-form";
import { useFormContext } from "react-hook-form";

type Props = {
  title?: string;
  children?: ReactNode;
  actionTitle?: string;
  actionProps?: ButtonProps;
  onSubmit?: MouseEventHandler<HTMLButtonElement> | undefined;
  isLoading?: boolean;
  isSocialLogin?: boolean;
} & Partial<FlexProps> &
  Partial<Pick<FormState<object>, "isSubmitting">>;

const ProfileAccountSection = ({
  title,
  actionProps,
  actionTitle,
  children,
  onSubmit,
  isLoading,
  isSubmitting,
  isSocialLogin,
  ...props
}: Props) => {
  const formApi = useFormContext();

  const content = (
    <Button
      variant="solid"
      height="2.25rem"
      marginLeft="auto"
      padding="0.5rem 0.625rem"
      width="fit-content"
      onClick={(e) => {
        onSubmit && onSubmit(e);
      }}
      isDisabled={isSocialLogin || isLoading || !formApi.formState.isDirty}
      isLoading={isSubmitting || formApi?.formState?.isSubmitting}
      {...actionProps}
      _disabled={{
        pointerEvents: "not-allowed",
        color: "primary.light.2",
        bg: "primary.light.1",
        _hover: {
          color: "primary.light.2",
          bg: "primary.light.1",
        },
      }}
    >
      {actionTitle}
    </Button>
  );

  return (
    <Flex flexDirection="column" gap={3} {...props}>
      {title && (
        <Text textStyle="1125" fontWeight={500} color="neutral.light.8">
          {title}
        </Text>
      )}

      <Popover isOpen>{children}</Popover>
      {actionTitle && isSocialLogin && (
        <Tooltip label="Not support for social login">{content}</Tooltip>
      )}
      {actionTitle && !isSocialLogin && content}
    </Flex>
  );
};

export default ProfileAccountSection;
