import type { RawTracesResponse } from "types/api/rawTrace";
import type {
  NativeEvent,
  NativeMessage,
  Transaction,
} from "types/api/transaction";
import { ADDRESS_ASSOCIATION } from "./transaction";

export const TX_HASH =
  "0x3ed9d81e7c1001bdda1caa1dc62c0acbbe3d2c671cdc20dc1e65efdaa4186967";

export const TX_HASH_SEI =
  "FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF";

export const TX: Transaction<"EVM"> = {
  timestamp: "2022-11-11T11:11:11.000000Z",
  memo: "",
  fee: {
    type: "actual",
    value: "0000000000000000",
  },
  gas_limit: "0000000",
  block: 0,
  status: "ok",
  method: "0x000000",
  confirmations: 0,
  type: 0,
  exchange_rate: null,
  to: {
    hash: "0x00000000000000000000000000000000000000000000",
    implementations: null,
    is_contract: true,
    is_verified: false,
    name: null,
    public_tags: [],
  },
  tx_burnt_fee: "0",
  max_fee_per_gas: "1000000000",
  result: "success",
  hash: "0x000000000000000000000000000000000000000000000000000000000000000000",
  gas_price: "0000000000",
  priority_fee: "00000000000000000",
  base_fee_per_gas: "0",
  from: {
    hash: "0x0000000000000000000000",
    implementations: null,
    is_contract: false,
    is_verified: false,
    name: null,
    public_tags: [],
  },
  token_transfers: null,
  tx_types: ["contract_call"],
  gas_used: "199140",
  created_contract: {
    hash: "0x0000000000000000000000",
    implementations: null,
    is_contract: true,
    is_verified: false,
    name: null,
    public_tags: [],
  },
  position: 0,
  nonce: 0,
  has_error_in_internal_txs: null,
  actions: [],
  decoded_input: null,
  token_transfers_overflow: null,
  raw_input:
    "0x00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
  value: "0",
  max_priority_fee_per_gas: "0000000000",
  revert_reason: null,
  confirmation_duration: [0, 155],
  tx_tag: null,
  association: ADDRESS_ASSOCIATION,
  __transactionType: "EVM",
};
export const NATIVE_EVENT: NativeEvent = {
  block_height: 0,
  id: 0,
  source: "TX_EVENT",
  tx_msg_index: null,
  type: "tx",
  tx_id: 0,
  attributes: [
    {
      block_height: 0,
      composite_key: "tx.fee",
      event_id: 0,
      index: 0,
      key: "fee",
      value: "0usei",
      tx_id: 0,
    },
    {
      block_height: 0,
      composite_key: "tx.fee_payer",
      event_id: 0,
      index: 1,
      key: "fee_payer",
      value: "sei00000000000000000000000000000000000000",
      tx_id: 0,
    },
  ],
};

export const NATIVE_MESSAGE: NativeMessage = {
  content: {
    "@type": "/seiprotocol.seichain.oracle.MsgAggregateExchangeRateVote",
    sender: "sei00000000000000000000000000000000000000",
    validator: "seivaloper0000000000000000000000000000000000",
    exchange_rates:
      "7.585973456072445569uatom,67749.877493773575985221ubtc,3533.196113578303681621ueth,0.628058519660522243uosmo,0.467415432902765867usei,1.000114574265341036uusdc,0.999769384681265697uusdt",
  },
  id: 0,
  index: 0,
  parent_id: null,
  sender: "sei00000000000000000000000000000000000000",
  sender_stats: {
    address: "sei00000000000000000000000000000000000000",
    sc_id: null,
    sc_name: null,
  },
  tx_id: 0,
  type: "/seiprotocol.seichain.oracle.MsgAggregateExchangeRateVote",
  events: [
    {
      block_height: 0,
      id: 0,
      source: "MSG_EVENT",
      tx_id: 0,
      tx_msg_index: 0,
      type: "message",
      attributes: [
        {
          block_height: 0,
          composite_key: "message.action",
          event_id: 0,
          index: 0,
          key: "action",
          value: "/seiprotocol.seichain.oracle.MsgAggregateExchangeRateVote",
          tx_id: 0,
        },
        {
          block_height: 0,
          composite_key: "message.module",
          event_id: 0,
          index: 1,
          key: "module",
          value: "oracle",
          tx_id: 0,
        },
        {
          block_height: 0,
          composite_key: "message.sender",
          event_id: 0,
          index: 2,
          key: "sender",
          value: "sei00000000000000000000000000000000000000",
          tx_id: 0,
        },
      ],
    },
    {
      block_height: 0,
      id: 0,
      source: "MSG_EVENT",
      tx_msg_index: 0,
      type: "aggregate_vote",
      tx_id: 0,
      attributes: [
        {
          block_height: 0,
          composite_key: "aggregate_vote.voter",
          event_id: 0,
          index: 0,
          key: "voter",
          value: "seivaloper0000000000000000000000000000000000",
          tx_id: 0,
        },
        {
          block_height: 0,
          composite_key: "aggregate_vote.exchange_rates",
          event_id: 0,
          index: 1,
          key: "exchange_rates",
          value:
            "7.585973456072445569uatom,67749.877493773575985221ubtc,3533.196113578303681621ueth,0.628058519660522243uosmo,0.467415432902765867usei,1.000114574265341036uusdc,0.999769384681265697uusdt",
          tx_id: 0,
        },
      ],
    },
  ],
};

export const TX_COSMOS: Transaction<"Cosmos"> = {
  timestamp: "2022-11-11T11:11:11.000000Z",
  memo: "",
  fee: {
    type: "actual",
    value: "0",
  },
  gas_limit: "0",
  block: 0,
  status: "ok",
  method: "MsgAggregateExchangeRateVote",
  confirmations: 0,
  type: 0,
  exchange_rate: null,
  to: {
    hash: "sei00000000000000000000000000000000000000",
    implementations: null,
    is_contract: false,
    is_verified: null,
    name: null,
    public_tags: [],
  },
  tx_burnt_fee: "0",
  max_fee_per_gas: "0",
  result: "success",
  hash: "C30B2357759A73C47A4A848993B73524C3790A40B0699F61621294F7F4927D50",
  gas_price: "0",
  priority_fee: "0",
  base_fee_per_gas: "0",
  from: {
    hash: "sei00000000000000000000000000000000000000",
    implementations: null,
    is_contract: false,
    is_verified: false,
    name: null,
    public_tags: [],
  },
  token_transfers: null,
  tx_types: ["MsgAggregateExchangeRateVote"],
  gas_used: "0",
  created_contract: null,
  position: 0,
  nonce: 0,
  has_error_in_internal_txs: null,
  actions: [],
  decoded_input: null,
  token_transfers_overflow: null,
  raw_input:
    "CjsKOS9zZWlwcm90b2NvbC5zZWljaGFpbi5vcmFjbGUuTXNnQWdncmVnYXRlRXhjaGFuZ2VSYXRlVm90ZQ==",
  value: "0",
  max_priority_fee_per_gas: "0",
  revert_reason: null,
  confirmation_duration: [],
  tx_tag: null,
  __transactionType: "Cosmos",
  signers: ["sei00000000000000000000000000000000000000"],
  native_events: [NATIVE_EVENT],
  native_messages: [NATIVE_MESSAGE],
};

export const TX_RAW_TRACE: RawTracesResponse = [];
