import { memo, useCallback, useContext } from "react";
import type { WorkspaceUserInfo } from "types/api/account";
import { LayoutContext } from "ui/shared/layout/components/LayoutProvider";
import ProfileAvatar from "./components/ProfileAvatar";

type Props = {
  user: WorkspaceUserInfo;
  onClose: AnyFunction;
};

const ProfileMenuAvatar = ({ user, onClose }: Props) => {
  const { store } = useContext(LayoutContext);

  const clickDefault = useCallback(() => {
    onClose();
    store.onChange({ tabId: "default", href: "/auth/profile" });
  }, []);

  return (
    <ProfileAvatar
      gap={6}
      data={user}
      paddingX={0}
      avatarProps={{
        wrapperProps: { boxSize: "4.5rem", display: "flex", flexShrink: 0 },
      }}
      flexDirection="column"
      nameProps={{ textStyle: "1125500", color: "neutral.light.8" }}
      emailProps={{ textStyle: "875", color: "neutral.light.7" }}
      sx={{
        "& .profile-sidebar-header_text": {
          alignItems: "center",
        },
        "& .profile-sidebar-header_container": {
          flexDirection: "column",
          alignItems: "center",
          gap: 6,
        },
      }}
      onClickName={clickDefault}
      hasToggle={false}
    ></ProfileAvatar>
  );
};

export default memo(ProfileMenuAvatar, (prev, next) => prev.user === next.user);
