const zIndices = {
  hide: -1,
  auto: "auto",
  base: 0,
  docked: 10,
  dropdown: 998,
  sticky: 997,
  sticky1: 996,
  sticky2: 995,
  banner: 994,
  overlay: 998,
  modal: 998,
  popover: 998,
  tooltip: 1000, // otherwise tooltips will not be visible in modals
  skipLink: 998,
  toast: 1700,
};

export default zIndices;
