import { Grid, GridItem, HStack, Text, VStack } from "@chakra-ui/react";

import { memo } from "react";
import type { GasPrices } from "types/api/stats";
import Divider from "../Divider";
import Hint from "../Hint";

const GasInfoTooltipContent = ({
  gasPrices,
}: {
  gasPrices: GasPrices | undefined | null;
}) => {
  if (!gasPrices) return null;
  return (
    <VStack
      alignItems="stretch"
      spacing="0.375rem"
      paddingX={4}
      paddingY={3}
      width="11.75rem"
      maxWidth="calc(100vw - 2rem)"
      color="neutral.light.1"
      textStyle="625"
      textAlign="left"
    >
      <Grid
        templateColumns="repeat(2, max-content)"
        rowGap={2}
        columnGap={4}
        fontSize="xs"
        flex={1}
        justifyContent="space-between"
        alignItems="stretch"
      >
        <GridItem color="#BADEF3">Slow</GridItem>
        <GridItem>
          {gasPrices.slow !== null ? `${gasPrices.slow} nsei` : "N/A"}
        </GridItem>
        <GridItem color="#BADEF3">Average</GridItem>
        <GridItem>
          {gasPrices.average !== null ? `${gasPrices.average} nsei` : "N/A"}
        </GridItem>
        <GridItem color="#BADEF3">Fast</GridItem>
        <GridItem>
          {gasPrices.fast !== null ? `${gasPrices.fast} nsei` : "N/A"}
        </GridItem>
      </Grid>
      <Divider backgroundColor="neutral.light.3" />
      <HStack alignItems="flex-start" justifySelf="stretch" spacing="0.25rem">
        <Hint label="" boxSize={4} color="gray.600" mt="-1px" />
        <Text
          as="i"
          fontSize="0.75rem"
          whiteSpace="wrap"
          wordBreak="break-word"
          color="inherit"
        >
          The gas calculation is based on latest 1,000 blocks
        </Text>
      </HStack>
    </VStack>
  );
};

export default memo(GasInfoTooltipContent, (prev, next) => {
  return prev.gasPrices === next.gasPrices;
});
