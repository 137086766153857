import { getEnvValueV2 } from "configs/frontend/chain/configs";
import _ from "lodash";
import type { SideBarSectionItem } from "ui/shared/layout/LayoutSidebar/types";

export enum WORKSPACE_PROFILE_ROLE {
  USER = "user",
  ADMIN = "admin",
}

export const USER_DASHBOARD_NAVS: SideBarSectionItem[] = [
  {
    id: "others",
    title: "Others",
    userRolesForShow: [WORKSPACE_PROFILE_ROLE.USER],
    // userRolesForHide: ["admin"],
    items: [
      {
        id: "api_keys",
        icon: "api-keys",
        title: "API Keys",
        href: "/auth/profile/api-keys",
      },
      {
        id: "api_key_stats",
        title: "API Key stats",
        href: "/auth/profile/api-key-stats",
        isHidden: true,
      },
      {
        id: "custom_abi",
        icon: "ABI",
        title: "Custom ABI",
        href: "/auth/profile/custom-abi",
      },
    ],
  },
];

export const ADMIN_DASHBOARD_NAVS: SideBarSectionItem[] = [
  {
    id: "apis",
    title: "APIs",
    userRolesForShow: [WORKSPACE_PROFILE_ROLE.ADMIN],
    items: [
      {
        id: "customers",
        icon: "api-keys",
        title: "Customers",

        href: "/auth/profile/admin/api-dashboard/customers",
        subItems: [
          {
            id: "customer_details",
            icon: "api-keys",
            title: "Customer details",
            href: "/auth/profile/admin/api-dashboard/customer/[id]",
          },
        ],
      },
      {
        id: "packages",
        icon: "package",
        title: "Packages",
        href: "/auth/profile/admin/api-dashboard/packages",
        subItems: [
          {
            id: "api_package",
            icon: "package",
            title: "API Packages",
            href: "/auth/profile/admin/api-dashboard/packages/[id]",
          },
        ],
      },
      {
        id: "credit_definition",
        icon: "owner",
        title: "Credit definition",
        href: "/auth/profile/admin/api-dashboard/credit-definition",
      },
      {
        id: "payment_list",
        icon: "description",
        title: "Payment list",
        href: "/auth/profile/admin/api-dashboard/payment-list",
      },
    ],
  },
];

const devFeatureUrls = new Set(getEnvValueV2(`common."DEV_FEATURES_URL`));

const profile = [
  {
    id: "account",
    title: "Account",
    items: [
      {
        id: "default",
        icon: "user",
        title: "Account settings",
        href: "/auth/profile/account",
      },
    ],
  },
  {
    id: "lists",
    title: "Lists",
    items: [
      {
        id: "watch_list",
        icon: "star_outline",
        title: "Watch list",
        href: "/auth/profile/watchlist",
      },
      {
        id: "private_tags",
        icon: "privattags",
        title: "Private tags",
        href: "/auth/profile/private-tags",
        // isDisabled: true,
        // isComingSoon: true,
      },
    ],
  },
  ...ADMIN_DASHBOARD_NAVS,
  ...USER_DASHBOARD_NAVS,
] satisfies SideBarSectionItem[];

export const navsWithDevFeatures = _.chain(profile)
  .thru((navs) => {
    const res = [] as SideBarSectionItem[];
    navs.forEach((section) => {
      const items = _.chain(section.items)
        .filter((item) => !devFeatureUrls.has(item.href))
        .value();

      if (items.length) {
        res.push({ ...section, items });
      }
    });
    return res;
  })
  .value();
