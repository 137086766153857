import React, {
  memo,
  useLayoutEffect,
  useState,
  type MutableRefObject,
} from "react";

// /**
//  * Compares two objects to determine if they have identical keys and values.
//  *
//  * @param {Object} prev - The first object to compare.
//  * @param {Object} next - The second object to compare.
//  * @returns {boolean} - Returns true if both objects have the same keys and values, otherwise false.
//  */
// function areObjectsEqual(prev: Record<string, any>, next: Record<string, any>) {
//   // Get keys of both objects
//   const prevKeys = Object.keys(prev);
//   const nextKeys = Object.keys(next);

//   // Check if the number of keys are different
//   if (prevKeys.length !== nextKeys.length) {
//     return false;
//   }

//   // Check if any key or value is different
//   for (const key of prevKeys) {
//     if (!next.hasOwnProperty(key) || prev[key] !== next[key]) {
//       return false;
//     }
//   }

//   // All keys and values are the same
//   return true;
// }

type Props = {
  view: React.ComponentType<Record<string, unknown>>;
  isActive: boolean;
  registerRef: MutableRefObject<{
    initProps: Record<string, unknown>;
    setProps: AnyFunction | null;
  }>;
};

const ConsumerLazy = ({ view: View, isActive, registerRef }: Props) => {
  const [props, setProps] = useState<Record<string, unknown>>(
    registerRef.current?.initProps ?? {},
  );
  useLayoutEffect(() => {
    registerRef.current = {
      initProps: props,
      setProps,
    };

    return () => {
      registerRef.current.setProps = null;
    };
  }, [registerRef]);
  return <View {...props} isActive={isActive} />;
};

export default memo(ConsumerLazy, (prev, next) => {
  return prev.view === next.view && prev.isActive === next.isActive;
});
