const DEFAULT_CURRENCY_DECIMALS = 18;

const chain = Object.freeze({
  name: "SEI",
  currency: {
    name: "SEI",
    symbol: "SEI",
    decimals: DEFAULT_CURRENCY_DECIMALS,
  },
});

export default chain;
