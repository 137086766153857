import { chainKey } from "configs/frontend/chain/utils";
import Router from "next/router";
import { type Route } from "nextjs-routes";

export const setQuery = (
  queryName: string,
  value: any,
  options?: {
    hash?: string;
    pathname?: Route["pathname"];
    isPush?: boolean;
  },
) => {
  return Router[options?.isPush ? "push" : "replace"](
    {
      pathname: options?.pathname || Router.pathname,
      query: {
        ...Router.query,
        chain: chainKey,
        [queryName]: value,
      },
      hash: options?.hash || Router.asPath.split("#")[1],
    },
    undefined,
    { scroll: false, shallow: true },
  );
};

export const setRouter = (
  pathname: Route["pathname"] | "current" | string,
  query: Record<string, any>,
  options?: {
    hash?: string;
    isReplace?: boolean;
    cleanQuery?: boolean;
  },
) => {
  return Router[options?.isReplace ? "replace" : "push"](
    {
      pathname: pathname || Router.pathname,
      query: {
        ...(options?.cleanQuery ? {} : Router.query),
        ...query,
        chain: chainKey,
      },
      hash: options?.hash || Router.asPath.split("#")[1] || "",
    },
    undefined,
    { scroll: false, shallow: true },
  );
};
