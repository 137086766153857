import { chakra, Flex, Grid, HStack, Stack, Text } from "@chakra-ui/react";
import useApiQuery from "lib/api/useApiQuery";
import { useLastSync } from "lib/hooks/useLastSync";
import { memo } from "react";
import { BLOCK } from "stubs/block";
import { generateKey } from "stubs/utils";
import type { Block, BlocksResponse } from "types/api/block";
import Empty from "ui/shared/Empty";
import Hint from "ui/shared/Hint";
import LinkInternal from "ui/shared/LinkInternal";
import LatestBlocksItem from "./LatestBlocksItem";

const BLOCK_MAX_COUNT = 6;

const placeholderData = {
  items: Array(BLOCK_MAX_COUNT).fill(BLOCK),
  next_page_params: null,
};

const LatestBlocks = () => {
  const { data: validator_stats_data } = useApiQuery("validators_stats");

  const { data, isPlaceholderData, isError, dataUpdatedAt } = useApiQuery<
    "blocks",
    BlocksResponse,
    Block[]
  >("blocks", {
    queryParams: {
      limit: BLOCK_MAX_COUNT,
    },
    queryOptions: {
      placeholderData: placeholderData,
      select: (data) => {
        return data?.items.slice(0, BLOCK_MAX_COUNT) || [];
      },
    },
  });

  const lastSync = useLastSync(dataUpdatedAt, [data]);

  return (
    <Stack
      overflow="hidden"
      borderRadius={2}
      borderWidth="1px"
      borderColor="neutral.light.3"
      backgroundColor="neutral.light.1"
      flexShrink={0}
      flex={1}
      spacing={0}
    >
      <Flex
        justifyContent="space-between"
        alignItems={{ base: "center", lg: "flex-start" }}
        paddingX={{ base: 4, xl: 6 }}
        paddingY={{ base: 3, xl: 3 }}
        height={{ lg: "5.425rem" }}
      >
        <Flex
          direction={"row"}
          width="full"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Flex alignItems="center" gap={2}>
            <Stack>
              <HStack spacing={2}>
                <chakra.span
                  as="h2"
                  whiteSpace="nowrap"
                  textStyle="1125700"
                  color="neutral.light.7"
                >
                  Latest blocks
                </chakra.span>
                <Hint label={`Last sync: ${lastSync}`}></Hint>
              </HStack>
              {validator_stats_data && validator_stats_data[1] ? (
                <Flex
                  direction={"row"}
                  width={"full"}
                  justifyContent="space-between"
                  alignItems={{ base: "center", lg: "flex-start" }}
                >
                  <Flex
                    width="full"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Flex alignItems="center" gap={1}>
                      <Text as="span" textStyle="875">
                        Allocated validators
                      </Text>{" "}
                      <Text as="span" textStyle="875">
                        <LinkInternal
                          href="/validators"
                          marginTop={-6}
                          position="relative"
                          textStyle="875"
                          color="secondary.03.text"
                        >
                          {validator_stats_data
                            ? validator_stats_data[1].value
                            : ""}
                        </LinkInternal>
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              ) : null}
            </Stack>
          </Flex>
          <LinkInternal
            href="/blocks"
            // marginTop={-6}
            color="accent.blue"
            position="relative"
          >
            View all
          </LinkInternal>
        </Flex>
      </Flex>

      <Grid
        borderStyle="solid"
        borderTopWidth="1px"
        borderColor="neutral.light.3"
        templateRows={{
          base: "repeat(6, 6.75rem)",
          lg: "repeat(6, 4.25rem)",
        }}
        height={{ base: "40.5rem", lg: "25.5rem" }}
        overflow="hidden"
      >
        {isError ? (
          <Empty width="full" gridRow="span 6" height="full"></Empty>
        ) : (
          data?.map((block, index) => (
            <LatestBlocksItem
              key={generateKey(index, isPlaceholderData, block.height)}
              block={block}
              isLoading={isPlaceholderData}
            />
          ))
        )}
      </Grid>
    </Stack>
  );
};

export default memo(LatestBlocks, () => true);
