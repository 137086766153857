import { getFeaturePayload } from "configs/app/features/types";
import type {
  CustomAbis,
  PrivateTag,
  PrivateTagsResponse,
  UpdateAvatarResponse,
  UserQuota,
  WatchlistAddress,
  WatchlistEVMResponse,
  WatchlistsResponse,
  WorkspaceUserInfo,
} from "types/api/account";
import type {
  Address,
  AddressBlocksValidatedResponse,
  AddressCoinBalanceHistoryChart,
  AddressCoinBalanceHistoryResponse,
  AddressCollectionsResponse,
  AddressCounters,
  AddressInternalTxsResponse,
  AddressNFTsResponse,
  AddressTabsCounters,
  AddressTokensCounter,
  AddressTokensResponse,
  AddressTokenTransferResponse,
  AddressTransactionsResponse,
  AddressWithdrawalsResponse,
} from "types/api/address";
import type { AddressesResponse } from "types/api/addresses";
import type {
  Block,
  BlocksResponse,
  BlockTransactionsResponse,
  BlockWithdrawalsResponse,
} from "types/api/block";
import type {
  ChartMarketResponse,
  ChartTransactionResponse,
} from "types/api/charts";
import type { BackendVersionConfig } from "types/api/configs";
import type {
  SmartContract,
  SmartContractReadMethod,
  SmartContractVerificationConfig,
  SmartContractWriteMethod,
  SolidityscanReport,
} from "types/api/contract";
import type {
  SmartContractSecurityAudits,
  VerifiedContractsCounters,
  VerifiedContractsResponse,
} from "types/api/contracts";
import type { IndexingStatus } from "types/api/indexingStatus";
import type { InternalTransactionsResponse } from "types/api/internalTransaction";
import type {
  LogsCosmosResponseAddress,
  LogsResponseAddress,
  LogsResponseTx,
} from "types/api/log";
import type { RawTracesResponse } from "types/api/rawTrace";
import type {
  SearchRedirectResult,
  SearchResult,
  SearchResultItem,
} from "types/api/search";
import type {
  Checkpoints,
  Counters,
  HomeStats,
  IStatsCounters,
  StatsChart,
  StatsCharts,
} from "types/api/stats";
import type {
  INativeTokenCounters,
  INativeTokenHoldersResponse,
  INativeTokenTransfersResponse,
  TokenCounters,
  TokenHolders,
  TokenICS20sResponse,
  TokenInfo,
  TokenInstance,
  TokenInstanceTransfersCount,
  TokenInventoryResponse,
  TokenNativesResponse,
} from "types/api/token";
import type {
  PointerResponse,
  TokenInstanceTransferResponse,
  TokensResponse,
} from "types/api/tokens";
import type { TokenTransferResponse } from "types/api/tokenTransfer";
import type {
  Association,
  Transaction,
  TransactionsResponsePending,
  TransactionsResponseValidated,
  TransactionsResponseWatchlist,
} from "types/api/transaction";
import type { TxStateChanges } from "types/api/txStateChanges";
import type {
  WithdrawalsCounters,
  WithdrawalsResponse,
} from "types/api/withdrawals";

import config from "configs/app";
import type { ExchangeCodeResponse, RegisterResponse } from "types/api/auth";
import type {
  IABCI,
  IChainStatus,
  IDeposit,
  IDistribution,
  IGenesis,
  IInflation,
  INodeInfo,
  ISlashing,
  IStaking,
  IStakingPool,
  ITallying,
  ITotalSupply,
  IVoting,
} from "types/api/chain";
import type {
  IContractCode,
  IContractCodeContractsResponse,
  IContractCodesResponse,
  IContractCodesStats,
} from "types/api/codeID";
import type {
  IBCChainConnectedResponse,
  IBCChainDetailsResponse,
  IIBCChannelTransactionsResponse,
  IIBCRelayerChannel,
  IIBCRelayerChannelTransferAssetsResponse,
  IIBCRelayersStat,
  IIBCTokenHoldersResponse,
  IIBCTokensResponse,
  IIBCTokenTransfersResponse,
} from "types/api/ibcRelayer";
import type { PaginatedApiKeyActivityList } from "types/api/pricing-package/api-key-activity.entity";
import type {
  ApiKeyPaginatedList,
  ApiKeyRawEntity,
} from "types/api/pricing-package/api-key.entity";
import type {
  GetPackageResponse,
  GetPublicPackagesResponse,
  IPackagesStats,
  PackageEntity,
} from "types/api/pricing-package/api-packages.entity";
import type {
  ApiResourcePaginatedList,
  ApiResourceRawEntity,
} from "types/api/pricing-package/api-resource.entity";
import type {
  CustomerStatsEntity,
  UserPackageProfileExtendedEntity,
} from "types/api/pricing-package/user-package-profile.entity";
import type {
  GetUserPackageResponse,
  UserPackageEntity,
  UserPackagePaginatedList,
  UserPackageProfilePaginatedList,
  UserPackageSimplifiedEntity,
} from "types/api/pricing-package/user-package.entity";
import type {
  IProposalParams,
  IProposalVoteInfoResponse,
  ProposalDepositorResponse,
  ProposalDetailsResponse,
  ProposalDetailStatsResponse,
  ProposalResponse,
  ProposalStats,
  ProposalValidatorVoteResponse,
  ProposalVoteResponse,
} from "types/api/proposal";
import type { GlobalPublicTagsResponse } from "types/api/tags";
import type {
  ValidatorDetailResponse,
  ValidatorInfosResponse,
  ValidatorsStatsResponse,
} from "types/api/validator";
import type { OSType } from "types/base";
import type {
  IAssetInfo,
  PaginatedAssetList,
} from "../../types/api/assetList.ts";
import { _markResource, type IResourceRootItem } from "./types";

export const RESOURCES = {
  // COMMON
  checkpoints: _markResource({
    path: "/gateway/api/v1/checkpoints",
  })._set_response<Checkpoints>(),
  global_public_tags: _markResource({
    path: "/gateway/api/v1/public-tags",
  })._set_response<GlobalPublicTagsResponse>(),

  // WORKSPACE ACCOUNT APIs
  workspace_userinfo: _markResource({
    path: "/workspace/api/v1/user/profile",
    needWorkspaceAuth: true,
  })._set_response<WorkspaceUserInfo>(),
  workspace_update_userinfo: _markResource({
    path: "/workspace/api/v1/user/profile",
    needWorkspaceAuth: true,
  })._set_response(),
  workspace_update_avatar: _markResource({
    path: "/workspace/api/v1/user/avatar",
    needWorkspaceAuth: true,
  })._set_response(),
  workspace_change_password: _markResource({
    path: "/workspace/api/v1/user/password",
    needWorkspaceAuth: true,
  })._set_response(),
  issue_workspace_token: _markResource({
    path: "/auth/issue-workspace-token",
  })._set_response(),
  my_quota: _markResource({
    path: "/workspace/api/v1/user-quota/me",
    needWorkspaceAuth: true,
  })._set_response<UserQuota>(),
  api_package_detail: _markResource({
    path: "/workspace/api/v1/package/:id",
    pathParams: ["id"],
    needWorkspaceAuth: false,
  })._set_response<PackageEntity, true>(),
  verified_assets: _markResource({
    path: "/workspace/api/v1/assets",
    filterFields: [
      "limit",
      "offset",
      "search_terms",
      "asset_types",
      "chain_id",
    ],
    needWorkspaceAuth: false,
  })._set_response<PaginatedAssetList, true>(),

  // ADMIN ROLE
  admin_get_user_package_profile_stats: _markResource({
    path: "/workspace/api/v1/admin/user-package-profile/stats",
    needWorkspaceAuth: true,
  })._set_response<CustomerStatsEntity>(),
  admin_create_assign_user_package: _markResource({
    path: "/workspace/api/v1/admin/user-package/:userId",
    pathParams: ["userId"],
    needWorkspaceAuth: true,
  })._set_response<UserPackageEntity>(),
  admin_get_user_package_list: _markResource({
    path: "/workspace/api/v1/admin/user-package",
    needWorkspaceAuth: true,
  })._set_response<UserPackagePaginatedList, true>(), // Paginated
  admin_get_specific_user_package_list: _markResource({
    path: "/workspace/api/v1/admin/user-package/:userId",
    pathParams: ["userId"],
    needWorkspaceAuth: true,
  })._set_response<UserPackagePaginatedList, true>(), // Paginated

  // USER ROLE
  user_update_user_package_action: _markResource({
    path: "/workspace/api/v1/user-package/:userPackageId/:action",
    pathParams: ["userPackageId", "action"],
    needWorkspaceAuth: true,
  })._set_response(),
  public_packages: _markResource({
    path: "/workspace/api/v1/package/public-packages",
  })._set_response<GetPublicPackagesResponse, true>(),

  // SEARCH
  quick_search: _markResource({
    path: "/gateway/api/v1/search/quick",
    filterFields: ["q"],
  })._set_response<Array<SearchResultItem>>(),
  search: _markResource({
    path: "/api/v2/search",
    filterFields: ["q"],
  })._set_response<SearchResult>(),

  // TOKEN
  tokens: _markResource({
    path: "/gateway/api/v1/tokens",
    filterFields: ["q", "type"],
  })._set_response<TokensResponse, true>(), // Paginated
  asset_info: _markResource({
    path: "/gateway/api/v1/workspace/asset-detail",
    filterFields: ["identifier"],
  })._set_response<IAssetInfo>(),

  // POINTER
  pointers: _markResource({
    path: "/gateway/api/v1/tokens/pointer",
    filterFields: ["original_address"],
  })._set_response<PointerResponse, true>(), // Paginated

  // VALIDATOR
  validators: _markResource({
    path: "/gateway/api/v1/validators",
    filterFields: ["status", "q"],
  })._set_response<ValidatorInfosResponse, true>(), // Paginated
  validator: _markResource({
    path: "/gateway/api/v1/validators/:address",
    pathParams: ["address"],
  })._set_response<ValidatorDetailResponse>(),
  validators_stats: _markResource({
    path: "/gateway/api/v1/validators/stats",
  })._set_response<ValidatorsStatsResponse>(),
  // PROPOSAL
  proposal_stats: _markResource({
    path: "/gateway/api/v1/proposals/stats",
  })._set_response<ProposalStats>(),
  proposals: _markResource({
    path: "/gateway/api/v1/proposals",
    filterFields: ["status", "limit", "items_count", "search"],
  })._set_response<ProposalResponse, true>(), // Paginated
  proposal: _markResource({
    path: "/gateway/api/v1/proposals/:proposal_id",
    pathParams: ["proposal_id"],
  })._set_response<ProposalDetailsResponse>(),
  proposal_detail_stats: _markResource({
    path: "/gateway/api/v1/proposals/:proposal_id/stats",
    pathParams: ["proposal_id"],
  })._set_response<ProposalDetailStatsResponse>(),
  proposal_votes: _markResource({
    path: "/gateway/api/v1/proposals/:proposal_id/votes",
    pathParams: ["proposal_id"],
    filterFields: ["answer", "limit", "items_count", "search", "vote_options"],
  })._set_response<ProposalVoteResponse, true>(), // Paginated
  proposal_validator_votes: _markResource({
    path: "/gateway/api/v1/proposals/:proposal_id/validator-votes",
    pathParams: ["proposal_id"],
    filterFields: ["limit", "items_count", "search", "vote_options"],
  })._set_response<ProposalValidatorVoteResponse, true>(), // Paginated
  proposal_depositors: _markResource({
    path: "/gateway/api/v1/proposals/:proposal_id/depositors",
    pathParams: ["proposal_id"],
    filterFields: ["message_type"],
  })._set_response<ProposalDepositorResponse, true>(), // Paginated
  governance_tallying: _markResource({
    path: "/gateway/api/v1/governance/tallying",
  })._set_response<IProposalParams>(),
  proposal_vote: _markResource({
    path: "/gateway/api/v1/proposals/:proposal_id/voters/:address",
    pathParams: ["proposal_id", "address"],
  })._set_response<IProposalVoteInfoResponse>(),

  // NATIVE TOKENS
  native_token: _markResource({
    path: "/gateway/api/v1/native-tokens/detail",
  })._set_response<TokenInfo>(),
  native_token_transfers: _markResource({
    path: "/gateway/api/v1/native-tokens/transfers",
    filterFields: ["denom", "limit", "items_count"],
  })._set_response<INativeTokenTransfersResponse, true>(), // Paginated
  native_token_holders: _markResource({
    path: "/gateway/api/v1/native-tokens/holders",
    filterFields: ["denom", "limit", "items_count"],
  })._set_response<INativeTokenHoldersResponse, true>(), // Paginated
  native_token_counters: _markResource({
    path: "/gateway/api/v1/native-tokens/counters",
    filterFields: ["denom"],
  })._set_response<INativeTokenCounters>(),

  // ICS_20
  ics20_tokens: _markResource({
    path: "/gateway/api/v1/ics20-tokens",
    filterFields: ["limit", "items_count", "search"],
  })._set_response<TokenICS20sResponse, true>(), // Paginated
  ics20_token: _markResource({
    path: "/gateway/api/v1/ics20-tokens/:hash",
    pathParams: ["hash"],
  })._set_response<TokenInfo>(),
  ics20_token_transfers: _markResource({
    path: "/gateway/api/v1/ics20-tokens/:hash/transfers",
    pathParams: ["hash"],
    filterFields: ["limit", "items_count"],
  })._set_response<IIBCTokenTransfersResponse, true>(), // Paginated
  ics20_token_holders: _markResource({
    path: "/gateway/api/v1/ics20-tokens/:hash/holders",
    pathParams: ["hash"],
    filterFields: ["limit", "items_count"],
  })._set_response<IIBCTokenHoldersResponse, true>(), // Paginated
  ics20_token_counters: _markResource({
    path: "/gateway/api/v1/ics20-tokens/:hash/counters",
    pathParams: ["hash"],
  })._set_response<TokenCounters>(),

  // params
  params_node_info: _markResource({
    path: "/gateway/api/v1/chain-parameters/native/node-info",
  })._set_response<INodeInfo>(),
  params_staking_pool: _markResource({
    path: "/gateway/api/v1/chain-parameters/native/staking-pool",
  })._set_response<IStakingPool>(),
  params_status: _markResource({
    path: "/gateway/api/v1/chain-parameters/native/status",
  })._set_response<IChainStatus>(),
  params_genesis: _markResource({
    path: "/gateway/api/v1/chain-parameters/native/genesis",
  })._set_response<IGenesis>(),
  params_abci_info: _markResource({
    path: "/gateway/api/v1/chain-parameters/native/abci-info",
  })._set_response<IABCI>(),
  params_total_supply: _markResource({
    path: "/gateway/api/v1/chain-parameters/native/total-supply",
  })._set_response<ITotalSupply>(),
  params_staking: _markResource({
    path: "/gateway/api/v1/chain-parameters/native/staking",
  })._set_response<IStaking>(),
  params_slashing: _markResource({
    path: "/gateway/api/v1/chain-parameters/native/slashing",
  })._set_response<ISlashing>(),
  params_distribution: _markResource({
    path: "/gateway/api/v1/chain-parameters/native/distribution",
  })._set_response<IDistribution>(),
  params_tallying: _markResource({
    path: "/gateway/api/v1/chain-parameters/native/governance/tallying",
  })._set_response<ITallying>(),
  params_inflation: _markResource({
    path: "/gateway/api/v1/chain-parameters/native/inflation",
  })._set_response<IInflation>(),
  params_voting: _markResource({
    path: "/gateway/api/v1/chain-parameters/native/governance/voting",
  })._set_response<IVoting>(),
  params_deposit: _markResource({
    path: "/gateway/api/v1/chain-parameters/native/governance/deposit",
  })._set_response<IDeposit>(),

  // Code ID
  contract_codes: _markResource({
    path: "/gateway/api/v1/contract-codes",
  })._set_response<IContractCodesResponse, true>(), // Paginated
  contract_code: _markResource({
    path: "/gateway/api/v1/contract-codes/:codeId",
    pathParams: ["codeId"],
  })._set_response<IContractCode>(),
  contract_code_contracts: _markResource({
    path: "/gateway/api/v1/contract-codes/:codeId/contracts",
    pathParams: ["codeId"],
  })._set_response<IContractCodeContractsResponse>(),
  contract_codes_stats: _markResource({
    path: "/gateway/api/v1/contract-codes/statistics",
  })._set_response<IContractCodesStats>(),

  active_free_trial_package: _markResource({
    path: "/workspace/api/v1/user-package/activate-free-trial-package",
    needWorkspaceAuth: true,
  })._set_response(),
  user_package: _markResource({
    path: "/workspace/api/v1/user-package",
    needWorkspaceAuth: true,
  })._set_response<GetUserPackageResponse, true>(), // Paginated
  latest_user_package: _markResource({
    path: "/workspace/api/v1/admin/user-package/:userId/latest",
    pathParams: ["userId"],
    needWorkspaceAuth: true,
  })._set_response<UserPackageEntity>(),
  update_user_package: _markResource({
    path: "/workspace/api/v1/admin/user-package/:userId/:userPackageId",
    pathParams: ["userId", "userPackageId"],
    needWorkspaceAuth: true,
  })._set_response<UserPackageEntity>(),
  api_package: _markResource({
    path: "/workspace/api/v1/admin/package",
    needWorkspaceAuth: true,
  })._set_response<GetPackageResponse, true>(),
  create_api_package: _markResource({
    path: "/workspace/api/v1/admin/package",
    needWorkspaceAuth: true,
  })._set_response<PackageEntity>(),
  update_api_package: _markResource({
    path: "/workspace/api/v1/admin/package/:id",
    pathParams: ["id"],
    needWorkspaceAuth: true,
  })._set_response<PackageEntity>(),
  user_package_profile_list: _markResource({
    path: "/workspace/api/v1/admin/user-package-profile",
    filterFields: [
      "package_id_list",
      "is_regular_account",
      "limit",
      "offset",
      "search_terms",
    ],
    needWorkspaceAuth: true,
  })._set_response<UserPackageProfilePaginatedList, true>(), // Paginated
  user_package_profile: _markResource({
    path: "/workspace/api/v1/admin/user-package-profile/:id",
    pathParams: ["id"],
    needWorkspaceAuth: true,
  })._set_response<UserPackageProfileExtendedEntity>(),
  user_package_profile_action: _markResource({
    path: "/workspace/api/v1/admin/user-package-profile/:id/:action",
    pathParams: ["id", "action"],
    needWorkspaceAuth: true,
  })._set_response(),
  get_api_resource_list: _markResource({
    path: "/workspace/api/v1/api-resource",
    needWorkspaceAuth: true,
  })._set_response<ApiResourcePaginatedList, true>(), // Paginated
  add_api_resource: _markResource({
    path: "/workspace/api/v1/admin/api-resource",
    needWorkspaceAuth: true,
  })._set_response<ApiResourceRawEntity>(),
  update_api_resource: _markResource({
    path: "/workspace/api/v1/admin/api-resource/:id",
    pathParams: ["id"],
    needWorkspaceAuth: true,
  })._set_response<ApiResourceRawEntity>(),
  delete_api_resource: _markResource({
    path: "/workspace/api/v1/admin/api-resource/:id",
    pathParams: ["id"],
    needWorkspaceAuth: true,
  })._set_response(),
  packages_stats: _markResource({
    path: "/workspace/api/v1/admin/user-package/stats",
    needWorkspaceAuth: true,
  })._set_response<IPackagesStats>(),
  package_info: _markResource({
    path: "/workspace/api/v1/package/:id",
    pathParams: ["id"],
  })._set_response<PackageEntity>(),
  user_package_register: _markResource({
    path: "/workspace/api/v1/user-package",
    needWorkspaceAuth: true,
  })._set_response<RegisterResponse>(),
  get_user_package_profile: _markResource({
    path: "/workspace/api/v1/user-package/profile",
    needWorkspaceAuth: true,
  })._set_response<UserPackageProfileExtendedEntity>(),
  active_package: _markResource({
    path: "/workspace/api/v1/user-package/active-package",
    needWorkspaceAuth: true,
  })._set_response<UserPackageSimplifiedEntity>(),
  user_get_latest_user_package: _markResource({
    path: "/workspace/api/v1/user-package/latest-package",
    needWorkspaceAuth: true,
  })._set_response<UserPackageEntity>(),
  get_api_key_list: _markResource({
    path: "/workspace/api/v1/api-keys",
    needWorkspaceAuth: true,
  })._set_response<ApiKeyPaginatedList, true>(), // Paginated
  get_api_key: _markResource({
    path: "/workspace/api/v1/api-keys/:id",
    pathParams: ["id"],
    needWorkspaceAuth: true,
  })._set_response<ApiKeyRawEntity>(),
  delete_api_key: _markResource({
    path: "/workspace/api/v1/api-keys/:id",
    pathParams: ["id"],
    needWorkspaceAuth: true,
  })._set_response(),
  create_api_key: _markResource({
    path: "/workspace/api/v1/api-keys",
    needWorkspaceAuth: true,
  })._set_response<ApiKeyRawEntity>(),
  update_api_key: _markResource({
    path: "/workspace/api/v1/api-keys/:id",
    pathParams: ["id"],
    needWorkspaceAuth: true,
  })._set_response<ApiKeyRawEntity>(),
  get_api_key_activities: _markResource({
    path: "/workspace/api/v1/api-keys/activities",
    needWorkspaceAuth: true,
  })._set_response<PaginatedApiKeyActivityList, true>(), // Paginated
  latest_txs_validated: _markResource({
    path: "/gateway/api/v1/transactions/latest",
    filterFields: ["filters", "type", "method", "limit"],
  })._set_response<TransactionsResponseValidated, true>(), // Paginated
  txs_validated: _markResource({
    path: "/gateway/api/v1/transactions",
    filterFields: ["filters", "type", "method", "limit"],
  })._set_response<TransactionsResponseValidated, true>(), // Paginated
  txs_pending: _markResource({
    path: "/api/v2/transactions",
    filterFields: ["filters", "type", "method"],
  })._set_response<TransactionsResponsePending, true>(), // Paginated
  txs_watchlist: _markResource({
    path: "/api/v2/transactions/watchlist",
    filterFields: [],
  })._set_response<TransactionsResponseWatchlist, true>(), // Paginated
  // ACCOUNT
  csrf: _markResource({
    path: "/api/account/v2/get_csrf",
  })._set_response(),
  update_userinfo: _markResource({
    path: "/gateway/api/v1/auth/profile/update-userinfo",
  })._set_response(),
  update_avatar: _markResource({
    path: "/gateway/api/v1/auth/profile/update-avatar",
  })._set_response<UpdateAvatarResponse>(),
  change_password: _markResource({
    path: "/gateway/api/v1/auth/change-password",
  })._set_response(),
  custom_abi: _markResource({
    path: "/api/account/v2/user/custom_abis{/:id}",
    pathParams: ["id"],
    needAuth: true,
  })._set_response<CustomAbis>(),
  watchlist_evms: _markResource({
    path: "/api/account/v2/user/watchlist",
    needAuth: true,
  })._set_response<WatchlistEVMResponse, true>(),
  watchlist_evm: _markResource({
    path: "/api/account/v2/user/watchlist{/:id}",
    pathParams: ["id"],
    needAuth: true,
  })._set_response<WatchlistAddress>(),
  watchlist_native: _markResource({
    path: "/workspace/api/v1/watchlist{/:id}",
    pathParams: ["id"],
    needWorkspaceAuth: true,
  })._set_response<WatchlistAddress>(),
  watchlist_byaddress: _markResource({
    path: "/workspace/api/v1/watchlist/address/:hash",
    pathParams: ["hash"],
    filterFields: ["chain_id"],
    needWorkspaceAuth: true,
  })._set_response<WatchlistAddress>(),
  private_tag_byhash: _markResource({
    path: "/workspace/api/v1/private-tag/hash/:hash",
    pathParams: ["hash"],
    filterFields: ["chain_id", "type"],
    needWorkspaceAuth: true,
  })._set_response<PrivateTag>(),
  watchlists: _markResource({
    path: "/workspace/api/v1/watchlist",
    filterFields: [
      "chain_id",
      "search_terms",
      "address_type",
      "limit",
      "offset",
    ],
    needWorkspaceAuth: true,
  })._set_response<WatchlistsResponse, true>(), // Paginated
  private_tags: _markResource({
    path: "/workspace/api/v1/private-tag",
    filterFields: [
      "chain_id",
      "search_terms",
      "type",
      "limit",
      "offset",
      "address_type",
      "hash_type",
    ],
    needWorkspaceAuth: true,
  })._set_response<PrivateTagsResponse, true>(), // Paginated
  private_tag: _markResource({
    path: "/workspace/api/v1/private-tag{/:id}",
    pathParams: ["id"],
    needWorkspaceAuth: true,
  })._set_response<PrivateTag>(),
  // STATS
  stats_counters: _markResource({
    path: "/api/v1/counters",
    endpoint: getFeaturePayload(config.features.stats)?.api.endpoint,
    basePath: getFeaturePayload(config.features.stats)?.api.basePath,
  })._set_response<Counters>(),
  stats_lines: _markResource({
    path: "/api/v1/lines",
    endpoint: getFeaturePayload(config.features.stats)?.api.endpoint,
    basePath: getFeaturePayload(config.features.stats)?.api.basePath,
  })._set_response<StatsCharts>(),
  stats_line: _markResource({
    path: "/api/v1/lines/:id",
    pathParams: ["id"],
    endpoint: getFeaturePayload(config.features.stats)?.api.endpoint,
    basePath: getFeaturePayload(config.features.stats)?.api.basePath,
  })._set_response<StatsChart>(),
  gateway_stats_counters: _markResource({
    path: "/gateway/api/v1/stats/counters",
  })._set_response<IStatsCounters>(),

  // AUTH
  request_challenge: _markResource({
    path: "/gateway/api/v1/auth/challenge/:target",
    pathParams: ["target"],
  })._set_response(),
  reset_password: _markResource({
    path: "/gateway/api/v1/auth/reset-password",
    filterFields: ["email", "password", "otp"],
  })._set_response(),
  register: _markResource({
    path: "/gateway/api/v1/auth/register",
    filterFields: ["email", "password"],
  })._set_response(),
  exchange_code: _markResource({
    path: "/gateway/api/v1/auth/exchange-code",
    filterFields: ["code", "email", "password"],
  })._set_response<ExchangeCodeResponse>(),

  // TRANSACTIONS
  tx: _markResource({
    path: "/gateway/api/v1/transactions/:hash",
    pathParams: ["hash"],
  })._set_response<Transaction>(),
  tx_internal_txs: _markResource({
    path: "/gateway/api/v1/transactions/:hash/internal-transactions",
    pathParams: ["hash"],
    filterFields: [],
  })._set_response<InternalTransactionsResponse, true>(),
  tx_logs: _markResource({
    path: "/gateway/api/v1/transactions/:hash/logs",
    pathParams: ["hash"],
    filterFields: [],
  })._set_response<LogsResponseTx, true>(),
  tx_token_transfers: _markResource({
    path: "/gateway/api/v1/transactions/:hash/token-transfers",
    pathParams: ["hash"],
    filterFields: ["type"],
  })._set_response<AddressTokenTransferResponse, true>(),
  tx_raw_trace: _markResource({
    path: "/api/v2/transactions/:hash/raw-trace",
    pathParams: ["hash"],
  })._set_response<RawTracesResponse>(),
  tx_state_changes: _markResource({
    path: "/api/v2/transactions/:hash/state-changes",
    pathParams: ["hash"],
    filterFields: [],
  })._set_response<TxStateChanges, true>(),
  withdrawals: _markResource({
    path: "/api/v2/withdrawals",
    filterFields: [],
  })._set_response<WithdrawalsResponse, true>(), // Paginated
  withdrawals_counters: _markResource({
    path: "/api/v2/withdrawals/counters",
  })._set_response<WithdrawalsCounters>(),

  // ADDRESSES
  addresses: _markResource({
    path: "/gateway/api/v1/addresses",
    filterFields: ["type" as OSType],
  })._set_response<AddressesResponse, true>(),

  // ADDRESS
  address: _markResource({
    path: "/gateway/api/v1/addresses/:hash",
    pathParams: ["hash"],
  })._set_response<Address>(),
  address_counters: _markResource({
    path: "/gateway/api/v1/addresses/:hash/counters",
    pathParams: ["hash"],
  })._set_response<AddressCounters>(),
  address_tabs_counters: _markResource({
    path: "/gateway/api/v1/addresses/:hash/tabs-counters",
    pathParams: ["hash"],
  })._set_response<AddressTabsCounters>(),
  address_txs: _markResource({
    path: "/gateway/api/v1/addresses/:hash/transactions",
    pathParams: ["hash"],
    filterFields: ["filter", "type" as OSType],
  })._set_response<AddressTransactionsResponse, true>(), // Paginated
  address_internal_txs: _markResource({
    path: "/gateway/api/v1/addresses/:hash/internal-transactions",
    pathParams: ["hash"],
    filterFields: ["filter"],
  })._set_response<AddressInternalTxsResponse, true>(), // Paginated
  address_association: _markResource({
    path: "/gateway/api/v1/addresses/:hash/association",
    pathParams: ["hash"],
  })._set_response<Association>(),
  associations: _markResource({
    path: "/gateway/api/v1/addresses/associations",
    filterFields: ["hashes"],
  })._set_response<Array<Association>>(),
  address_token_transfers: _markResource({
    path: "/gateway/api/v1/addresses/:hash/token-transfers",
    pathParams: ["hash"],
    filterFields: ["filter", "type", "token"],
  })._set_response<AddressTokenTransferResponse, true>(), // Paginated
  address_blocks_validated: _markResource({
    path: "/api/v2/addresses/:hash/blocks-validated",
    pathParams: ["hash"],
    filterFields: [],
  })._set_response<AddressBlocksValidatedResponse, true>(), // Paginated
  address_coin_balance: _markResource({
    path: "/api/v2/addresses/:hash/coin-balance-history",
    pathParams: ["hash"],
    filterFields: [],
  })._set_response<AddressCoinBalanceHistoryResponse, true>(), // Paginated
  address_coin_balance_chart: _markResource({
    path: "/api/v2/addresses/:hash/coin-balance-history-by-day",
    pathParams: ["hash"],
  })._set_response<AddressCoinBalanceHistoryChart>(),
  address_logs: _markResource({
    path: "/api/v2/addresses/:hash/logs",
    pathParams: ["hash"],
    filterFields: [],
  })._set_response<LogsResponseAddress, true>(),
  address_logs_cosmos: _markResource({
    path: "/gateway/api/v1/addresses/:hash/logs",
    pathParams: ["hash"],
    filterFields: [],
  })._set_response<LogsCosmosResponseAddress, true>(),
  address_tokens: _markResource({
    path: "/gateway/api/v1/addresses/:hash/tokens",
    pathParams: ["hash"],
    filterFields: ["type", "q"],
  })._set_response<AddressTokensResponse, true>(), // Paginated
  address_nfts: _markResource({
    path: "/gateway/api/v1/addresses/:hash/nft",
    pathParams: ["hash"],
    filterFields: ["type"],
  })._set_response<AddressNFTsResponse, true>(), // Paginated
  address_collections: _markResource({
    path: "/gateway/api/v1/addresses/:hash/nft/collections",
    pathParams: ["hash"],
    filterFields: ["type"],
  })._set_response<AddressCollectionsResponse, true>(), // Paginated
  address_withdrawals: _markResource({
    path: "/api/v2/addresses/:hash/withdrawals",
    pathParams: ["hash"],
    filterFields: [],
  })._set_response<AddressWithdrawalsResponse, true>(), // Paginated
  address_tokens_counter: _markResource({
    path: "/gateway/api/v1/addresses/:hash/tokens/counter",
    pathParams: ["hash"],
  })._set_response<AddressTokensCounter>(),

  // CONTRACT
  contract: _markResource({
    path: "/api/v2/smart-contracts/:hash",
    pathParams: ["hash"],
  })._set_response<SmartContract>(),
  contract_methods_read: _markResource({
    path: "/api/v2/smart-contracts/:hash/methods-read",
    pathParams: ["hash"],
  })._set_response<Array<SmartContractReadMethod>>(),
  contract_methods_read_proxy: _markResource({
    path: "/api/v2/smart-contracts/:hash/methods-read-proxy",
    pathParams: ["hash"],
  })._set_response<Array<SmartContractReadMethod>>(),
  contract_method_query: _markResource({
    path: "/api/v2/smart-contracts/:hash/query-read-method",
    pathParams: ["hash"],
  })._set_response<any>(),
  contract_methods_write: _markResource({
    path: "/api/v2/smart-contracts/:hash/methods-write",
    pathParams: ["hash"],
  })._set_response<Array<SmartContractWriteMethod>>(),
  contract_methods_write_proxy: _markResource({
    path: "/api/v2/smart-contracts/:hash/methods-write-proxy",
    pathParams: ["hash"],
  })._set_response<Array<SmartContractWriteMethod>>(),
  contract_verification_config: _markResource({
    path: "/api/v2/smart-contracts/verification/config",
  })._set_response<SmartContractVerificationConfig>(),
  contract_verification_via: _markResource({
    path: "/api/v2/smart-contracts/:hash/verification/via/:method",
    pathParams: ["hash", "method"],
  })._set_response<any>(),
  contract_solidityscan_report: _markResource({
    path: "/api/v2/smart-contracts/:hash/solidityscan-report",
    pathParams: ["hash"],
  })._set_response<SolidityscanReport>(),

  // SEARCH
  search_check_redirect: _markResource({
    path: "/api/v2/search/check-redirect",
  })._set_response<SearchRedirectResult>(),

  // OTHER
  api_v2_key: _markResource({
    path: "/api/v2/key",
  })._set_response<any>(),

  // TOKEN
  token: _markResource({
    path: "/gateway/api/v1/tokens/:hash",
    pathParams: ["hash"],
  })._set_response<TokenInfo>(),
  token_counters: _markResource({
    path: "/gateway/api/v1/tokens/:hash/counters",
    pathParams: ["hash"],
  })._set_response<TokenCounters>(),
  token_holders: _markResource({
    path: "/gateway/api/v1/tokens/:hash/holders",
    pathParams: ["hash"],
  })._set_response<TokenHolders, true>(), // Paginated
  token_transfers: _markResource({
    path: "/gateway/api/v1/tokens/:hash/transfers",
    pathParams: ["hash"],
  })._set_response<TokenTransferResponse, true>(), // Paginated
  token_inventory: _markResource({
    path: "/gateway/api/v1/tokens/:hash/instances",
    pathParams: ["hash"],
    filterFields: ["holder_address_hash"],
  })._set_response<TokenInventoryResponse, true>(), // Paginated
  tokens_bridged: _markResource({
    path: "/api/v2/tokens/bridged",
    filterFields: ["q", "chain_ids"],
  })._set_response<TokensResponse, true>(), // Paginated
  native_tokens: _markResource({
    path: "/gateway/api/v1/native-tokens",
    filterFields: ["search", "limit", "items_count"],
  })._set_response<TokenNativesResponse, true>(), // Paginated

  // TOKEN INSTANCE
  token_instance: _markResource({
    path: "/gateway/api/v1/tokens/:hash/instances/:id",
    pathParams: ["hash", "id"],
  })._set_response<TokenInstance>(),
  token_instance_transfers_count: _markResource({
    path: "/gateway/api/v1/tokens/:hash/instances/:id/transfers-count",
    pathParams: ["hash", "id"],
  })._set_response<TokenInstanceTransfersCount>(),
  token_instance_transfers: _markResource({
    path: "/gateway/api/v1/tokens/:hash/instances/:id/transfers",
    pathParams: ["hash", "id"],
  })._set_response<TokenInstanceTransferResponse, true>(), // Paginated
  token_instance_holders: _markResource({
    path: "/api/v2/tokens/:hash/instances/:id/holders",
    pathParams: ["hash", "id"],
  })._set_response<TokenHolders, true>(), // Paginated
  refresh_metadata: _markResource({
    path: "/gateway/api/v1/tokens/:hash/instances/:id/refetch-nft-info",
    pathParams: ["hash", "id"],
    filterFields: ["refresh_metadata_for_existed_nft"],
  })._set_response(),

  // IBC
  ibc_chain_connecteds: _markResource({
    path: "/gateway/api/v1/ibc/relayers/chain-connected",
    filterFields: ["search", "status", "limit"],
  })._set_response<IBCChainConnectedResponse, true>(), // Paginated
  ibc_chain_details: _markResource({
    path: "/gateway/api/v1/ibc/relayers/:chain_id",
    pathParams: ["chain_id"],
    filterFields: ["limit", "items_count"],
  })._set_response<IBCChainDetailsResponse, true>(),
  ibc_transfer_assets: _markResource({
    path: "/gateway/api/v1/ibc/channel/:channel_id/transfer-assets/:counterparty_channel_id",
    pathParams: ["channel_id", "counterparty_channel_id"],
    filterFields: ["limit", "items_count", "type", "search"],
  })._set_response<IIBCRelayerChannelTransferAssetsResponse, true>(), // Paginated
  ibc_channel_details: _markResource({
    path: "/gateway/api/v1/ibc/channel/:channel_id/counterparty/:counterparty_channel_id",
    pathParams: ["channel_id", "counterparty_channel_id"],
  })._set_response<IIBCRelayerChannel>(),
  ibc_relayers_stat: _markResource({
    path: "/gateway/api/v1/ibc/relayers/stat",
  })._set_response<IIBCRelayersStat>(),
  ibc_channel_transactions: _markResource({
    path: "/gateway/api/v1/ibc/relayers/:channel_id/transactions",
    pathParams: ["channel_id"],
    filterFields: ["limit", "items_count", "type"],
  })._set_response<IIBCChannelTransactionsResponse, true>(), // Paginated
  ibc_tokens: _markResource({
    path: "/gateway/api/v1/ibc/tokens",
    filterFields: ["limit", "items_count"],
  })._set_response<IIBCTokensResponse, true>(), // Paginated

  // BLOCKS, TXS
  blocks: _markResource({
    path: "/gateway/api/v1/blocks",
    filterFields: ["type", "limit"],
  })._set_response<BlocksResponse, true>(), // Paginated
  block: _markResource({
    path: "/gateway/api/v1/blocks/:height_or_hash",
    pathParams: ["height_or_hash"],
  })._set_response<Block>(),
  block_txs: _markResource({
    path: "/gateway/api/v1/blocks/:height_or_hash/transactions",
    pathParams: ["height_or_hash"],
    filterFields: ["type" as OSType],
  })._set_response<BlockTransactionsResponse, true>(), // Paginated
  block_withdrawals: _markResource({
    path: "/api/v2/blocks/:height_or_hash/withdrawals",
    pathParams: ["height_or_hash"],
    filterFields: [],
  })._set_response<BlockWithdrawalsResponse, true>(),

  // VERIFIED CONTRACTS
  verified_contracts: _markResource({
    path: "/api/v2/smart-contracts",
    filterFields: ["q", "filter"],
  })._set_response<VerifiedContractsResponse, true>(), // Paginated
  verified_contracts_counters: _markResource({
    path: "/api/v2/smart-contracts/counters",
  })._set_response<VerifiedContractsCounters>(),
  contract_security_audits: _markResource({
    path: "/api/v2/smart-contracts/:hash/audit-reports",
    pathParams: ["hash"],
  })._set_response<SmartContractSecurityAudits>(),

  // HOMEPAGE
  homepage_stats: _markResource({
    path: "/gateway/api/v1/stats",
  })._set_response<HomeStats>(),
  homepage_chart_txs: _markResource({
    path: "/gateway/api/v1/stats/charts/transactions",
  })._set_response<ChartTransactionResponse>(),
  homepage_chart_market: _markResource({
    path: "/gateway/api/v1/stats/charts/market",
  })._set_response<ChartMarketResponse>(),
  homepage_blocks: _markResource({
    path: "/api/v2/main-page/blocks",
  })._set_response<BlocksResponse>(),
  homepage_deposits: _markResource({
    path: "/api/v2/main-page/optimism-deposits",
  })._set_response<any>(),
  homepage_txs: _markResource({
    path: "/api/v2/main-page/transactions",
  })._set_response<TransactionsResponseValidated>(),
  homepage_txs_watchlist: _markResource({
    path: "/api/v2/main-page/transactions/watchlist",
  })._set_response<TransactionsResponseWatchlist>(),
  homepage_indexing_status: _markResource({
    path: "/api/v2/main-page/indexing-status",
  })._set_response<IndexingStatus>(),

  // CONFIGS
  config_backend_version: _markResource({
    path: "/api/v2/config/backend-version",
  })._set_response<BackendVersionConfig>(),

  // API V1
  csv_export_txs: _markResource({
    path: "/gateway/api/v1/export/transactions-csv",
  })._set_response<any>(),
  csv_export_internal_txs: _markResource({
    path: "/api/v1/internal-transactions-csv",
  })._set_response<any>(),
  csv_export_token_transfers: _markResource({
    path: "/gateway/api/v1/export/token-transfers-csv",
  })._set_response<any>(),
  csv_export_logs: _markResource({
    path: "/api/v1/logs-csv",
  })._set_response<any>(),
  // CSV EXPORT
  csv_export_token_holders: _markResource({
    path: "/api/v2/tokens/:hash/holders/csv",
    pathParams: ["hash"],
  })._set_response<any>(),
  graphql: _markResource({
    path: "/api/v1/graphql",
  })._set_response<any>(),
} satisfies {
  [K in string]: IResourceRootItem;
};
