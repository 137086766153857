"use client";

import { LogtoProvider as Base, type LogtoConfig } from "@logto/react";
import {
  workspaceEndpoint,
  workspaceId,
  workspaceResource,
} from "configs/frontend/chain/auth";
import { memo, type ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const config = {
  appId: workspaceId,
  endpoint: workspaceEndpoint,
  resources: [workspaceResource],
} as LogtoConfig;

const LogtoProvider = ({ children }: Props) => {
  return <Base config={config}>{children}</Base>;
};

export default memo(LogtoProvider, (prev, next) => {
  return prev.children === next.children;
});
