import type { FlexProps } from "@chakra-ui/react";
import { Button, Center, Flex, Text, useDisclosure } from "@chakra-ui/react";
import useApiQuery from "lib/api/useApiQuery";
import { useWatchState } from "lib/hooks/useWatchState";
import {
  getRecentSearchKeywords,
  saveToRecentKeywords,
} from "lib/recentSearchKeywords";
import _, { omit } from "lodash";
import React, { type MutableRefObject } from "react";
import { Controller, useForm } from "react-hook-form";
import SearchPromotion from "ui/promotion/SearchPromotion";
import Divider from "ui/shared/Divider";
import IconSvg from "ui/shared/IconSvg";
import {
  PopoverModal,
  PopoverModalContent,
  PopoverModalTrigger,
} from "ui/shared/PopoverModal";
import type { PopoverModalContentProps } from "ui/shared/PopoverModal/PopoverModalContent";
import SearchBarRecentKeywords from "./SearchBarRecentKeywords";
import SearchBarSuggest from "./SearchBarSuggest/SearchBarSuggest";
import type { SearchInputProps } from "./SearchInput";
import SearchInput from "./SearchInput";

type Props = {
  isFullscreen?: boolean;
  searchInputProps?: SearchInputProps;
  resultProps?: FlexProps;
  contentProps?: PopoverModalContentProps;
  searchRef?: MutableRefObject<{
    onOpen: AnyFunction;
  }>;
};

const SCROLL_CONTAINER_ID = "search_bar_popover_content";

const SearchBarTop = ({
  searchInputProps,
  isFullscreen,
  resultProps,
  contentProps,
  searchRef,
}: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [q, setQ] = useWatchState("", [], { debounce: 300 });

  const { control, setValue, handleSubmit, getValues } = useForm({
    defaultValues: { search: "", recents: getRecentSearchKeywords() },
  });

  const query = useApiQuery("quick_search", {
    queryParams: { q: q },
    queryOptions: { enabled: q.trim().length > 0 },
  });

  const onValid = React.useCallback(
    ({ search, recents }: { search: string; recents: string[] }) => {
      if (search) {
        const results = saveToRecentKeywords(search, recents);
        setValue("recents", results);
      }
    },
    [],
  );

  const handleItemClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>, q: string) => {
      const results = saveToRecentKeywords(q, getValues("recents"));
      setValue("recents", results);
      onClose();
    },
    [onClose],
  );

  return (
    <>
      {!(isFullscreen === false) && (
        <Button
          order={2}
          variant="primary"
          boxSize={9}
          display={{ base: "flex", lg: "none" }}
          alignItems="center"
          justifyContent="center"
          padding={0}
          onClick={() => {
            searchRef?.current?.onOpen?.();
            document.body.style.overflowY = "hidden";
            onOpen();
          }}
        >
          <IconSvg name="search" boxSize={5} color="neutral.light.6" />
        </Button>
      )}

      <PopoverModal
        id="searchbar"
        zIndex={isFullscreen === false ? 2 : 998}
        isFullscreen={isFullscreen}
        isOpen={isOpen}
        onClose={onClose}
        backgroundColor={isFullscreen === false ? undefined : "neutral.light.1"}
      >
        <PopoverModalTrigger
          as={Flex}
          alignItems="center"
          gap={3}
          marginTop={{
            base: isFullscreen === false ? "unset" : 2,
            lg: "unset",
          }}
          marginX={{ base: isFullscreen === false ? "unset" : 4, lg: "unset" }}
        >
          {!(isFullscreen === false) && (
            <Button
              variant="unstyled"
              boxSize={6}
              display={{ base: "flex", lg: "none" }}
              justifyContent="center"
              alignItems="center"
              onClick={() => {
                onClose?.();
                document.body.style.overflowY = null as any;
              }}
            >
              <IconSvg
                name="arrows/east-left"
                color="neutral.light.6"
                boxSize="1.125rem"
              ></IconSvg>
            </Button>
          )}
          <Controller
            control={control}
            name="search"
            render={({ field: { value, onChange } }) => {
              return (
                <SearchInput
                  flex={1}
                  size="xs"
                  onChange={(e) => {
                    onChange(e.target.value);
                    setQ(e.target.value);
                  }}
                  value={value}
                  onClick={(e) => {
                    onOpen();
                    searchInputProps?.onClick?.(e);
                  }}
                  {...{
                    ...omit(searchInputProps, "onClick"),
                    groupProps: _.merge(
                      {
                        order: { lg: 3 },
                        width: {
                          base: "full",
                          // md: "10rem",
                          lg: isFullscreen === false ? "full" : "20rem",
                          "2lg": isFullscreen === false ? "full" : "25rem",
                        },
                        maxWidth: "full",
                        backgroundColor: "neutral.light.1",
                      },
                      searchInputProps?.groupProps,
                    ),
                  }}
                />
              );
            }}
          ></Controller>
        </PopoverModalTrigger>
        <PopoverModalContent overflow="hidden" {...contentProps}>
          <SearchPromotion
            width="full"
            paddingY={3}
            paddingX={4}
            mt={2}
          ></SearchPromotion>
          <Divider
            orientation="horizontal"
            width="full"
            flexShrink={0}
          ></Divider>
          <Flex
            flexDirection="column"
            width="full"
            alignItems="center"
            paddingBottom={3}
            flex={1}
            overflow="hidden"
            {...resultProps}
          >
            <Controller
              control={control}
              name="search"
              render={({ field: { value: search, onChange } }) => {
                return (
                  <>
                    <Controller
                      control={control}
                      name="recents"
                      render={({ field: { value } }) => (
                        <>
                          {Boolean(!search.trim().length && value.length) && (
                            <SearchBarRecentKeywords
                              onClick={(kw) => {
                                onChange(kw);
                                setQ(kw);
                                handleSubmit(onValid);
                              }}
                              onClear={() => {
                                setValue("recents", []);
                              }}
                            />
                          )}
                          {!q.trim().length && !value.length && (
                            <Center flex={1}>
                              <Text variant="light5">
                                Enter keyword for searching
                              </Text>
                            </Center>
                          )}
                        </>
                      )}
                    ></Controller>
                    {Boolean(search.trim().length) && (
                      <SearchBarSuggest
                        query={query}
                        q={q}
                        onItemClick={(e) => {
                          handleItemClick(e, q);
                        }}
                        containerId={SCROLL_CONTAINER_ID}
                      />
                    )}
                  </>
                );
              }}
            ></Controller>
          </Flex>
        </PopoverModalContent>
      </PopoverModal>
    </>
  );
};

export default SearchBarTop;
