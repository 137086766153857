import { chainConfigs } from "configs/frontend/chain/chainConfigs";
import Handlebars from "handlebars";
import type { Route } from "nextjs-routes";
import { description, keyword, title } from "./templates";

// Default templates for fallback
const DEFAULT_TITLE = title.DEFAULT_TEMPLATE;
const DEFAULT_DESCRIPTION = description.DEFAULT_TEMPLATE;
const DEFAULT_KEYWORD = keyword.DEFAULT_TEMPLATE;

// Compile templates safely
const compileTemplate = (
  template: string | undefined,
  fallback: string,
  context: Record<string, any>,
) => {
  try {
    if (!template) return fallback;
    return Handlebars.compile(template)(context);
  } catch {
    return fallback;
  }
};

/**
 * Generates SEO metadata (title, description, keyword) based on the route and query parameters.
 *
 * @param pathname - The route pathname.
 * @param query - Query parameters for dynamic content.
 * @param chain - Optional chain identifier.
 * @returns An object containing title, description, and keyword.
 */
export default function generate<R extends Route>(
  pathname: R["pathname"],
  query: R["query"],
  chain?: string,
) {
  try {
    // Prepare the context for template rendering
    const context = {
      ...query,
      network_name: "SEI",
      chain,
      chain_name: chainConfigs.find((c) => c.chainKey === chain)?.chainName,
    };

    // Retrieve raw templates from mappings
    const titleRaw = title.TITLE_MAP[pathname];
    const descriptionRaw = description.DESCRIPTION_MAP[pathname];
    const keywordRaw = keyword.KEYWORD_MAP[pathname];

    // Generate SEO metadata
    return {
      title: compileTemplate(titleRaw, DEFAULT_TITLE, context),
      description: compileTemplate(
        descriptionRaw,
        DEFAULT_DESCRIPTION,
        context,
      ),
      keywords: compileTemplate(keywordRaw, DEFAULT_KEYWORD, context),
    };
  } catch (error) {
    console.log("=== generate error ===");
    console.log(error.message);
    return {
      title: DEFAULT_TITLE,
      description: DEFAULT_DESCRIPTION,
      keywords: DEFAULT_KEYWORD,
    };
  }
}
