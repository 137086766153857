import type { Options } from "lib/hooks/useShallow";
import { useWatchState } from "lib/hooks/useWatchState";
import { useRouter } from "next/router";
import getQueryParamString from "./getQueryParamString";
import { useSetQuery } from "./useSetQuery";

export const useSetStateQuery = <
  T extends string | string[] | undefined = string | undefined,
  R extends Primitive | Primitive[] | undefined = T,
  E extends Primitive | Primitive[] | undefined = T,
>(
  queryName: any,
  watch?: any[],
  options?: Options & {
    cleanUp?: { keepQueries?: string[] };
    removeQueries?: string[];
    autoArray?: boolean;
    decode?: (value: T) => R;
    encode?: (value: E) => T;
  },
): [R, (nextValue: E | ((e: R) => E)) => void] => {
  const router = useRouter();
  const setQuery = useSetQuery(queryName, options);
  const [value, setValue] = useWatchState<R>(
    getQueryParamString(router.query[queryName]) as never,
    watch,
    {
      ...options,
      transform: options?.decode as any,
    },
  );

  return [
    value,
    (nextValue) => {
      const encoded = options?.encode
        ? options.encode(
            typeof nextValue === "function" ? nextValue(value) : nextValue,
          )
        : typeof nextValue === "function"
          ? nextValue(value)
          : nextValue;
      setValue(encoded, {
        preset: () => {
          setQuery(encoded);
        },
      });
    },
  ];
};
