import { WagmiAdapter } from "@reown/appkit-adapter-wagmi";
import type { AppKitNetwork } from "@reown/appkit/networks";
import { createAppKit } from "@reown/appkit/react";
import { chainKey } from "configs/frontend/chain/utils";
import { walletConnectProjectId } from "configs/hydration/wallet";
import { memo, useMemo, type ReactNode } from "react";
import {
  cookieStorage,
  createStorage,
  WagmiProvider,
  type Config,
} from "wagmi";
import {
  chainConfigs,
  MainNet,
} from "../../../configs/frontend/chain/chainConfigs";

// 2. Create a metadata object - optional
const metadata = {
  name: "Seitrace",
  description: "Connect your wallet to Seitrace",
  url: "https://seitrace.com", // origin must match your domain & subdomain
  icons: ["https://seitrace.com/icons/logo/logo.svg"],
};

// 3. Set the networks

// const connectors: CreateConnectorFn[] = [];
// connectors.push(injected({ shimDisconnect: true }));

// 4. Create Wagmi Adapter

// const wagmiConfig = createConfig({
//   chains: [chainConfig],
//   transports: {
//     [chainConfig.id]: http(chainConfig.rpcUrls.default.http[0]),
//   },
// });

// createWeb3Modal({
//   wagmiConfig,
//   defaultChain: chainConfig,
//   projectId: projectId,
//   themeMode: "light",

//   // allowUnsupportedChain: true,
//   themeVariables: {
//     // "--w3m-z-index": zIndices.modal,
//     // "--w3m-accent": colors.blue[600],
//     // "--w3m-border-radius-master": "2px",
//     // "--w3m-font-family": `${BODY_TYPEFACE}, sans-serif`,
//   },
// });

// const metadata = {
//   name: "Seitrace Connect",
//   description: "Seitrace Connect",
//   url: "https://seitrace.com", // origin must match your domain & subdomain
//   icons: ["https://seitrace.com/icons/logo/logo.svg"],
// };

// const metamaskMetadata = {
//   name: "Metamask",
//   description: "Metamask",
//   url: "https://metamask.io", // origin must match your domain & subdomain
//   iconUrl:
//     "https://raw.githubusercontent.com/MetaMask/brand-resources/refs/heads/master/SVG/SVG_MetaMask_Icon_Color.svg",
// };

// create the connectors (delete the ones you don't need)
// const connectors: CreateConnectorFn[] = [];
// connectors.push(
//   walletConnect({
//     qrModalOptions: {
//       themeVariables: {
//         "--wcm-z-index": "999",
//       },
//       themeMode: "light",
//     },
//     // disableProviderPing: true,
//     projectId,
//     showQrModal: true,
//   }),
// ); // showQrModal must be false
// connectors.push(injected({ shimDisconnect: true }));
// connectors.push(metaMask({ dappMetadata: metamaskMetadata })); // for mobile connection

// connectors.push(
//   coinbaseWallet({
//     appName: metadata.name,
//     appLogoUrl: metadata.icons[0],
//   }),
// );

// 4. Create Wagmi Adapter
// const wagmiAdapter = new WagmiAdapter({
//   storage: createStorage({
//     key: "wagmi",
//     storage: cookieStorage,
//   }),
//   networks: [chainConfig],
//   projectId: walletConnectProjectId,
//   ssr: true,
//   syncConnectedChain: true,
//   // connectors: connectors,
//   // [
//   // walletConnect({
//   //   qrModalOptions: {
//   //     themeVariables: {
//   //       "--wcm-z-index": "1000",
//   //     },
//   //     themeMode: "light",
//   //   },
//   //   // disableProviderPing: true,
//   //   projectId,
//   //   showQrModal: true,
//   // }),
//   // injected({
//   //   shimDisconnect: true,
//   // }),
//   // ],
//   // transports: {
//   //   [chainConfig.id]: http(chainConfig.rpcUrls.default.http[0]),
//   // },
// });

// const wagmiConfig = wagmiAdapter.wagmiConfig as Config;

// 5. Create modal

// createAppKit({
//   adapters: [wagmiAdapter],
//   networks: [chainConfig],
//   allowUnsupportedChain: true,
//   enableWalletConnect: true,
//   enableCoinbase: false,
//   enableEIP6963: true,
//   enableWallets: true,
//   enableInjected: true,
//   projectId: walletConnectProjectId,
//   features: {
//     email: true,
//     history: true,
//     // socials: true,
//     allWallets: true,
//     emailShowWallets: true,
//     smartSessions: true,
//     // swaps: false,
//     // onramp: false,
//   },
//   debug: false,
//   metadata: metadata,
//   // metadata,
//   // defaultNetwork: chainConfig,
//   // features: {
//   //   analytics: true, // Optional - defaults to your Cloud configuration
//   // },
//   themeMode: "light",
//   themeVariables: {
//     "--w3m-z-index": 999,
//     // "--w3m-accent": accentBlue,
//     // "--w3m-border-radius-master": "2px",
//     // "--w3m-font-family": bodyFont,
//   },
// });

/**
 * AppKitProvider
 * @param param0 children
 * @returns AppKitProvider
 */
const AppKitProvider = ({ children }: { children?: ReactNode }) => {
  const wagmiConfig = useMemo(() => {
    const chainConfig = (chainConfigs.find(
      (chain) => chain.chainKey === chainKey,
    )?.config ?? MainNet) as AppKitNetwork;
    const wagmiAdapter = new WagmiAdapter({
      storage: createStorage({
        key: "wagmi",
        storage: cookieStorage,
      }),
      networks: [chainConfig],
      projectId: walletConnectProjectId,
      ssr: true,
      syncConnectedChain: true,
    });

    const wagmiConfig = wagmiAdapter.wagmiConfig as Config;

    createAppKit({
      adapters: [wagmiAdapter],
      networks: [chainConfig],
      allowUnsupportedChain: true,
      enableWalletConnect: true,
      enableCoinbase: false,
      enableEIP6963: true,
      enableWallets: true,
      enableInjected: true,
      projectId: walletConnectProjectId,
      features: {
        email: true,
        history: true,
        // socials: true,
        allWallets: true,
        emailShowWallets: true,
        smartSessions: true,
        // swaps: false,
        // onramp: false,
      },
      debug: false,
      metadata: metadata,
      themeMode: "light",
      themeVariables: {
        "--w3m-z-index": 999,
      },
    });
    return wagmiConfig;
  }, []);

  return (
    <WagmiProvider
      reconnectOnMount={true}
      config={wagmiConfig}
      // initialState={initialState}
      // initialState={{
      //   chainId: chainConfig.id,
      //   connections: new Map<string, Connection>(),
      //   status: "disconnected",
      //   current: null,
      // }}
    >
      {children}
    </WagmiProvider>
  );
};

export default memo(AppKitProvider, (prev, next) => {
  return prev.children === next.children;
});
