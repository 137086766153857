import type { LinkProps } from "@chakra-ui/next-js";
import { Link } from "@chakra-ui/next-js";
import type { FlexProps } from "@chakra-ui/react";
import { Skeleton } from "@chakra-ui/react";
import type { ChakraProps } from "@chakra-ui/system";
import { chainKey } from "configs/frontend/chain/utils";
import { memo, useMemo, type ForwardedRef } from "react";

type Props = Partial<Omit<LinkProps, "href" | "color">> & {
  isLoading?: boolean;
  isDisabled?: boolean;
  isScrollTop?: boolean;
  href?: string | LinkProps["href"];
  color?: ChakraProps["color"];
  ref?: ForwardedRef<HTMLAnchorElement>;
};

export function addChainParamSearchParams(_href: any): string {
  const href = String(_href || "");
  const sym = String(href).includes("?") ? "&" : "?";
  return `${href}${sym}chain=${chainKey}`;
}

const LinkInternal = ({
  isLoading,
  isDisabled,
  isScrollTop,
  onClick,
  children,
  href: _href,
  target,
  ref,
  ...props
}: Props) => {
  const href = useMemo(() => addChainParamSearchParams(_href), [_href]);

  if (isLoading) {
    return (
      <Skeleton alignItems="center" {...(props as FlexProps)}>
        <span>{children}</span>
      </Skeleton>
    );
  }

  if (isDisabled || !href) {
    return children;
  }

  return (
    <Link
      href={href as any}
      target={target ?? "_self"}
      scroll={false}
      shallow={true}
      // prefetch={true}
      onClick={(e) => {
        if (isScrollTop) {
          document.body.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }

        onClick?.(e);
      }}
      display="inline-flex"
      alignItems="center"
      gap={2}
      {...(props as any)}
      ref={ref}
    >
      <>{children}</>
    </Link>
  );
};

export type LinkInternalProps = Props;

export default memo(LinkInternal);
