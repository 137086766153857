import { Box, Flex, Skeleton, Text } from "@chakra-ui/react";
import Link from "next/link";
import { type ReactNode, memo } from "react";
import Hint from "ui/shared/Hint";
import type { IconName } from "ui/shared/IconSvg";
import IconSvg from "ui/shared/IconSvg";

type Props = {
  icon: IconName;
  title: string;
  value: ReactNode;
  tooltipLabel?: ReactNode;
  url?: string;
  isLoading?: boolean;
};

const StatsItem = ({
  icon,
  title,
  value,
  tooltipLabel,
  url,
  isLoading,
}: Props) => {
  return (
    <Box
      gap={4}
      height="full"
      position="relative"
      paddingX={{ base: 4, md: 5 }}
      paddingY={{ base: 4, md: 2 }}
      borderRightWidth={{ base: "0px", md: "1px" }}
      _last={{
        borderRightWidth: "0px",
      }}
    >
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        boxSize="full"
        borderColor="neutral.light.3"
        rowGap={4}
        {...(url && !isLoading
          ? {
              as: Link,
              href: url,
            }
          : {})}
      >
        <IconSvg
          name={icon}
          boxSize={"32px"}
          isLoading={isLoading}
          borderRadius="base"
        />
        <Flex flexDirection="column" alignItems="start" gap={1}>
          <Skeleton isLoaded={!isLoading} borderRadius="base">
            <Text textStyle="175" color="neutral.light.8">
              {value}
            </Text>
          </Skeleton>
          <Skeleton
            isLoaded={!isLoading}
            color="neutral.light.7"
            borderRadius="base"
            display="flex"
            gap={1}
            alignItems="center"
            height={5}
          >
            <Text textStyle="8125">{title}</Text>
            {tooltipLabel && (
              <Hint
                label={tooltipLabel}
                tooltipProps={{
                  borderRadius: "md",
                }}
                isLoading={isLoading}
              />
            )}
          </Skeleton>
        </Flex>
      </Flex>
    </Box>
  );
};

export default memo(StatsItem, (prev, next) => {
  return (
    prev.icon === next.icon &&
    prev.title === next.title &&
    prev.value === next.value &&
    prev.tooltipLabel === next.tooltipLabel &&
    prev.url === next.url &&
    prev.isLoading === next.isLoading
  );
});
