import { getIsNilEVMAddress } from "lib/getOSType";
import { useMemo } from "react";
import Hint, { type HintProps } from "ui/shared/Hint";

type Props = {
  isLoading?: boolean;
  hash: string | undefined | null;
  showWarning: "burn" | undefined;
} & Partial<HintProps>;

const AddressWarning = ({ hash, isLoading, showWarning, ...props }: Props) => {
  const label = useMemo(() => {
    if (showWarning === "burn")
      if (getIsNilEVMAddress(hash)) {
        return "Null Address - Tokens sent here are burned forever";
      }
    if (!hash) {
      return "Token may be burned or owner data needs refresh";
    }

    return undefined;
  }, [hash]);
  if (!label) return <></>;
  return (
    <Hint
      label={label}
      isLoading={isLoading}
      tooltipProps={{
        placement: "top",
      }}
      {...props}
    />
  );
};

export default AddressWarning;
