import { memo } from "react";
import type { WorkspaceUserInfo } from "types/api/account";
import LayoutProvider from "ui/shared/layout/components/LayoutProvider";
import { useSidebarNavs } from "./hooks/useSidebarNavs";
import ProfileMenuAvatar from "./ProfileMenuAvatar";
import ProfileMenuItems from "./ProfileMenuItems";

type Props = {
  user: WorkspaceUserInfo;
  onClose: AnyFunction;
};

const ProfileMenuChecked = ({ user, onClose }: Props) => {
  const filteredNavs = useSidebarNavs(user);

  return (
    <LayoutProvider navs={filteredNavs}>
      <ProfileMenuAvatar user={user} onClose={onClose} />
      <ProfileMenuItems
        navs={filteredNavs}
        onClose={onClose}
      ></ProfileMenuItems>
    </LayoutProvider>
  );
};

export default memo(
  ProfileMenuChecked,
  (prev, next) => prev.user === next.user,
);
