const breakpoints = {
  // maybe we need them in future
  sm: "375px",
  ssm: "425px",
  sssm: "500px",
  md: "768px",
  lg: "1024px",
  "2lg": "1200px",
  xl: "1440px",
  // these breakpoint are needed just to make others work
  "2xl": "3000px",
};

export default breakpoints;
