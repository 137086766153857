import type { ChakraProps, ImageProps, TextProps } from "@chakra-ui/react";
import { Flex, Image, Skeleton, Tooltip } from "@chakra-ui/react";
import useApiQuery from "lib/api/useApiQuery";
import { getIsNFT } from "lib/getOSType";
import _ from "lodash";
import { route } from "nextjs-routes";
import { memo, useCallback, useMemo, type ReactNode } from "react";
import type { IIBCTokenType } from "types/api/ibcRelayer";
import type { TokenInfo } from "types/api/token";
import type { TokenTotalPayload } from "types/api/tokenTransfer";
import type { TagProps } from "ui/shared/chakra/Tag";
import CopyToClipboardAsync, {
  type CopyToClipboardAsyncProps,
} from "ui/shared/copyToClipboard/CopyToClipboardAsync";
import { AssociationTag } from "ui/shared/entities/base/components";
import type { IconSvgProps } from "ui/shared/IconSvg";
import IconSvg from "ui/shared/IconSvg";
import LinkInternal from "ui/shared/LinkInternal";
import {
  TruncatedTextTail,
  type TruncatedTextTooltipProps,
} from "ui/shared/truncate";
import { TEXT_PROPS } from "../base/utils";

type IToken =
  | (Partial<Omit<TokenInfo, "address" | "type" | "decimals">> & {
      type?: TokenInfo["type"] | IIBCTokenType | null;
      address: string | undefined | null;
      decimals?: string | number | null | undefined;
    })
  | undefined
  | null;

type LinkProps = {
  href: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  children: ReactNode;
} & ChakraProps;

const Link = ({
  isDisabled,
  isLoading,
  children,
  href,
  ...props
}: LinkProps) => {
  if (isDisabled || isLoading) return children;

  return (
    <LinkInternal href={href} overflow="hidden" color="accent.blue" {...props}>
      {children}
    </LinkInternal>
  );
};

type ConfirmIconPosition = "icon" | "name" | "symbol";

type ConfirmIconProps = {
  confirmIconPosition: ConfirmIconPosition;
  isLoading?: boolean;
  token: (IToken & { address: string | Falsy }) | undefined | null;
} & Partial<IconSvgProps>;

const ConfirmIcon = ({
  isLoading,
  token,
  confirmIconPosition,
  ...props
}: ConfirmIconProps) => {
  const { data, isFetching: isGlobalTagsLoading } = useApiQuery(
    "global_public_tags",
    {
      queryOptions: {
        enabled: !isLoading,
        staleTime: Infinity,
        retry: false,
      },
    },
  );

  const isConfirm = useMemo(
    () =>
      Boolean(
        data?.some(
          (publicTag) =>
            publicTag.address_hash.toLocaleLowerCase() ===
            token?.address?.toLocaleLowerCase(),
        ),
      ),
    [data, token?.address],
  );

  if (!isConfirm) return <></>;

  return (
    <Tooltip label="Reputation OK: evaluated to be a token of public interest by Seitrace">
      <IconSvg
        isLoading={isLoading || isGlobalTagsLoading}
        name="verified_v2"
        boxSize={5}
        color="secondary.04"
        position={(confirmIconPosition === "icon" && "absolute") || "static"}
        borderRadius="full"
        backgroundColor="transparent"
        overflow="hidden"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bottom="-0.12rem"
        right={0}
        transform={
          (confirmIconPosition === "icon" && "translate(50%, 0)") || undefined
        }
        {...props}
      />
    </Tooltip>
  );
};

type IconProps = {
  token: IToken;
  isLoading?: boolean;
  noIcon?: boolean;
  confirmIconProps?: Partial<IconSvgProps>;
  showConfirm?: boolean;
} & Partial<ImageProps>;

const Icon = ({
  noIcon,
  token,
  isLoading: _isLoading,
  showConfirm,
  confirmIconProps,
  boxSize = 4,
  ...props
}: IconProps) => {
  const isNFT = useMemo(() => getIsNFT(token?.type) === "nft", [token?.type]);

  const identifier = useMemo(
    () => token?.address || token?.token_denom || undefined,
    [token?.address, token?.token_denom],
  );

  const { data: _src, isFetching } = useApiQuery("asset_info", {
    queryParams: {
      identifier: identifier,
    },
    queryOptions: {
      select: (data) => {
        return data?.image || undefined;
      },
      staleTime: Infinity,
      retry: false,
      enabled: Boolean(
        identifier && identifier !== "usei" && !_isLoading && !noIcon,
      ),
    },
  });

  const isLoading = isFetching || _isLoading;

  const src = useMemo(
    () =>
      _src ||
      (identifier === "usei" && "/icons/sei.svg") ||
      token?.icon_url ||
      (isNFT && "/icons/nft-placeholder.svg") ||
      "/icons/token-placeholder.svg",
    [_src, identifier, token?.icon_url, isNFT],
  );

  if (noIcon) {
    return <></>;
  }

  if (isLoading)
    return (
      <Skeleton boxSize={boxSize} borderRadius="full" flexShrink={0}></Skeleton>
    );

  return (
    <>
      <Image
        boxSize={boxSize}
        flexShrink={0}
        loading="lazy"
        borderRadius="full"
        aria-invalid={
          !src ||
          src === "/icons/token-placeholder.svg" ||
          src === "/icons/nft-placeholder.svg" ||
          undefined
        }
        src={src}
        alt={token?.symbol || token?.name || "token"}
        objectFit="cover"
        borderStyle="solid"
        backgroundColor="neutral.light.2"
        _invalid={{
          borderColor: "neutral.light.4",
          borderWidth: "0.5px",
        }}
        onError={(e) => {
          const currentSrc = e.currentTarget.src;
          if (
            currentSrc === "/icons/token-placeholder.svg" ||
            currentSrc === "/icons/nft-placeholder.svg"
          ) {
            return;
          }

          if (isNFT) {
            e.currentTarget.src = "/icons/nft-placeholder.svg";
            return;
          } else {
            e.currentTarget.src = "/icons/token-placeholder.svg";
            return;
          }
        }}
        {...props}
      />

      {showConfirm && (
        <ConfirmIcon
          confirmIconPosition="icon"
          token={token}
          isLoading={_isLoading}
          {...confirmIconProps}
        ></ConfirmIcon>
      )}
    </>
  );
};

type NameProps = {
  token: IToken;
  noName?: boolean;
  isLoading?: boolean;
  noTooltip?: boolean;
  tooltipProps?: Partial<TruncatedTextTooltipProps>;
  fallback?: string;
  linkProps?: Partial<LinkProps>;
  noLink?: boolean;
  showFullyInfo?: boolean;
} & TextProps;

const Name = ({
  token,
  noName,
  isLoading,
  noTooltip,
  tooltipProps,
  fallback,
  linkProps,
  noLink,
  showFullyInfo,
  ...props
}: NameProps) => {
  const nameString = useMemo(
    () => token?.name || token?.base_denom || token?.token_denom,
    [
      token?.address,
      token?.base_denom,
      token?.name,
      token?.symbol,
      token?.token_denom,
    ],
  );

  const defaultHref = useMemo(
    () =>
      route({
        pathname: "/token/[...slug]",
        query: {
          slug: [token?.address || ""],
        },
      }),
    [token?.address],
  );
  const isLinkDisabled = useMemo(
    () =>
      noLink ||
      !token?.type ||
      (!token.address && !token.base_denom) ||
      // token.address?.startsWith("factory/") ||
      // token.base_denom?.startsWith("factory/") ||
      // token.address?.startsWith("ibc/") ||
      // token.base_denom?.startsWith("ibc/") ||
      token?.address === "usei" ||
      token?.base_denom === "usei",
    [token?.type, token?.address, noLink],
  );

  if (noName) {
    return <></>;
  }

  return (
    <Link
      href={defaultHref}
      isLoading={isLoading}
      isDisabled={isLinkDisabled}
      {...linkProps}
    >
      <TruncatedTextTail
        isDisabled={!nameString || noTooltip}
        isLoading={isLoading}
        fallback={fallback ?? "Unnamed Token"}
        textStyle="875"
        tooltipProps={{
          defaultIsTruncated: showFullyInfo,
          highPriorityIsTruncated: showFullyInfo,
          placement: "top",
          ...tooltipProps,
        }}
        label={
          showFullyInfo
            ? `${nameString || ""} ${token?.symbol ? `(${token.symbol})` : ""}`
            : undefined
        }
        {...props}
      >
        {nameString}
      </TruncatedTextTail>
    </Link>
  );
};

type SymbolProps = {
  token: IToken;
  noSymbol?: boolean;
  isLoading?: boolean;
} & TextProps;

const Symbol = ({ token, noSymbol, isLoading, ...props }: SymbolProps) => {
  const symbol = token?.symbol;

  if (noSymbol || !symbol) {
    return <></>;
  }

  return (
    <TruncatedTextTail isLoading={isLoading} {...props}>
      {symbol}
    </TruncatedTextTail>
  );
};

type CopyProps = {
  noCopy?: boolean;
  token: IToken;
  isLoading?: boolean;
} & CopyToClipboardAsyncProps;

const Copy = ({ noCopy, token, isLoading, ...props }: CopyProps) => {
  const setValue = useCallback(() => token?.address, [token?.address]);

  if (noCopy || !token?.address) {
    return <></>;
  }

  return (
    <CopyToClipboardAsync
      isLoading={isLoading}
      setValue={setValue}
      {...props}
    ></CopyToClipboardAsync>
  );
};

export type TokenV2Props = {
  token: IToken;
  noSymbol?: boolean;
  onlySymbol?: boolean;
  iconProps?: Omit<
    Partial<IconProps>,
    "confirmIconProps" | "token" | "confirmIconPosition"
  >;
  linkProps?: Partial<LinkProps>;
  symbolProps?: Partial<SymbolProps>;
  confirmIconProps?: Partial<IconSvgProps>;
  /**
   * Position of the confirm icon
   * @default "symbol"
   */
  confirmIconPosition?: ConfirmIconPosition | "none";
  nameProps?: Omit<NameProps, "token">;
  fallback?: string;
  isLoading?: boolean;
  noTooltip?: boolean;
  tooltipProps?: Partial<TruncatedTextTooltipProps>;
  showAssociation?: boolean;
  noIcon?: boolean;
  noCopy?: boolean;
  noLink?: boolean;
  copyProps?: Partial<Omit<CopyProps, "token">>;
  total?: TokenTotalPayload;
  showFullyInfo?: boolean;
  associationProps?: TagProps;
} & TextProps;

const TokenV2 = ({
  iconProps,
  linkProps,
  symbolProps,
  noIcon,
  confirmIconProps,
  confirmIconPosition = "symbol",
  showAssociation,
  token: _token,
  isLoading,
  noTooltip,
  tooltipProps,
  fallback,
  nameProps,
  noCopy,
  noLink,
  copyProps,
  total,
  noSymbol,
  showFullyInfo,
  associationProps,
  ...props
}: TokenV2Props) => {
  const token = useMemo(() => {
    return _.chain(_token || ({} as IToken))
      .tap((item) => {
        if (total?.denom === "usei") {
          item["name"] = "Sei";
          item["token_denom"] = "usei";
          return;
        }
        if (
          total?.denom?.startsWith("ibc/") ||
          total?.denom?.startsWith("factory/")
        ) {
          item["name"] = total.denom;
          item["token_denom"] = total.denom;
        }
      })
      .value();
  }, [_token, total]);

  const [partProps, otherProps] = useSplitProps(props, TEXT_PROPS);

  return (
    <Flex gap={2} alignItems="center" overflow="hidden" {...otherProps}>
      <Icon
        token={token}
        isLoading={isLoading}
        noIcon={noIcon}
        confirmIconProps={confirmIconProps}
        showConfirm={confirmIconPosition === "icon"}
        {...iconProps}
      />

      <Name
        token={token}
        isLoading={isLoading}
        noTooltip={noTooltip}
        tooltipProps={tooltipProps}
        fallback={fallback}
        linkProps={linkProps}
        noLink={noLink}
        showFullyInfo={showFullyInfo}
        {...partProps}
        {...nameProps}
      />

      {confirmIconPosition === "name" && (
        <ConfirmIcon
          isLoading={isLoading}
          confirmIconPosition="name"
          token={token}
          {...confirmIconProps}
        ></ConfirmIcon>
      )}
      <Symbol
        isLoading={isLoading}
        token={token}
        noSymbol={noSymbol}
        {...symbolProps}
      />
      {confirmIconPosition === "symbol" && (
        <ConfirmIcon
          confirmIconPosition="symbol"
          token={token}
          isLoading={isLoading}
          {...confirmIconProps}
        ></ConfirmIcon>
      )}
      <AssociationTag
        token={token as TokenInfo}
        isLoading={isLoading}
        address={token?.address}
        association={token?.association}
        showAssociation={showAssociation}
        {...associationProps}
      ></AssociationTag>
      <Copy
        isLoading={isLoading}
        token={token}
        noCopy={noCopy}
        {...copyProps}
      />
    </Flex>
  );
};

export const TokenIcon = memo(Icon);
export type TokenIconProps = IconProps;

export default memo(TokenV2, (prev, next) => {
  return (
    prev.token?.address === next.token?.address &&
    prev.isLoading === next.isLoading &&
    prev.total?.denom === next.total?.denom
  );
});
