import type { BoxProps, IconButtonProps } from "@chakra-ui/react";
import { Button, IconButton, Skeleton, useDisclosure } from "@chakra-ui/react";
import { memo, useCallback } from "react";

import { useLogto } from "@logto/react";

import { workspaceCallback } from "configs/frontend/chain/auth";
import useFetchProfileInfo from "lib/hooks/useFetchProfileInfo";
import useIsMobile from "lib/hooks/useIsMobile";
import _ from "lodash";
import { useRouter } from "next/router";
import {
  PopoverModal,
  PopoverModalContent,
  PopoverModalTrigger,
} from "ui/shared/PopoverModal";
import UserAvatar from "ui/shared/UserAvatar";
import ProfileMenuChecked from "./ProfileMenuChecked";

type Props = {
  onClick?: IconButtonProps["onClick"];
} & BoxProps;

const ProfileMenu = ({ onClick, ...props }: Props) => {
  const { signIn } = useLogto();
  const router = useRouter();
  const { data: user, isLoading } = useFetchProfileInfo();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleSignInClick = useCallback(() => {
    signIn(workspaceCallback);
  }, [signIn]);

  const isMobile = useIsMobile();
  if (!user && isMobile) {
    return <></>;
  }

  if (router.pathname.startsWith("/auth/profile") && isMobile) {
    return <></>;
  }

  return (
    <PopoverModal
      isOpen={isOpen}
      onClose={onClose}
      isFullscreen={false}
      openDirection="zoom"
      borderRadius="full"
      display={{
        base: "flex",
        lg: "flex",
      }}
      {...props}
    >
      <PopoverModalTrigger borderRadius="full">
        {_.chain(null)
          .thru(() => {
            if (isLoading) {
              return (
                <Skeleton
                  flexShrink={0}
                  boxSize="2.25rem"
                  borderRadius="full"
                ></Skeleton>
              );
            }

            if (user) {
              return (
                <IconButton
                  aria-label="profile menu"
                  icon={<UserAvatar user={user} boxSize="2.25rem" />}
                  variant="outline"
                  colorScheme="blue"
                  boxSize="2.25rem"
                  padding={0}
                  flexShrink={0}
                  borderRadius="full"
                  borderWidth="0.5px"
                  borderColor="accent.orange"
                  overflow="hidden"
                  onClick={(e) => {
                    onOpen();
                    e.currentTarget?.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                    onClick?.(e);
                  }}
                />
              );
            }

            return (
              <Button
                flexShrink={0}
                fontSize="sm"
                variant="solid"
                width="6.25rem"
                height="2.25rem"
                loadingText="Signing in..."
                isLoading={isLoading}
                onClick={handleSignInClick}
              >
                Sign In
              </Button>
            );
          })
          .value()}
      </PopoverModalTrigger>
      <PopoverModalContent
        right={{ base: "-0.75rem", lg: "0px" }}
        top={{ base: "calc(100% + 5px)", lg: "cacl(100% + 10px)" }}
        width="17.5rem"
        padding="2rem 0.75rem 0.75rem 0.75rem"
        overflow="hidden"
        zIndex={999}
        // ref={contentRef}
      >
        {user && <ProfileMenuChecked user={user} onClose={onClose} />}
      </PopoverModalContent>
    </PopoverModal>
  );
};

export default memo(ProfileMenu, (prev, next) => {
  return prev.onClick === next.onClick;
});
