import { useEffect, useMemo, useState, useTransition } from "react";

import { DAY, HOUR, MINUTE, SECOND } from "lib/consts";
import moment from "lib/date/moment";

const getIntervals = (diff: string) => {
  if (diff.includes("sec") || diff.includes("secs")) return SECOND;
  if (diff.includes("min") || diff.includes("mins")) return MINUTE;
  if (diff.includes("hr") || diff.includes("hrs")) return HOUR;
  if (diff.includes("days")) return DAY;
  return null;
};

const getValue = (ts: moment.MomentInput) => {
  const mm = moment(ts);
  if (mm.isValid()) return mm;
  return null;
};

export default function useTimeAgoIncrement(
  ts: moment.MomentInput | undefined | null,
  isEnabled?: boolean,
) {
  const mm = useMemo(() => getValue(ts), [ts]);
  const [value, setValue] = useState(mm?.fromNow() || "");
  const [, startTransition] = useTransition();

  useEffect(() => {
    if (!mm) return;
    if (!value) return;
    if (!isEnabled) return;
    const intervalTime = getIntervals(value);
    if (!intervalTime) return;
    const interval = setInterval(() => {
      startTransition(() => setValue(mm.fromNow()));
    }, intervalTime);
    return () => clearInterval(interval);
  }, [isEnabled, mm]);

  return value;
}
