import { setQuery } from "lib/router/setQuery";
import { memo, useEffect } from "react";

type Props = {
  chain: string;
};

const AppTrigger = ({ chain }: Props) => {
  useEffect(() => {
    setQuery("chain", chain);
  }, []);

  return <></>;
};

export default memo(AppTrigger, () => true);
