import { useLogto } from "@logto/react";
import { useQueryClient } from "@tanstack/react-query";
import type { AxiosRequestConfig } from "axios";
import { workspaceResource } from "configs/frontend/chain/auth";
import * as cookies from "lib/cookies";
import { authEmitter } from "lib/events/authEmitter";
import type { Params as FetchParams } from "lib/hooks/useFetch";
import { rawFetch } from "lib/hooks/useFetch";
import _, { pickBy } from "lodash";
import { useCallback } from "react";
import type { CsrfData } from "types/client/account";
import buildUrl from "./buildUrl";
import isBodyAllowed from "./isBodyAllowed";
import { RESOURCES } from "./mapping";
import {
  type IResourceName,
  type IResourcePathParam,
  type IResourceRootItem,
  type IResponse,
} from "./resources";
import { getResourceKey } from "./useApiQuery";

export interface Params<R extends IResourceName> {
  pathParams?: IResourcePathParam<R>;
  queryParams?: Record<string, string | Array<string> | number | undefined>;
  fetchParams?: Pick<FetchParams, "body" | "method" | "signal">;
  configs?: AxiosRequestConfig;
}

export default function useApiFetch() {
  const getAccessToken = useLogto?.()?.getAccessToken;
  const queryClient = useQueryClient();

  return useCallback(
    async <R extends IResourceName, T = IResponse<R>>(
      resourceName: R,
      { pathParams, queryParams, fetchParams, configs }: Params<R> = {},
    ) => {
      const resource = RESOURCES[resourceName] as IResourceRootItem;

      const csrfToken = _.chain(isBodyAllowed(fetchParams?.method))
        .thru((allowed) => {
          if (!allowed) return undefined;
          return queryClient.getQueryData<CsrfData>(getResourceKey("csrf"))
            ?.token;
        })
        .value();

      const accessToken = await (async () => {
        if (resource.endpoint && resource.needAuth) {
          const token =
            (await getAccessToken(workspaceResource)) &&
            cookies.get(cookies.NAMES.API_TOKEN);
          if (!token) throw new Error("Blockscout Token not found");
          return token;
        }

        if (resource.needWorkspaceAuth) {
          const token = await getAccessToken(workspaceResource);
          if (!token) throw new Error("Workspace Token not found");
          return `Bearer ${token}`;
        }
      })().catch((error: any) => {
        authEmitter.emit("AUTH::INVALID", error);
        // console.log(authEmitter.listenerCount("AUTH::INVALID"));
        return Promise.reject(error);
      });

      const headers = pickBy(
        {
          "x-endpoint": undefined,
          Authorization: accessToken,
          "x-csrf-token": csrfToken || undefined,
        },
        Boolean,
      ) as AxiosRequestConfig["headers"];

      const url = buildUrl(resourceName, pathParams, queryParams);

      return rawFetch<T>(
        url,
        {
          headers,
          ...fetchParams,
        },
        {
          withCredentials: true,
          ...configs,
        },
      ).catch((error) => {
        if (error.status === 401 || error.status === 403) {
          authEmitter.emit("AUTH::INVALID", error);
          // console.log(authEmitter.listenerCount("AUTH::INVALID"));
        }
        return Promise.reject(error);
      });
    },
    [getAccessToken, queryClient],
  );
}
