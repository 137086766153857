import type { Route } from "nextjs-routes";

export const DEFAULT_TEMPLATE =
  "Seitrace | Leading Sei Blockchain Explorer & Defi hub";

export const TITLE_MAP: Partial<Record<Route["pathname"], string>> = {
  "/": DEFAULT_TEMPLATE,
  "/txs": "Sei Network Transactions Information | Seitrace",
  "/tx/[hash]": "Sei Network Transaction Details | Seitrace",
  "/blocks": "Sei Network Blocks | Seitrace",
  "/block/[height_or_hash]": "Sei Network Block #{{height_or_hash}} | Seitrace",
  "/accounts": "Top Accounts by SEI balance | Seitrace",
  "/address/[hash]": "Address details | Address {{hash}} | Seitrace",
  "/verified-contracts": "SEI Network Verified Contracts | Seitrace",

  "/address/[hash]/contract-verification":
    "Verify & Publish Contract Source Code for {{hash}} | Seitrace",

  "/token/[...slug]": `{{#if is_nft}}
{{#if is_instance}}
Collection{{#if token_name}} {{token_name}}{{/if}}{{#if token_symbol}} ({{token_symbol}}){{/if}} Tracker #{{token_id}} | Seitrace
{{else}}
Collection{{#if token_name}} {{token_name}}{{/if}}{{#if token_symbol}} ({{token_symbol}}){{/if}} Tracker | Seitrace
{{/if}}
{{else}}
Token{{#if token_name}} {{token_name}}{{/if}}{{#if token_symbol}} ({{token_symbol}}){{/if}} Tracker | Seitrace
{{/if}}`,

  "/stats": "Sei Network Charts and Statistics | Seitrace",
  "/api-docs": "REST API | Seitrace",
  "/auth/profile": "My profile | Seitrace",
  "/auth/profile/account": "My profile | Seitrace",
  "/auth/profile/watchlist": "Watch list | Seitrace",
  "/auth/profile/private-tags": "Private tags | Seitrace",
  "/auth/profile/api-keys": "API keys | Seitrace",
  "/auth/profile/custom-abi": "Custom ABI | Seitrace",
  "/csv-export": "Export data to CSV | Seitrace",
  "/404": "Page Not Found - 404 page | Seitrace",

  "/validators": "Sei Network Validators Stats & Analysis | Seitrace",
  "/validator/[hash]": "Seitrace | Sei Network Validator {{hash}}",
  "/proposals": "Sei Network Governance Proposals | Seitrace",
  "/proposal/[id]": "Sei Network Governance Proposal #{{id}} | Seitrace",

  // ibc
  "/ibc-relayers": "Sei Network IBC Relayers | Seitrace",
  "/ibc-relayer/[channel_id]/counterparty/[counterparty_channel_id]":
    "Sei Network IBC Relayer Sei {{chain_name}} - {{channel_id}} details | Seitrace",

  // pamaters
  "/parameters": "Sei Network Parameters | Seitrace",

  // subscription
  "/insights": "Sei Network APIs (Insights) | Seitrace",
  "/insights-docs": "Sei Network APIs (Insights) Documents | Seitrace",
  "/subscriptions/[id]": "API subscription | Seitrace",

  // Reward claim
  "/claim-reward": "Sei Rewards - Claim Airdrop on Sei Blockchain | Seitrace",
  get "/claim-reward/[id]"() {
    return this["/claim-reward"];
  },

  // "/claim-reward/[id]/address/[address]": "Sei Rewards",

  "/code-ids": "SEI Network Code IDs | Seitrace",

  "/contract-verification":
    "Verify & Publish EVM Contract Source Code | Seitrace",

  "/tokens": "Fungible Tokens Tracker | Seitrace",
  "/nfts": "Non Fungible Tokens (NFTs) Tracker | Seitrace",
  "/hybrids": "Hybrid Tokens Tracker | Seitrace",

  "/verified-assets": "Sei blockchain verified assets | Seitrace",

  "/tool/wallet-profile": "Sei blockchain - Track wallet profile | Seitrace",
  "/tool/multisender": "Sei blockchain - Multisender | Seitrace",

  "/about": "About Seitrace",
};
