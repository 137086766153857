import {
  Box,
  HStack,
  Menu,
  MenuButton,
  MenuGroup,
  MenuList,
  type BoxProps,
} from "@chakra-ui/react";
import { chainConfigs } from "configs/frontend/chain/chainConfigs";
import { currentChainConfig } from "lib/hooks/useCurrentChain";
import { memo } from "react";
import OptimizationImage from "ui/shared/OptimizationImage";
import ChainItem from "./ChainItem";
import ChainTag from "./ChainTag";

type Props = BoxProps;

const ChainSelect = (props: Props) => {
  return (
    <Box position="relative" height={9} order={{ base: 2, lg: 3 }} {...props}>
      <Menu placement="top-end">
        <MenuButton
          position="relative"
          height={9}
          _active={{
            borderColor: "primary.light.4",
          }}
          borderWidth="1px"
          borderRadius="0.5rem"
          backgroundColor="neutral.light.1"
          _hover={{
            backgroundColor: "primary.light.1",
          }}
          cursor="pointer"
          borderColor="neutral.light.3"
        >
          <HStack spacing={2} padding={2} textStyle="875">
            <OptimizationImage
              src="/images/sei.svg"
              alt="select_chain"
              hasWrapper
              wrapperProps={{
                boxSize: 5,
                rounded: "full",
                overflow: "hidden",
                flexShrink: 0,
              }}
            />
            <HStack color="neutral.light.7" whiteSpace="pre-line" spacing={1}>
              <span>
                {currentChainConfig?.networkType?.capitalizeFirstLetter() ||
                  "Select chain"}
              </span>
              <ChainTag chainKey={currentChainConfig?.chainKey || ""} />
            </HStack>
          </HStack>
        </MenuButton>

        <MenuList
          zIndex={1}
          borderWidth="1px"
          borderColor="neutral.light.3"
          borderRadius="0.5rem"
          paddingY={1}
        >
          <MenuGroup
            title="Select chain"
            textStyle="8125"
            fontWeight={400}
            color="neutral.light.5"
            paddingX={3}
            paddingTop={1}
            paddingBottom="1px"
            borderBottomWidth="1px"
            borderBottomColor="neutral.light.3"
            margin={0}
            marginBottom={0}
            marginTop={0}
            marginInlineStart={0}
            marginInlineEnd={0}
          >
            {chainConfigs.map((item) => {
              return <ChainItem key={item.chainKey} item={item} />;
            })}
          </MenuGroup>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default memo(ChainSelect, () => true);
