import type { AxiosResponse } from "axios";
import type { DefaultFilter } from "types/api/base";
import type { RESOURCES } from "./mapping";

export type IResourceRootItem = {
  readonly path: string;
  readonly pathParams?: string[];
  readonly filterFields?: string[];
  readonly endpoint?: string;
  readonly basePath?: string;
  readonly needAuth?: boolean;
  readonly needWorkspaceAuth?: boolean;
};

type IMarkResource = <
  P extends Readonly<string>,
  Ps extends string[] | undefined = undefined,
  Fs extends string[] | undefined = undefined,
  Et extends string | undefined = undefined,
  Bp extends string | undefined = undefined,
  Na extends boolean | undefined = undefined,
  Nwa extends boolean | undefined = undefined,
  F extends object = RemoveUndefined<{
    readonly path: P;
    readonly pathParams: Ps;
    readonly filterFields: Fs extends string[]
      ? (Fs[number] | keyof DefaultFilter)[]
      : undefined;
    readonly endpoint: Et;
    readonly basePath: Bp;
    readonly needAuth: Na;
    readonly needWorkspaceAuth: Nwa;
  }>,
  R = F & {
    readonly _response: any;
    readonly _pageable: false;
    _set_response: <R = any, P extends boolean = false>() => {
      _response: R;
      _pageable: P;
    } & F;
  },
>(_format: {
  readonly path: P;
  readonly pathParams?: Ps;
  readonly filterFields?: Fs;
  readonly endpoint?: Et;
  readonly basePath?: Bp;
  readonly needAuth?: Na;
  readonly needWorkspaceAuth?: Nwa;
}) => R;

export const _markResource: IMarkResource = (_format) => {
  // @ts-ignore
  _format._set_response = () => _format;

  return _format as any;
};

export type IResource = typeof RESOURCES;

export type IResourceName = keyof IResource;

export type IResourceItem<R extends IResourceName> = IResource[R];

export type IResourcePath<R extends IResourceName> = IResourceItem<R>["path"];

export type IResourceFilterKey<R extends IResourceName> =
  IResourceItem<R> extends { filterFields: Array<string> }
    ? Extract<IResourceItem<R>["filterFields"][number], string>
    : never;

export type IResourceFilter<R extends IResourceName> = {
  [K in IResourceFilterKey<R>]?: any;
};

export type IResourcePathParamKey<R extends IResourceName> =
  IResourceItem<R> extends {
    pathParams: Array<unknown>;
  }
    ? Extract<IResourceItem<R>["pathParams"][number], string>
    : never;

export type IResourcePathParam<R extends IResourceName> = {
  [K in IResourcePathParamKey<R>]: string | undefined;
};

export const resourceKey = <R extends IResourceName>(x: R): string => x;

// export type IAPIRequest<R extends IResourceName> = {
//   path: IResourcePath<R>;
//   pathParams?: IResourcePathParams<R>[];
//   endpoint?: string;
//   basePath?: string;
//   needAuth?: boolean; // for external APIs which require authentication
//   needWorkspaceAuth?: boolean;
// };

export type IResourceError<T = any, D = any> = Omit<
  AxiosResponse<T, D>,
  "data"
> & {
  payload: T | undefined;
};

export type ResourceError<T = unknown, D = any> = IResourceError<T, D>;

export type ResourceErrorAccount<T> = ResourceError<{ errors: T }>;

export type IResponse<R extends IResourceName> = IResourceItem<R>["_response"];

export type IPageableResourceName = {
  [K in IResourceName]: IResource[K] extends { _pageable: true } ? K : never;
}[IResourceName];
