import { chakra, Flex, Grid, Stack } from "@chakra-ui/react";
import { useWatchState } from "lib/hooks/useWatchState";
import { memo } from "react";
import LinkInternal from "ui/shared/LinkInternal";
import TabFloat from "ui/shared/Tabs/TabFloat";
import TabOnlyPanels from "ui/shared/Tabs/TabOnlyPanels";
import LatestTxsContent from "./LatestTxsContent";

const LatestTxs = () => {
  const [tab, setTab] = useWatchState<"evm" | "native">("evm", [], {
    throttle: 200,
  });

  return (
    <Stack
      overflow="hidden"
      borderRadius={2}
      borderWidth="1px"
      borderColor="neutral.light.3"
      backgroundColor="neutral.light.1"
      flexShrink={0}
      flex={1}
      spacing={0}
    >
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        paddingX={{ base: 4, xl: 6 }}
        paddingY={{ base: 3, xl: 3 }}
        paddingBottom="0px !important"
        height={{ lg: "5.425rem" }}
        borderStyle="solid"
        borderBottomWidth="1px"
        gap={1}
      >
        <Flex width="full" justifyContent="space-between" alignItems="center">
          <chakra.span
            as="h2"
            whiteSpace="nowrap"
            textStyle="1125700"
            color="neutral.light.7"
          >
            Latest transactions
          </chakra.span>
          <LinkInternal
            href={tab === "evm" ? "/txs" : "/txs?tab=native"}
            color="accent.blue"
            position="relative"
          >
            View all
          </LinkInternal>
        </Flex>
        <TabFloat
          id="latest_transactions"
          value={tab}
          isSetOnRouter={false}
          onChange={(newId) => {
            setTab(newId);
          }}
          tabs={[
            { id: "evm", title: "EVM", component: undefined },
            { id: "native", title: "Native SEI", component: undefined },
          ]}
        ></TabFloat>
      </Flex>
      <Grid
        templateRows={{
          base: "repeat(6, 6.75rem)",
          lg: "repeat(6, 4.25rem)",
        }}
        height={{ base: "40.5rem", lg: "25.5rem" }}
        overflow="hidden"
      >
        <TabOnlyPanels
          activeId={tab as string}
          tabs={[
            {
              id: "evm",
              component: LatestTxsContent,
              props: {
                type: "evm" as "evm" | "native",
              },
            },
            {
              id: "native",
              component: LatestTxsContent,
              props: {
                type: "native" as "evm" | "native",
              },
            },
          ]}
        />
      </Grid>
    </Stack>
  );
};

export default memo(LatestTxs, () => true);
