import type { ChakraProps, TextProps } from "@chakra-ui/react";
import { Flex, Skeleton, VStack } from "@chakra-ui/react";
import { getIsContract } from "lib/getOSType";
import _ from "lodash";
import { route } from "nextjs-routes";
import { memo, useCallback, useMemo, type ReactNode } from "react";
import type { AddressParam } from "types/api/addressParams";
import CopyToClipboardAsync, {
  type CopyToClipboardAsyncProps,
} from "ui/shared/copyToClipboard/CopyToClipboardAsync";
import IconSvg, { type IconSvgProps } from "ui/shared/IconSvg";
import LinkInternal from "ui/shared/LinkInternal";
import TooltipV2 from "ui/shared/tootltip/TooltipV2";
import {
  TruncatedTextConstant,
  TruncatedTextDynamic,
  TruncatedTextTail,
  type TruncatedTextTooltipProps,
} from "ui/shared/truncate";
import { useSnapshot } from "valtio";
import AddressWarning from "../base/AddressWarning";
import { convertBoxSize, TEXT_PROPS } from "../base/utils";
import { createAvatarUri } from "./AddressIdenticon";

type IAddress =
  | ({
      hash: AddressParam["hash"] | undefined | null;
    } & Partial<
      Pick<
        AddressParam,
        "name" | "is_contract" | "is_verified" | "implementations"
      >
    > & {
        image_url?: string | null;
      })
  | undefined;

type LinkProps = {
  href: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  children: ReactNode;
} & ChakraProps;

const Link = ({
  isDisabled,
  isLoading,
  children,
  href,
  ...props
}: LinkProps) => {
  if (isDisabled || isLoading) return children;

  return (
    <LinkInternal href={href} color="accent.blue" {...props}>
      {children}
    </LinkInternal>
  );
};

type IconProps = {
  address: IAddress;
  isLoading?: boolean;
  noIcon?: boolean;
  noTooltip?: boolean;
  /**
   * Only support rem and level.
   * @default 1rem
   * eg. 1rem, 2rem, 1, 2, 3, ...
   */
  boxSize?: `${number}rem` | number;
} & Partial<IconSvgProps>;

const Icon = ({
  noIcon,
  address,
  isLoading,
  noTooltip,
  boxSize = "1rem",
  optimizationImageProps,
  ...props
}: IconProps) => {
  const _boxSize = useMemo(() => convertBoxSize(boxSize), [boxSize]);

  const store = useStore({
    src: "",
  });

  const onError = useCallback(() => {
    if (!address?.hash) {
      store.src = "";
      return;
    }
    store.src = createAvatarUri(address?.hash, _boxSize);
  }, [address?.hash, _boxSize]);

  const { name, color, label, isDisabled } = useMemo(
    () =>
      _.chain(null)
        .thru(() => {
          const isProxy = Boolean(address?.implementations?.length);
          const isVerified = address?.is_verified;
          if (isProxy) {
            if (isVerified) {
              return {
                name: "contracts/proxy",
                color: "secondary.02",
                label: "Verified proxy contract",
                isDisabled: Boolean(noTooltip),
              };
            }
            return {
              name: "contracts/proxy",
              color: "neutral.light.6",
              label: "Proxy contract",
              isDisabled: Boolean(noTooltip),
            };
          }

          const isContract = getIsContract(address as any);

          if (isContract) {
            if (isVerified) {
              return {
                name: "contract_verified_v2",
                color: "secondary.02",
                label: "Verified contract",
                isDisabled: Boolean(noTooltip),
              };
            }
            return {
              name: "contract",
              color: "neutral.light.6",
              label: "Contract",
              isDisabled: Boolean(noTooltip),
            };
          }

          const hasImage = Boolean(
            address?.image_url && address.image_url !== "validator-default.svg",
          );
          if (hasImage) {
            return {
              src: address?.image_url,
              label: address?.name,
              isDisabled: Boolean(noTooltip || !address?.name),
            };
          }
          return {
            src: createAvatarUri(address?.hash, _boxSize),
            label: address?.name,
            isDisabled: Boolean(noTooltip || !address?.name),
          };
        })
        .thru(({ name, src, color, label, isDisabled }) => {
          store.src = src || "";
          return {
            name,
            color,
            label,
            isDisabled,
          };
        })
        .value(),
    [
      address?.hash,
      address?.is_contract,
      address?.is_verified,
      address?.implementations,
      noTooltip,
      _boxSize,
    ],
  );

  const snap = useSnapshot(store);

  if (noIcon || !address?.hash || address?.hash === "N/A") {
    return <></>;
  }

  if (isLoading)
    return <Skeleton boxSize={4} borderRadius="full" flexShrink={0}></Skeleton>;

  return (
    <TooltipV2 label={label} isDisabled={isDisabled}>
      <IconSvg
        flexShrink={0}
        name={name as never}
        src={snap.src as never}
        color={color}
        boxSize={boxSize}
        borderRadius="full"
        overflow="hidden"
        alt={address.name || "address"}
        {...props}
        optimizationImageProps={{
          ...optimizationImageProps,
          onError: onError,
        }}
      />
    </TooltipV2>
  );
};

type NameProps = {
  address: IAddress;
  isLoading?: boolean;
  noTooltip?: boolean;
  tooltipProps?: Partial<TruncatedTextTooltipProps>;
  linkProps?: Partial<LinkProps>;
  noLink?: boolean;
  showAddress?: boolean;
  isValidator?: boolean;
} & TextProps;

const Name = ({
  address,
  isLoading,
  noTooltip,
  tooltipProps,
  linkProps,
  noLink,
  color,
  showAddress,
  isValidator,
  ...props
}: NameProps) => {
  const defaultHref = useMemo(
    () =>
      route({
        pathname: (isValidator && "/validator/[hash]") || "/address/[hash]",
        query: {
          hash: address?.hash?.replace?.("factory/", "") || "",
        },
      }),
    [address?.hash],
  );
  const isLinkDisabled = useMemo(
    () =>
      noLink ||
      !address?.hash ||
      address.hash === "N/A" ||
      address.hash === "usei" ||
      address.hash.startsWith("factory/") ||
      address.hash.startsWith("ibc/"),
    [address?.hash, noLink],
  );

  return (
    <Link
      href={defaultHref}
      isDisabled={isLinkDisabled}
      color={color}
      {...linkProps}
    >
      <TruncatedTextTail
        isDisabled={noTooltip}
        isLoading={isLoading}
        textStyle="875"
        label={
          <VStack spacing={0}>
            <strong>{address?.name}</strong>
            {!showAddress && address?.hash && <span>{address?.hash}</span>}
          </VStack>
        }
        tooltipProps={{
          highPriorityIsTruncated: true,
          defaultIsTruncated: true,
          ...tooltipProps,
        }}
        {...props}
      >
        {address?.name}
      </TruncatedTextTail>
    </Link>
  );
};

type HashProps = ({
  address: IAddress;
  isLoading?: boolean;
  fallback?: ReactNode;
  noLink?: boolean;
  linkProps?: Partial<LinkProps>;
  tooltipProps?: Partial<TruncatedTextTooltipProps>;
  isValidator?: boolean;
  noTooltip?: boolean;
} & TextProps) &
  (
    | {
        truncation: "tail";
        headLength?: undefined | never;
        tailLength?: undefined | never;
      }
    | {
        truncation: "constant";
        headLength?: number;
        tailLength?: number;
      }
    | {
        truncation: "dynamic";
        headLength?: undefined;
        tailLength?: undefined;
      }
  );

const Hash = ({
  address,
  truncation = "constant",
  headLength = 10,
  tailLength = 9,
  isLoading,
  fallback,
  noLink,
  linkProps,
  tooltipProps,
  color,
  isValidator,
  noTooltip,
  ...props
}: HashProps) => {
  const defaultHref = useMemo(
    () =>
      route({
        pathname: (isValidator && "/validator/[hash]") || "/address/[hash]",
        query: {
          hash: address?.hash?.replace?.("factory/", "") || "",
        },
      }),
    [address?.hash],
  );
  const isLinkDisabled = useMemo(
    () =>
      noLink ||
      !address?.hash ||
      address.hash === "N/A" ||
      address.hash === "usei" ||
      address.hash.startsWith("factory/") ||
      address.hash.startsWith("ibc/"),
    [address?.hash, noLink],
  );

  if (truncation === "tail") {
    return (
      <Link
        href={defaultHref}
        isDisabled={isLinkDisabled}
        color={color}
        isLoading={isLoading}
        {...linkProps}
      >
        <TruncatedTextTail
          textStyle="875"
          isLoading={isLoading}
          tooltipProps={tooltipProps}
          fallback={fallback}
          isDisabled={noTooltip}
          {...props}
        >
          {address?.hash?.replace?.("N/A", "")?.replace?.("usei", "")}
        </TruncatedTextTail>
      </Link>
    );
  }

  if (truncation === "dynamic") {
    return (
      <Link
        href={defaultHref}
        isDisabled={isLinkDisabled}
        isLoading={isLoading}
        {...linkProps}
      >
        <TruncatedTextDynamic
          textStyle="875"
          tooltipProps={tooltipProps}
          isLoading={isLoading}
          fallback={typeof fallback === "string" ? fallback : "N/A"}
          color={color}
          headLength={headLength}
          tailLength={tailLength}
          isDisabled={noTooltip}
          {...props}
        >
          {address?.hash?.replace?.("N/A", "")?.replace?.("usei", "") || ""}
        </TruncatedTextDynamic>
      </Link>
    );
  }

  return (
    <Link
      href={defaultHref}
      isDisabled={isLinkDisabled}
      isLoading={isLoading}
      {...linkProps}
    >
      <TruncatedTextConstant
        headLength={headLength}
        tailLength={tailLength}
        textStyle="875"
        tooltipProps={tooltipProps}
        isLoading={isLoading}
        fallback={fallback}
        color={color}
        isDisabled={noTooltip}
        {...props}
      >
        {address?.hash?.replace?.("N/A", "")?.replace?.("usei", "") || ""}
      </TruncatedTextConstant>
    </Link>
  );
};

type CopyProps = {
  isNa?: boolean;
  noCopy?: boolean;
  address: IAddress;
  isLoading?: boolean;
} & Partial<CopyToClipboardAsyncProps>;

const Copy = memo(
  ({ isNa, noCopy, address, isLoading, ...props }: CopyProps) => {
    const setValue = useCallback(() => address?.hash, [address?.hash]);

    if (isNa || noCopy || !address?.hash || address?.hash === "N/A") {
      return <></>;
    }

    return (
      <CopyToClipboardAsync
        isLoading={isLoading}
        setValue={setValue}
        {...props}
      ></CopyToClipboardAsync>
    );
  },
  (prev, next) =>
    prev.isLoading === next.isLoading &&
    prev.address === next.address &&
    prev.noCopy === next.noCopy,
);

export type AddressV2Props = {
  address: IAddress;
  nameProps?: Partial<NameProps>;
  iconProps?: Omit<IconProps, "address">;
  linkProps?: Partial<LinkProps>;
  fallback?: ReactNode;
  isLoading?: boolean;
  noTooltip?: boolean;
  tooltipProps?: Partial<TruncatedTextTooltipProps>;
  noIcon?: boolean;
  noCopy?: boolean;
  noLink?: boolean;
  hashProps?: Omit<
    HashProps,
    "address" | "tooltipProps" | "truncation" | "headLength" | "tailLength"
  >;
  showAddress?: boolean;
  noName?: boolean;
  showWarning?: "burn";
  isValidator?: boolean;
  copyProps?: Partial<CopyProps>;
} & TextProps &
  (
    | {
        truncation: "tail";
        headLength?: never;
        tailLength?: never;
      }
    | {
        truncation: "constant";
        headLength: number;
        tailLength: number;
      }
    | {
        truncation: "dynamic";
        headLength?: number;
        tailLength?: number;
      }
    | {
        truncation?: undefined;
        headLength?: undefined;
        tailLength?: undefined;
      }
  );

const AddressV2 = ({
  iconProps,
  linkProps,
  noIcon,
  address,
  isLoading,
  noTooltip,
  tooltipProps,
  fallback = "N/A",
  nameProps,
  noCopy,
  noLink,
  hashProps,
  truncation = "constant",
  headLength,
  tailLength,
  showAddress,
  noName,
  showWarning,
  isValidator,
  copyProps,
  ...props
}: AddressV2Props) => {
  const [textProps, otherProps] = useSplitProps(props, TEXT_PROPS);

  return (
    <Flex gap={1} alignItems="center" overflow="hidden" {...otherProps}>
      <Icon
        address={address}
        isLoading={isLoading}
        noIcon={noIcon}
        {...iconProps}
      />

      <Flex
        flexDirection="column"
        gap={1}
        alignItems="stretch"
        overflow="hidden"
      >
        {!noName && address?.name && (
          <Name
            address={address}
            isLoading={isLoading}
            noTooltip={noTooltip}
            tooltipProps={tooltipProps}
            linkProps={linkProps}
            noLink={noLink}
            showAddress={showAddress}
            isValidator={isValidator}
            {...textProps}
            {...nameProps}
          />
        )}
        {(showAddress || noName || !address?.name) && (
          <Hash
            address={address}
            isLoading={isLoading}
            fallback={fallback}
            noLink={noLink || Boolean(showAddress && !noName && address?.name)}
            tooltipProps={tooltipProps}
            truncation={truncation as any}
            headLength={headLength}
            tailLength={tailLength}
            isValidator={isValidator}
            noTooltip={noTooltip}
            {...textProps}
            {...hashProps}
          />
        )}
      </Flex>

      <Copy
        isLoading={isLoading}
        address={address}
        noCopy={noCopy}
        {...copyProps}
      />

      {showWarning && (
        <AddressWarning
          hash={address?.hash}
          isLoading={isLoading}
          showWarning={showWarning}
        />
      )}
    </Flex>
  );
};

export { Icon };

export default memo(AddressV2, (prev, next) => {
  return (
    prev.address?.hash === next.address?.hash &&
    prev.address?.name === next.address?.name &&
    prev.isLoading === next.isLoading &&
    prev.noIcon === next.noIcon
  );
});
