import { memo } from "react";

import type { WorkspaceUserInfo } from "types/api/account";
import IconSvg from "ui/shared/IconSvg";
import type { OptimizationImageProps } from "./OptimizationImage";
import OptimizationImage from "./OptimizationImage";

type Props = {
  user?: WorkspaceUserInfo;
} & Partial<OptimizationImageProps>;

const UserAvatar = ({ user, ...props }: Props) => {
  return (
    <OptimizationImage
      flexShrink={0}
      src={user?.picture || ""}
      alt="profile_avatar"
      hasWrapper
      objectFit="cover"
      transition="transform 0.2s ease-in-out"
      fallback={
        <IconSvg
          src="/icons/avatar.svg"
          boxSize="full"
          color="neutral.light.6"
        ></IconSvg>
      }
      {...props}
      wrapperProps={{
        boxSize: "2.5rem",
        borderWidth: "0.5px",
        borderRadius: "full",
        borderColor: "accent.orange",
        overflow: "hidden",
        transition: "transform 0.2s ease-in-out",
        ...props.wrapperProps,
      }}
    />
  );
};

export default memo(UserAvatar);
