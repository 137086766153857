import { useToast } from "@chakra-ui/react";
import { useSDK } from "@metamask/sdk-react";
import { useCallback } from "react";
import { CosmosMainNet } from "../../../configs/frontend/chain/chainConfigs";
import { useCurrentChain } from "../../../lib/hooks/useCurrentChain";

export const useAddEvmChainToWallet = () => {
  const currentChain = useCurrentChain();
  const { provider } = useSDK();

  const toast = useToast();

  const addOrSwitchChain = useCallback(async () => {
    const config = currentChain!.config;
    const chainIdHex = `0x${config.id.toString(16)}`;
    const chainParams = {
      chainId: chainIdHex,
      chainName: currentChain?.chainPrettyName,
      iconUrls: [CosmosMainNet.logo_URIs!.svg, CosmosMainNet.logo_URIs!.png],
      nativeCurrency: {
        name: config.nativeCurrency.name,
        symbol: config.nativeCurrency.symbol,
        decimals: config.nativeCurrency.decimals,
      },
      rpcUrls: config.rpcUrls.default.http,
      blockExplorerUrls: [config.blockExplorers?.default.url],
    };

    if (!provider) {
      console.warn("Ethereum object not found");
      window.open("https://metamask.io/download.html", "_blank");
      return;
    }

    return await provider
      .request({
        method: "wallet_addEthereumChain",
        params: [chainParams],
      })
      .then(() => {
        toast({
          position: "top-right",
          title: "Success",
          description: "Successfully added network to your wallet",
          status: "success",
          variant: "subtle",
          isClosable: true,
        });
      })
      .catch((error: Error) => {
        console.log(error);
        toast({
          position: "top-right",
          title: "Error",
          description: (error as Error)?.message || "Something went wrong",
          status: "error",
          variant: "subtle",
          isClosable: true,
        });
      });
  }, [currentChain?.chainId, provider]);
  return {
    addOrSwitchChain: addOrSwitchChain,
  };
};
