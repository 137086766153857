import type { CenterProps, LayoutProps } from "@chakra-ui/react";
import { Center, Text, VStack } from "@chakra-ui/react";
import { memo } from "react";
import IconSvg from "./IconSvg";

type Props = {
  text?: React.ReactNode;
  boxSize?: LayoutProps["boxSize"];
} & Partial<CenterProps>;

const Empty = ({ text, boxSize, ...props }: Props) => {
  return (
    <Center textStyle="1125" {...props}>
      <VStack spacing={3}>
        <IconSvg src="/icons/empty.svg" boxSize={boxSize ?? "10rem"}></IconSvg>
        <Text color="neutral.light.8" textAlign="center">
          {text ?? "No data found"}
        </Text>
      </VStack>
    </Center>
  );
};

export type EmptyProps = Props;

export default memo(Empty);
