"use strict";

import { Skeleton, chakra } from "@chakra-ui/react";
import React from "react";

import useTimeAgoIncrement from "lib/hooks/useTimeAgoIncrement";

interface Props {
  ts: string;
  isEnabled?: boolean;
  isLoading?: boolean;
  className?: string;
}

const BlockTimestamp = ({
  ts,
  isEnabled = true,
  isLoading = false,
  className,
}: Props) => {
  const timeAgo = useTimeAgoIncrement(ts, isEnabled);

  return (
    <Skeleton
      isLoaded={!isLoading}
      color="neutral.light.6"
      fontWeight={400}
      className={className}
      display="inline-block"
    >
      <chakra.span>{timeAgo}</chakra.span>
    </Skeleton>
  );
};

export default React.memo(BlockTimestamp);
