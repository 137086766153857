import { Link } from "@chakra-ui/next-js";
import { Box, Button, Center, chakra, Code, Stack } from "@chakra-ui/react";
import { chainKey } from "configs/frontend/chain/utils";
import { route } from "nextjs-routes";
import { memo } from "react";
import AppErrorIcon from "./AppErrorIcon";
import AppErrorTitle from "./AppErrorTitle";
import AppErrorUnauthorization from "./custom/AppErrorUnauthorization";

interface Props {
  error: any;
}

const ERROR_TEXTS: Record<string, { title: string; text: string }> = {
  400: {
    title: "Bad Request",
    text: "The server could not understand your request. Please check your input and try again.",
  },
  401: {
    title: "Unauthorized",
    text: "You are not authorized to access this resource. Please login or provide valid credentials.",
  },
  403: {
    title: "Forbidden",
    text: "You don't have permission to access this resource. Contact the administrator if you believe this is a mistake.",
  },
  404: {
    title: "Page not found",
    text: "Sorry! We encountered an unexpected error. Please try back again shortly.",
  },
  405: {
    title: "Address not found",
    text: "Our explore cannot index this address!",
  },
  408: {
    title: "Request Timeout",
    text: "Your request took too long to process. Please try again later.",
  },
  422: {
    title: "Request cannot be processed",
    text: "Your request contained an error, perhaps a mistyped tx/block/address hash. Try again, and check the developer tools console for more info.",
  },
  429: {
    title: "Too many requests",
    text: "You have exceeded the request rate for a given time period. Please reduce the number of requests and try again soon.",
  },
  500: {
    title: "Oops! Something went wrong",
    text: "An unexpected error has occurred. Try reloading the page, or come back soon and try again.",
  },
  502: {
    title: "Bad Gateway",
    text: "The server received an invalid response from the upstream server. Please try again later.",
  },
  503: {
    title: "Service Unavailable",
    text: "The server is currently unavailable due to maintenance or high load. Please try again later.",
  },
  504: {
    title: "Gateway Timeout",
    text: "The server took too long to respond. Please try again later.",
  },
};

const AppError = ({ error }: Props) => {
  const message =
    (typeof error?.payload === "string" && error?.payload) || error?.message;
  const status = error?.status || (error?.cause as any)?.status;

  if (message === "JWT:USER::SUSPENDED") {
    return <AppErrorUnauthorization></AppErrorUnauthorization>;
  }

  const { title, text } = ERROR_TEXTS[String(status)] || ERROR_TEXTS[500];

  return (
    <Box position="static" overflow="hidden">
      <Stack
        top="calc(7rem + 1px)"
        left={0}
        right={0}
        bottom={{ base: "31.3125rem", lg: "16.8125rem" }}
        zIndex={2}
        overflow="hidden"
        backgroundImage="/icons/error-background.svg"
        backgroundRepeat="no-repeat"
        backgroundSize={{
          base: "200%",
          lg: "150%",
          xl: "100%",
        }}
        minHeight="100%"
        width="100%"
        height="max-content"
        position="relative"
        paddingX={4}
        backgroundPosition="center"
        flexDirection="column"
        alignItems="center"
      >
        <AppErrorIcon status={status}>
          <Center
            inset={0}
            position="absolute"
            flexDirection="column"
            overflow="hidden"
            gap="1rem"
            alignItems="stretch"
            fontSize="1rem"
            fontWeight={400}
            lineHeight="1.5rem"
            sx={{
              WebkitTextStroke: 0,
            }}
            color="neutral.light.8"
          >
            <AppErrorTitle title={title} />

            <chakra.span
              wordBreak="break-word"
              textAlign="center"
              marginX="auto"
              noOfLines={3}
            >
              {text}
            </chakra.span>
            {message && (
              <Code
                variant="error"
                overflowY="auto"
                // maxHeight={200}
                isTruncated
                width="20rem"
                maxWidth="full"
                marginX="auto"
                noOfLines={3}
              >
                {message}
              </Code>
            )}
            <Button
              as={Link}
              whiteSpace="nowrap"
              minWidth="9.1875rem"
              textAlign="center"
              variant="solid"
              marginX="auto"
              href={
                route({
                  pathname: "/",
                  query: {
                    chain: chainKey,
                  },
                }) as any
              }
            >
              Back home
            </Button>
          </Center>
        </AppErrorIcon>
      </Stack>
    </Box>
  );
};

export default memo(AppError, (prev, next) => prev.error === next.error);
