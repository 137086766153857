// import { DEFAULT_TEMPLATE as DEFAULT_DESCRIPTION } from "lib/metadata/templates/description";
// import { DEFAULT_TEMPLATE as DEFAULT_TITLE } from "lib/metadata/templates/title";
import Head from "next/head";
import { memo } from "react";

type Props = {
  description: string;
  // opengraph: { title: string; description?: string };
  // children?: ReactNode;
  origin: string;
  keywords: string;
  title: string;
};

const BaseHead = ({ title, description, origin, keywords }: Props) => {
  return (
    <Head>
      {/* {children} */}
      <title key="title">{title}</title>
      <meta key="charset" charSet="utf-8" />
      <meta
        key="viewport"
        name="viewport"
        content="width=device-width, initial-scale=1.0"
      />

      <meta key="description" name="description" content={description} />
      <meta key="keywords" name="keywords" content={keywords || ""} />

      <meta key="og_type" property="og:type" content="website" />
      <meta key="og_site_name" property="og:site_name" content="Seitrace" />
      {/* <meta key="og_url" property="og:url" content={origin} /> */}
      <meta key="og_title" property="og:title" content={title} />
      <meta
        key="og_description"
        property="og:description"
        content={description}
      />
      <meta
        key="og_image"
        property="og:image"
        content={`${origin}/static/og_placeholder.png`}
      />
      <meta key="og_image_width" property="og:image:width" content="250" />
      <meta key="og_image_height" property="og:image:height" content="250" />
      <meta key="og_image_type" property="og:image:type" content="image/png" />

      <meta
        key="twitter_card"
        name="twitter:card"
        content="summary_large_image"
      />
      <meta key="twitter_title" name="twitter:title" content={title} />
      <meta
        key="twitter_description"
        name="twitter:description"
        content={description}
      />
      <meta
        key="twitter_image"
        name="twitter:image"
        content={`${origin}/static/og_placeholder.png`}
      />
      <meta key="twitter_site" name="twitter:site" content="@seitrace_" />
      <meta key="twitter_creator" name="twitter:creator" content="@seitrace_" />

      {/* FAVICON */}
      <link
        key="icon_48"
        rel="icon"
        href="/favicon/favicon.ico"
        sizes="48x48"
      />
      <link
        key="icon_32"
        rel="icon"
        sizes="32x32"
        type="image/png"
        href="/favicon/favicon-32x32.png"
      />
      <link
        key="icon_16"
        rel="icon"
        sizes="16x16"
        type="image/png"
        href="/favicon/favicon-16x16.png"
      />
      <link
        key="icon_apple"
        rel="apple-touch-icon"
        href="/favicon/apple-touch-icon-180x180.png"
      />
      <link key="icon_mask" rel="mask-icon" href="/icons/logo/logo.svg" />
      <link key="sprite" rel="preload" as="image" href="/icons/sprite.svg" />
    </Head>
  );
};

export default memo(
  BaseHead,
  (prev, next) =>
    // prev.children === next.children &&
    prev.description === next.description &&
    prev.title === next.title &&
    prev.origin === next.origin &&
    prev.keywords === next.keywords,
);

// export default BaseHead;
