import _ from "lodash";
import type { INavActivePath, INavGroup, INavGroupExpandable } from "./types";

export const items = [
  {
    id: "home",
    title: "Home",
    route: {
      pathname: "/",
    },
  },
  {
    id: "governance",
    title: "Governance",
    items: [
      {
        id: "validators",
        title: "Validators",
        route: {
          pathname: "/validators",
          alloweds: ["/validator/[hash]"],
        },
      },
      {
        id: "proposals",
        title: "Proposals",
        route: {
          pathname: "/proposals",
          alloweds: ["/proposal/[id]"],
        },
      },
    ],
  },
  {
    id: "blockchain",
    title: "Blockchain",
    route: {
      alloweds: ["/address/[hash]", "/address/[hash]/contract-verification"],
    },
    items: [
      {
        id: "transactions",
        title: "Transactions",
        route: {
          pathname: "/txs",
          alloweds: ["/tx/[hash]"],
        },
      },
      {
        id: "blocks",
        title: "Blocks",
        route: {
          pathname: "/blocks",
          alloweds: ["/block/[height_or_hash]"],
        },
      },
      {
        id: "ibc-relayers",
        title: "IBC Relayers",
        route: {
          pathname: "/ibc-relayers",
          alloweds: [
            "/ibc-relayer/[channel_id]/counterparty/[counterparty_channel_id]",
          ],
        },
      },
      {
        id: "top-accounts",
        title: "Top Accounts",
        route: {
          pathname: "/accounts",
        },
      },
      "divider",
      {
        id: "evm-verifed-contracts",
        title: "EVM Verified Contracts",
        route: {
          pathname: "/verified-contracts",
        },
      },
      {
        id: "native-sei-code-ids",
        title: "Native SEI Code IDs",
        route: {
          pathname: "/code-ids",
          alloweds: ["/code-id/[id]"],
        },
      },
    ],
  },
  {
    id: "assets",
    title: "Assets",
    route: {
      alloweds: ["/token/[...slug]"],
    },
    items: [
      {
        id: "tokens",
        title: "Tokens",
        route: {
          pathname: "/tokens",
          // fallback: "/token/[...slug]",
        },
      },
      {
        id: "nfts",
        title: "NFTs",
        route: {
          pathname: "/nfts",
        },
      },
      {
        id: "hybrids",
        title: "Hybrid Tokens",
        route: {
          pathname: "/hybrids",
        },
        postProps: {
          colorScheme: "orange",
          children: "ERC-404",
        },
      },
    ],
  },
  {
    id: "resources",
    title: "Resources",
    route: {
      alloweds: ["/subscriptions/[id]", "/subscriptions/payment"],
    },
    items: [
      {
        id: "seitrace-insights",
        title: "Seitrace Insights",
        route: {
          pathname: "/insights",
        },
        postProps: {
          colorScheme: "orange",
          children: "New (API)",
        },
      },
      {
        id: "insight-docs",
        title: "Insight Docs",
        route: {
          pathname: "/insights-docs",
        },
      },
      "divider",
      {
        id: "verified-assets",
        title: "Verified Assets",
        route: {
          pathname: "/verified-assets",
        },
        postProps: {
          colorScheme: "orange",
          children: "New",
        },
      },
      "divider",
      {
        id: "charts-stats",
        title: "Charts & Stats",
        route: {
          pathname: "/stats",
        },
      },
      {
        id: "verify-contract",
        title: "Verify Contract",
        route: {
          pathname: "/contract-verification",
        },
        postProps: {
          colorScheme: "red",
          children: "EVM only",
        },
      },
      "divider",
      {
        id: "parameters",
        title: "Parameters",
        route: {
          pathname: "/parameters",
        },
      },
      {
        id: "docs",
        title: "Docs",
        href: "https://docs.seitrace.com",
      },
    ],
  },
  {
    id: "sei-tools",
    title: "",
    route: {
      pathname: "/tool/wallet-profile",
      alloweds: [
        "/tool",
        "/tool/address-book",
        "/tool/associate",
        "/tool/multisender",
        "/tool/profile-checker",
        "/tool/revoke",
        "/tool/send-token",
        "/tool/wallet-profile",
      ],
    },
    postProps: {
      // colorScheme: "orange",
      color: "secondary.01.text",
      borderColor: "secondary.01",
      backgroundColor: "secondary.01.bg",
      variant: "unstyled",
      children: "Sei Tools",
      fontSize: "1rem",
      lineHeight: "1.5rem",
      paddingX: "0.38rem",
      gap: 1,
      height: "1.5rem",
      borderRadius: "0.25rem",
      _hover: {
        color: "secondary.05.text",
        borderColor: "secondary.05",
        backgroundColor: "secondary.05.bg",
      },
      _selected: {
        color: "secondary.05.text",
        borderColor: "secondary.05",
        backgroundColor: "secondary.05.bg",
      },
      iconProps: {
        name: "tools/default",
        boxSize: "1.25rem",
      },
    },
  },
] satisfies INavGroup[];

export const groups = items.filter(
  (item) => "items" in item,
) satisfies INavGroup[];

export type INavItems = typeof items;

export type IStore = {
  open: boolean;
  active: INavActivePath<INavItems>;
} & Record<INavGroupExpandable<INavItems>, boolean>;

export const formatedPaths = _.chain(new Map<string, string>())
  .tap((activePaths) => {
    for (const group of items as INavGroup[]) {
      if (group.route?.pathname) {
        activePaths.set(group.route.pathname, group.id);
      }
      if (group.route?.alloweds) {
        for (const allowed of group.route.alloweds) {
          activePaths.set(allowed, group.id);
        }
      }
      if (group.items) {
        for (const item of group.items) {
          if (item === "divider") continue;
          if (item.route?.pathname) {
            activePaths.set(item.route.pathname, `${group.id}===${item.id}`);
          }
          if (item.route?.alloweds) {
            for (const allowed of item.route.alloweds) {
              activePaths.set(allowed, `${group.id}===${item.id}`);
            }
          }
        }
      }
    }
  })
  .value();
