import { Center, Flex, Text } from "@chakra-ui/react";
import React from "react";

import type { SearchResultAddressOrContract } from "types/api/search";

import * as AddressEntity from "ui/shared/entities/address/AddressEntity";

interface Props {
  data: SearchResultAddressOrContract;
}

const SearchBarSuggestAddress = ({ data }: Props) => {
  return (
    <Flex alignItems="center" gap={5} overflow="hidden">
      <Center boxSize={8} position="relative">
        <AddressEntity.Icon
          width="2rem"
          height="2rem"
          address={{
            hash: data.address,
            is_contract: false,
            name: "",
            is_verified: false,
            implementations: null,
          }}
        />
        {/* {data.is_smart_contract_verified && (
          <IconSvg
            name="status/success"
            boxSize={6}
            color="secondary.02"
            position="absolute"
            borderRadius="100%"
            bottom={-1}
            right="-50%"
          />
        )} */}
      </Center>

      <Flex flexDirection="column" gap={1} flex={1} overflow="hidden">
        {data.name && (
          <Text
            width="full"
            isTruncated
            fontSize="0.875rem"
            fontWeight={400}
            lineHeight="1.25rem"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {data.name}
          </Text>
        )}
        <Text
          width="full"
          isTruncated
          color="neutral.light.6"
          fontSize="0.8125rem"
          fontWeight={400}
          lineHeight="1rem"
        >
          {data.address}
        </Text>
      </Flex>
    </Flex>
  );
};

export default React.memo(SearchBarSuggestAddress);
