import type { IChainKey } from "configs/server/chain";
import { kodeMono, rubik } from "lib/fonts";
import isBrowser from "lib/isBrowser";
import { serverGenerate } from "lib/metadata/serverGenerate";
import "lib/setLocale";
import type { NextPage } from "next";
import App, { type AppContext, type AppProps } from "next/app";
import Script from "next/script";
import { memo, useMemo, type ReactNode } from "react";
import "styles/index.css";
import "styles/interchain-ui-kit-react.cjs.css";
import type { IJsonChainConfigs } from "types/api";
import InitContexts from "ui/init/InitContexts";
import BaseHead from "ui/meta/BaseHead";
import AppErrorBoundary from "ui/shared/AppError/AppErrorBoundary";
import LayoutDefault from "ui/shared/layout/LayoutDefault";
import "../lib/global";

type Props = AppProps & {
  Component: NextPage;
  title: string;
  description: string;
  origin: string;
  protocol: string;
  chain: string;
  keywords: string;
  configs: IJsonChainConfigs;
};

const PageErrorBoundary = memo(
  ({ children, Component }: { children: ReactNode; Component: NextPage }) => {
    const CatchedPage = useMemo(() => {
      console.log("Rendering page");
      return ({ children }: { children: ReactNode }) => (
        <AppErrorBoundary>{children}</AppErrorBoundary>
      );
    }, [Component]);
    return <CatchedPage>{children}</CatchedPage>;
  },
  (prev, next) => {
    return prev.children === next.children;
  },
);

const ProtectableLayout = memo(
  ({ Component, pageProps }: Pick<Props, "Component" | "pageProps">) => {
    if (Component.getLayout) {
      return Component.getLayout(
        <PageErrorBoundary Component={Component}>
          <Component {...pageProps} />
        </PageErrorBoundary>,
      );
    }

    return (
      <AppErrorBoundary>
        <LayoutDefault
          customContent={Component.getCustomContent?.(pageProps)}
          getSubLayout={Component.getSubLayout}
        >
          <PageErrorBoundary Component={Component}>
            <Component {...pageProps} />
          </PageErrorBoundary>
        </LayoutDefault>
      </AppErrorBoundary>
    );
  },
  (prev, next) => {
    return (
      prev.Component === next.Component && prev.pageProps === next.pageProps
    );
  },
);

function MyApp({
  Component,
  pageProps,
  title = "",
  description = "",
  origin = "seitrace.com",
  protocol = "https",
  chain = "pacific-1",
  keywords = "",
  configs,
}: Props) {
  return (
    <>
      <BaseHead
        title={title}
        description={description}
        keywords={keywords}
        origin={`${protocol}://${origin}`}
      />

      <style jsx global>
        {`
          :root {
            --font-rubik: ${rubik.style.fontFamily};
            --font-kode-mono: ${kodeMono.style.fontFamily};
          }
        `}
      </style>

      <Script
        id="set-globalThis-chain"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            globalThis.chain = "${chain}";
            console.log('globalThis.chain is changed to :', chain);
          `,
        }}
      />

      <Script
        id="set-globalThis-configs"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                  globalThis.configs = ${JSON.stringify(configs)};
                  console.log('globalThis.configs is changed to :', globalThis.configs);
                `,
        }}
      />

      <InitContexts chain={chain}>
        <ProtectableLayout Component={Component} pageProps={pageProps} />
      </InitContexts>
    </>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const ctx = appContext.ctx;

  const chain = (ctx.query.chain || "pacific-1") as IChainKey;

  if (!globalThis.configs && !isBrowser()) {
    await import("tools/scripts/stickConfigs").then(({ stickConfigs }) => {
      stickConfigs(globalThis.configs);
    });
  }

  const origin = ctx.req?.headers.host;

  const protocol = ctx.req?.headers["x-forwarded-proto"];

  const appProps = await App.getInitialProps(appContext);

  const { title, description, keywords } = await serverGenerate(
    appContext,
    chain,
  );

  return {
    ...appProps,
    origin,
    title,
    description,
    keywords,
    protocol,
    chain: chain,
    configs: globalThis.configs,
  };
};

export default MyApp;
