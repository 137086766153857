import type { Association } from "types/api/transaction";

export const ADDRESS_ASSOCIATION: Association = {
  block_height: 0,
  evm_hash: "0x0000000000000000000000000000000000000000",
  id: 0,
  sei_hash: "sei0000000000000000000000000000000000000000000000000000000000000",
  timestamp: "1970-01-01T00:00:00.000",
  tx_hash: "0000000000000000000000000000000000000000000000000000000000000000",
  type: "CREATE_CW20_POINTER",
};
