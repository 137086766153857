import { Grid } from "@chakra-ui/react";
import useApiQuery from "lib/api/useApiQuery";
import { memo } from "react";
import { TX } from "stubs/tx";
import { generateKey, generateListStub } from "stubs/utils";
import type { TransactionsResponseValidated } from "types/api/transaction";
import Empty from "ui/shared/Empty";
import LatestTxsItem from "./LatestTxsItem";

type Props = {
  type: "evm" | "native";
};

const placeholderData = generateListStub<"latest_txs_validated">(TX, 6, {
  next_page_params: null,
}) as TransactionsResponseValidated;

const LatestTxsContent = ({ type }: Props) => {
  const { data, isPlaceholderData, isError } = useApiQuery(
    "latest_txs_validated",
    {
      queryParams: {
        type: type === "native" ? "Cosmos" : "EVM",
        limit: 6,
      },
      queryOptions: {
        placeholderData: placeholderData,
      },
    },
  );

  const isLoading = isPlaceholderData;
  return (
    <Grid
      templateRows={{
        base: "repeat(6, 6.75rem)",
        lg: "repeat(6, 4.25rem)",
      }}
      height={{ base: "40.5rem", lg: "25.5rem" }}
      overflow="hidden"
    >
      {isError && <Empty width="full" gridRow="span 6" height="full"></Empty>}

      {data?.items.map((transaction, index) => (
        <LatestTxsItem
          tx={transaction}
          tab={type}
          isLoading={isLoading}
          key={generateKey(index, isLoading, transaction.hash)}
        />
      ))}
    </Grid>
  );
};

export default memo(LatestTxsContent, () => true);
