import type { Route } from "nextjs-routes";

// equal og:description

export const DEFAULT_TEMPLATE =
  "Leading Sei blockchain explorer for both EVM and Cosmos environments";

// FIXME all page descriptions will be updated later
export const DESCRIPTION_MAP: Partial<Record<Route["pathname"], string>> = {
  "/token/[...slug]": `{{#if is_nft}}
{{#if is_instance}}
Track{{#if token_name}} {{token_name}}{{/if}}{{#if token_symbol}} ({{token_symbol}}){{/if}} #{{token_id}} on Sei Network with Seitrace. View real-time token analytics including token ID information, item activities and metadata
{{else}}
Track{{#if token_name}} {{token_name}}{{/if}}{{#if token_symbol}} ({{token_symbol}}){{/if}} on Sei Network with Seitrace. View real-time token analytics including total supply, inventory, holder count, price history, and market data
{{/if}}
{{else}}
Track{{#if token_name}} {{token_name}}{{/if}}{{#if token_symbol}} ({{token_symbol}}){{/if}} on Sei Network with Seitrace. View real-time token analytics including total supply, holder count, price history, and market data
{{/if}}`,

  "/validators":
    "Explore Sei Network validators with real-time analytics on Seitrace. Monitor validator performance, commission, staking metrics, and network health",
  "/validator/[hash]":
    "Explore Sei Network validator details with real-time analytics on Seitrace. Monitor validator performance, commission, staking metrics, and network health",

  "/proposals":
    "Track Sei Network Governance Proposals with Seitrace. Monitor proposal statuses, voting periods, and governance activities in real-time",
  get "/proposal/[id]"() {
    return this["/proposals"];
  },

  "/txs":
    "Track confirmed transactions on Sei Network across both EVM and Cosmos environments. Monitor token transfers, smart contract interactions in real-time",
  "/tx/[hash]":
    "Sei Network transaction details: status, confirmations, gas fees, token transfers, associated",

  "/blocks":
    "Monitor Sei Network blocks in real-time: block height, validator activity, transaction counts, gas usage, and block rewards",
  "/block/[height_or_hash]":
    "Sei Network Block Height {{height_or_hash}}. The timestamp, size, block reward, difficulty, gas used and the transactions in the block are detailed on Seitrace",

  "/ibc-relayers":
    "Track Sei Network's IBC relayers and cross-chain activities. Monitor connected chains, channel status, and transaction flows on Seitrace",
  "/ibc-relayer/[channel_id]/counterparty/[counterparty_channel_id]":
    "Detailed IBC channel analytics: Sei {{chain}} to {{counterparty_channel_id}} connection status, transfer history, and performance on Seitrace",

  "/accounts":
    "Monitor leading Sei Network wallets with combined native and EVM on the same account. Track top holders and balance distribution on Seitrace",
  "/address/[hash]":
    "View the transactions, token transfers, Token holdings, associated address and other data for address {{hash}} on SeiTrace",

  "/verified-contracts":
    "Explore verified EVM smart contracts on Sei blockchain through Seitrace. Each contract's source code is verified to match deployed bytecode for security",

  "/code-ids":
    "View and verify Code IDs deployed on SEI Network - the parallel blockchain combining Cosmos and EVM capabilities. Browse contract codes with Seitrace",

  "/insights":
    "Seitrace Insights provides robust API endpoints for Sei Network applications. Get real-time blockchain data, analytics, and explorer metrics through our standard and Pro API services",
  get "/insights-docs"() {
    return this["/insights"];
  },
  "/stats":
    "Detailed Sei Network statistics dashboard featuring on-chain data, blockchain metrics, network analytics, and smart contract insights. Visual analytics for Sei ecosystem",

  "/contract-verification":
    "Verify and publish Solidity smart contract source code on Sei Network with Seitrace's contract verification tool. Simple and secure validation for EVM contract deployments",
  get "/address/[hash]/contract-verification"() {
    return this["/contract-verification"];
  },

  "/parameters":
    "Explore current Sei Network protocol parameters and chain configurations. Track governance settings and network parameters for {{chain}} through Seitrace's parameter dashboard",

  "/claim-reward":
    "Easily claim your Sei Network airdrops and rewards through Seitrace. Simple, secure interface to check eligibility and claim tokens directly on Sei blockchain",
  get "/claim-reward/[id]"() {
    return this["/claim-reward"];
  },

  "/tokens":
    "Track all tokens (ERC-20, CW-20, Native, IBC, Factory) on Sei blockchain with real-time prices, market caps, holder counts, and smart contract details",
  "/nfts":
    "Track all NFTs (ERC-721, CW-721, ERC-1155) on Sei blockchain with real-time total items, holder counts",
  "/hybrids":
    "Track tokens ERC-404 on Sei blockchain with real-time prices, market caps, holder counts, and smart contract details",

  "/verified-assets":
    "Track all tokens (ERC-20, CW-20, Native, IBC, Factory) on Sei blockchain with real-time prices, their metadata, including contract-based, add to wallet",

  "/tool/wallet-profile":
    "Track your SEI wallet's assets - from native tokens to NFTs - all in one profile view",
  "/tool/multisender":
    "Distribute tokens to multiple EVM addresses in one transaction - efficient batch sending for airdrops and payments",

  "/about":
    "Built and launched by the Cavies team, Seitrace provides a deep dive into real-time insights as the first comprehensive explorer for the Sei Network",
};
