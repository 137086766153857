import { useQuery } from "@tanstack/react-query";
import buildUrl from "lib/api/buildUrl";
import { getResourceKey } from "lib/api/useApiQuery";
import * as cookies from "lib/cookies";

export default function useGetCsrfToken() {
  useQuery({
    queryKey: getResourceKey("csrf"),
    queryFn: async () => {
      const url = buildUrl("csrf");
      const apiResponse = await fetch(url, { credentials: "include" });
      const csrfFromHeader = apiResponse.headers.get("x-bs-account-csrf");

      if (!csrfFromHeader) {
        return;
      }

      return { token: csrfFromHeader };
    },
    enabled: Boolean(cookies.get(cookies.NAMES.API_TOKEN)),
  });
}
