"use client";

import { type Chain } from "viem";
import { ChainKey, SEI_BASE_COSMOS_KIT_CHAIN } from "./data";
import type { ChainConfig } from "./types";

export const RPC_URLS: Record<ChainKey, string> = {
  [ChainKey.ARCTIC_1]: "https://evm-rpc-arctic-1.sei-apis.com",
  [ChainKey.ATLANTIC_2]: "https://evm-rpc-testnet.sei-apis.com",
  [ChainKey.PACIFIC_1]: "https://evm-rpc.sei-apis.com",
};

export const DevNet = {
  id: 713715,
  name: "SEI Devnet",
  nativeCurrency: { name: "SEI", symbol: "SEI", decimals: 18 },
  rpcUrls: {
    // default: { http: ["https://evm-rpc-arctic-1.sei-apis.com"] },
    // public: { http: ["https://evm-rpc-arctic-1.sei-apis.com"] },
    default: { http: [RPC_URLS[ChainKey.ARCTIC_1]] },
    public: { http: [RPC_URLS[ChainKey.ARCTIC_1]] },
  },
  blockExplorers: {
    default: { name: "Seitrace", url: "https://seitrace.com" },
  },
} as const satisfies Chain;

export const TestNet = {
  id: 1328,
  name: "SEI Testnet",
  nativeCurrency: { name: "SEI", symbol: "SEI", decimals: 18 },
  rpcUrls: {
    // default: { http: ["https://evm-rpc-testnet.sei-apis.com"] },
    // public: { http: ["https://evm-rpc-testnet.sei-apis.com"] },
    default: { http: [RPC_URLS[ChainKey.ATLANTIC_2]] },
    public: { http: [RPC_URLS[ChainKey.ATLANTIC_2]] },
  },
  blockExplorers: {
    default: { name: "Seitrace", url: "https://seitrace.com" },
  },
} as const satisfies Chain;

export const MainNet = {
  id: 1329,
  name: "SEI",
  nativeCurrency: { name: "SEI", symbol: "SEI", decimals: 18 },
  rpcUrls: {
    // default: { http: ["https://evm-rpc.sei-apis.com"] },
    // public: { http: ["https://evm-rpc.sei-apis.com"] },
    default: { http: [RPC_URLS[ChainKey.PACIFIC_1]] },
    public: { http: [RPC_URLS[ChainKey.PACIFIC_1]] },
  },
  blockExplorers: {
    default: { name: "Seitrace", url: "https://seitrace.com" },
  },
} as const satisfies Chain;

export const CosmosMainNet = {
  ...SEI_BASE_COSMOS_KIT_CHAIN,
  chain_type: "cosmos",
  chain_name: "sei",
  pretty_name: "Sei (mainnet)",
  chain_id: "pacific-1",
  apis: {
    rpc: [
      {
        address: "https://rpc.sei-apis.com",
        provider: "Rhino Stake",
      },
    ],
    rest: [
      {
        address: "https://rest.sei-apis.com",
        provider: "Rhino Stake",
      },
    ],
    grpc: [
      {
        address: "https://grpc.sei-apis.com:443",
        provider: "Rhino Stake",
      },
    ],
    "evm-http-jsonrpc": [
      {
        address: "https://evm-rpc.sei-apis.com",
        provider: "Rhino Stake",
      },
    ],
  },
  explorers: [
    {
      url: "https://seitrace.com/?chain=pacific-1",
      tx_page: "https://seitrace.com/tx/${txHash}?chain=pacific-1",
      account_page:
        "https://seitrace.com/address/${accountAddress}?chain=pacific-1",
      proposal_page:
        "https://seitrace.com/proposal/${proposalId}?chain=pacific-1",
      block_page: "https://seitrace.com/block/${blockHeight}?chain=pacific-1",
    },
  ],
} as const;

export const CosmosTestNet = {
  ...SEI_BASE_COSMOS_KIT_CHAIN,
  chain_type: "cosmos",
  chain_name: "seitestnet2",
  pretty_name: "Sei (testnet)",
  chain_id: "atlantic-2",
  apis: {
    rpc: [
      {
        address: "https://rpc-testnet.sei-apis.com",
        provider: "Rhino Stake",
      },
    ],
    rest: [
      {
        address: "https://rest-testnet.sei-apis.com",
        provider: "Rhino Stake",
      },
    ],
    grpc: [
      {
        address: "https://grpc-testnet.sei-apis.com:443",
        provider: "Rhino Stake",
      },
    ],
    "evm-http-jsonrpc": [
      {
        address: "https://evm-rpc-testnet.sei-apis.com",
        provider: "Rhino Stake",
      },
    ],
  },
  explorers: [
    {
      // name: "Seitrace",
      url: "https://seitrace.com/?chain=atlantic-2",
      tx_page: "https://seitrace.com/tx/${txHash}?chain=atlantic-2",
      account_page:
        "https://seitrace.com/address/${accountAddress}?chain=atlantic-2",
      proposal_page:
        "https://seitrace.com/proposal/${proposalId}?chain=atlantic-2",
      block_page: "https://seitrace.com/block/${blockHeight}?chain=atlantic-2",
    },
  ],
} as const;

export const CosmosDevNet = {
  ...SEI_BASE_COSMOS_KIT_CHAIN,
  chain_type: "cosmos",
  chain_name: "seidevnet",
  chain_id: "arctic-1",
  pretty_name: "Sei (devnet)",
  apis: {
    rpc: [
      {
        address: "https://rpc-arctic-1.sei-apis.com",
        provider: "Rhino Stake",
      },
    ],
    rest: [
      {
        address: "https://rest-arctic-1.sei-apis.com",
        provider: "Rhino Stake",
      },
    ],
    grpc: [
      {
        address: "https://grpc-arctic-1.sei-apis.com:443",
        provider: "Rhino Stake",
      },
    ],
    "evm-http-jsonrpc": [
      {
        address: "https://evm-rpc-arctic-1.sei-apis.com",
        provider: "Rhino Stake",
      },
    ],
  },
  explorers: [
    {
      // name: "Seitrace",
      url: "https://seitrace.com/?chain=arctic-1",
      tx_page: "https://seitrace.com/tx/${txHash}?chain=arctic-1",
      account_page:
        "https://seitrace.com/address/${accountAddress}?chain=arctic-1",
      proposal_page:
        "https://seitrace.com/proposal/${proposalId}?chain=arctic-1",
      block_page: "https://seitrace.com/block/${blockHeight}?chain=arctic-1",
    },
  ],
} as const;

export const ChainKeys = {
  DEVNET: ChainKey.ARCTIC_1,
  PUBLIC_TESTNET: ChainKey.ATLANTIC_2,
  MAINNET: ChainKey.PACIFIC_1,
};

export type NetworkType = "mainnet" | "testnet" | "devnet";

export enum ECosmosChain {
  SEI = "sei",
  SEI_DEVNET = "seidevnet",
  SEI_TESTNET = "seitestnet2",
}

export const chainConfigs = [
  {
    chainName: "pacific-1 (mainnet)",
    chainKey: ChainKeys.MAINNET,
    chainId: 1329,
    disabled: false,
    config: MainNet,
    networkType: "mainnet",
    chainPrettyName: "Sei (mainnet)",
    cosmosChainKey: ECosmosChain.SEI,
    cosmosConfig: CosmosMainNet,
  },
  {
    chainName: "atlantic-2 (testnet)",
    chainKey: ChainKeys.PUBLIC_TESTNET,
    chainId: 1328,
    config: TestNet,
    networkType: "testnet",
    chainPrettyName: "Sei (testnet)",
    cosmosChainKey: ECosmosChain.SEI_TESTNET,
    cosmosConfig: CosmosTestNet,
  },
  {
    chainName: "arctic-1 (devnet)",
    chainKey: ChainKeys.DEVNET,
    chainId: 713715,
    config: DevNet,
    networkType: "devnet",
    chainPrettyName: "Sei (devnet)",
    cosmosChainKey: ECosmosChain.SEI_DEVNET,
    cosmosConfig: CosmosDevNet,
  },
] as ChainConfig[];
