import { Center, Flex } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { memo, useMemo } from "react";
import SearchBarTop from "../searchBar/SearchBarTop";
import ChainSelect from "./ChainSelect";
import TopBarStats from "./TopBarStats";

const TopBar = () => {
  const router = useRouter();
  const homePage = useMemo(() => router.pathname === "/", [router.pathname]);
  // const dispatch = useAppDispatch();

  return (
    <Center backgroundColor="neutral.light.1" zIndex={998}>
      <Flex
        width="full"
        paddingY={{ base: 2, lg: "0.375rem", xl: "0.5rem" }}
        gap={{ base: 3, lg: 4 }}
        flexWrap="wrap"
        alignItems="center"
        maxWidth={{ base: "full", xl: "1440px" }}
        paddingX={{ base: 4, lg: 5, "2lg": 8, xl: 10 }}
        height="3.25rem"
        backgroundColor="neutral.light.1"
        justifyContent="space-between"
      >
        <TopBarStats flex={1} />
        {!homePage && (
          <SearchBarTop
            isFullscreen
            searchInputProps={{
              placeholder:
                "Search by Address / Txn Hash / Block / Token  / NFT collection",
            }}
            contentProps={{
              zIndex: 1000,
              minHeight: {
                base: "unset",
                lg: "20rem",
              },
              maxHeight: {
                base: "100dvh",
                lg: "85dvh",
              },
            }}
          />
        )}
        <ChainSelect
          order={{
            base: 1,
            lg: 3,
          }}
        />
      </Flex>
    </Center>
  );
};

export default memo(TopBar, () => true);
