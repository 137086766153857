import { Flex, Text } from "@chakra-ui/react";
import moment from "lib/date/moment";
import { memo, useMemo } from "react";
import type { SearchResultBlock } from "types/api/search";
import IconSvg from "ui/shared/IconSvg";

interface Props {
  data: SearchResultBlock;
}

const SearchBarSuggestBlock = ({ data }: Props) => {
  const subTitle = useMemo(() => {
    if (data.description) return data.description;
    if (!data.timestamp) return "";
    return moment(data.timestamp).format("llll");
  }, [data.timestamp, data.description]);

  return (
    <Flex alignItems="center" gap={3} overflow="hidden">
      <IconSvg name="block-purple" boxSize={4} />

      <Flex flexDirection="column" gap={1} flex={1} overflow="hidden">
        <Text width="full" isTruncated color="neutral.light.8" size="875">
          {data.block_number.toString()}
        </Text>
        <Text width="full" isTruncated color="neutral.light.6" size="8125">
          {subTitle}
        </Text>
      </Flex>
    </Flex>
  );
};

export default memo(SearchBarSuggestBlock);
