import { chakra, Flex, GridItem, HStack, Skeleton } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { memo, useMemo } from "react";
import type { Block } from "types/api/block";
import BlockTimestamp from "ui/blocks/BlockTimestamp";
import CurrencyValue from "ui/shared/CurrencyValue";
import Divider from "ui/shared/Divider";
import AddressV2 from "ui/shared/entities/address/AddressEntityV2";
import BlockV2, { BlockIconV2 } from "ui/shared/entities/block/BlockEntityV2";

type Props = {
  block: Block;
  isLoading?: boolean;
};

const LatestBlocksItem = ({ block, isLoading }: Props) => {
  const totalReward = useMemo(
    () =>
      BigNumber.sum(...(block.rewards?.map((x) => x.reward) || [])).div(
        10 ** 18,
      ),
    [block],
  );
  return (
    <GridItem
      borderBottom="1px solid"
      _last={{ border: "none" }}
      borderRightWidth={0}
      borderLeftWidth={0}
      borderColor="divider"
      paddingX={{ base: 4, lg: 4 }}
      paddingY={{ base: 4, lg: 3 }}
      display="flex"
      alignItems={{ base: "stretch", lg: "center" }}
      justifyContent="space-between"
      width="100%"
      cursor="pointer"
      _hover={{
        bg: "primary.light.1",
      }}
      flexDirection={{ base: "column", lg: "row" }}
      columnGap={4}
      overflow="hidden"
      whiteSpace="normal"
    >
      <Flex
        display="flex"
        height={{ lg: "full" }}
        columnGap={{ base: 2, lg: 4 }}
        alignItems="center"
        flex={{ lg: 1 }}
        width={{ lg: "8rem" }}
        overflow="hidden"
      >
        <BlockIconV2 boxSize="1.25rem" isLoading={isLoading} flexShrink={0} />
        <Flex
          alignItems={{ base: "center", lg: "flex-start" }}
          flexDirection={{ base: "row", lg: "column" }}
          rowGap={1}
          columnGap={2}
          flex={1}
          textStyle="875"
          overflow="hidden"
        >
          <BlockV2
            noCopy
            noIcon
            isLoading={isLoading}
            number={block.height}
            textStyle="0.875rem"
          />
          <Divider
            orientation="vertical"
            alignSelf="stretch"
            height="unset"
            display={{ base: "inline-block", lg: "none" }}
            flexShrink={0}
          />
          <BlockTimestamp
            ts={block.timestamp}
            isEnabled={!isLoading}
            isLoading={isLoading}
          />
        </Flex>
      </Flex>

      <Flex
        direction={"column"}
        flex={{ lg: 2 }}
        width={{ lg: "12rem" }}
        rowGap="0.375rem"
        textStyle="875"
        alignItems={{
          base: "center",
          lg: "stretch",
        }}
        columnGap={2}
        overflow="hidden"
        flexDirection={{ base: "row", lg: "column" }}
      >
        <HStack spacing={1} overflow="hidden">
          <Skeleton
            isLoaded={!isLoading}
            textTransform="capitalize"
            color="neutral.light.6"
            flexShrink={0}
          >
            <span>Validator</span>
          </Skeleton>

          <AddressV2
            address={{
              ...block.miner,
              name: block?.miner?.validator_data?.description?.moniker || "",
              hash: block?.miner?.validator_data?.operator_address || "",
            }}
            isLoading={isLoading}
            noIcon
            noCopy
            isValidator
            textStyle="875"
            truncation="tail"
          />
        </HStack>

        <Divider
          orientation="vertical"
          alignSelf="stretch"
          height="unset"
          display={{ base: "inline-block", lg: "none" }}
          flexShrink={0}
        />

        <Skeleton
          flexShrink={0}
          isLoaded={!isLoading}
          display="flex"
          alignItems="center"
        >
          <chakra.span
            textStyle="8125"
            color="neutral.light.7"
            display="inline-flex"
            gap={1}
          >
            <span>Power:</span>
            {block?.miner?.validator_data?.percent_voting_power.toFixed(2)}%
          </chakra.span>
        </Skeleton>
      </Flex>

      <Flex
        rowGap={{ lg: 1 }}
        width={{ lg: "6.5rem" }}
        flexShrink={0}
        flexDirection={{ base: "row", lg: "column" }}
        position="relative"
        columnGap="0.125rem"
        flex={{ lg: 1 }}
        textStyle="875"
      >
        <Skeleton isLoaded={!isLoading} color="neutral.light.6">
          <span>Reward:</span>
        </Skeleton>
        <CurrencyValue
          value={totalReward}
          decimals={0}
          isLoading={isLoading}
        ></CurrencyValue>
      </Flex>
    </GridItem>
  );
};

export default memo(LatestBlocksItem, (prev, next) => {
  return (
    prev.block.height === next.block.height && prev.isLoading === next.isLoading
  );
});
