import { useMemo } from "react";
import type { WorkspaceUserInfo } from "types/api/account";
import { navsWithDevFeatures } from "ui/shared/layout/constants/profile";

export const useSidebarNavs = (user: WorkspaceUserInfo | undefined) => {
  return useMemo(() => {
    return navsWithDevFeatures.filter((section) => {
      if (!user?.roles) return true;

      const roles = new Set(user.roles);

      if (!section.userRolesForShow && !section.userRolesForHide) return true;

      const hasShowRole = section.userRolesForShow?.some((role) =>
        roles.has(role),
      );

      const hasHideRole = section.userRolesForHide?.some((role) =>
        roles.has(role),
      );

      return hasShowRole && !hasHideRole;
    });
  }, [user?.roles]);
};
