import type { ReactNode } from "react";
import LinkInternal from "ui/shared/LinkInternal";

export const SUPPORT_EMAIL = "mailto:support@seitrace.com";
export const SALES_EMAIL = "mailto:sales@seitrace.com";

export const openSupportEmail = () => {
  window.open(SALES_EMAIL, "_blank");
};

export type FooterHyperlink = {
  title: string;
  href?: string;
  isExternal?: boolean;
  component?: ReactNode;
};

export const footerHyperlinkGroupList: {
  title: string;
  items: FooterHyperlink[];
}[] = [
  {
    title: "Company",
    items: [
      { title: "About us", href: "/about" },
      { title: "Brand Assets", href: "/brand-assets" },
      {
        title: "Contact us",
        href: SUPPORT_EMAIL,
        isExternal: true,
      },
      {
        title: "Terms & Conditions",
        component: (
          <>
            <LinkInternal
              display="inline"
              color="inherit"
              isScrollTop
              href="/terms"
            >
              Terms
            </LinkInternal>
            {" & "}
            <LinkInternal
              display="inline"
              color="inherit"
              isScrollTop
              href="/privacy"
            >
              Privacy
            </LinkInternal>
          </>
        ),
      },
    ],
  },
  {
    title: "Community",
    items: [
      //   { title: "Update token info", href: "/update_token_info" },
      {
        title: "Documents",
        href: "https://docs.seitrace.com",
        isExternal: true,
      },
      {
        title: "Report Issue",
        href: "mailto:support@seitrace.com?subject=Report Issues",
        isExternal: true,
      },
    ],
  },
];
