import { Flex, Grid, GridItem, Skeleton, Text } from "@chakra-ui/react";
import config from "configs/app";
import useTimeAgoIncrement from "lib/hooks/useTimeAgoIncrement";
import { memo } from "react";
import type { Transaction } from "types/api/transaction";
import Tag from "ui/shared/chakra/Tag";
import CurrencyValue from "ui/shared/CurrencyValue";
import AddressV2 from "ui/shared/entities/address/AddressEntityV2";
import TxEntity from "ui/shared/entities/tx/TxEntity";
import FromTo from "ui/shared/FromTo";
import IconSvg from "ui/shared/IconSvg";
import TruncatedTextTooltip from "ui/shared/truncate/TruncatedTextTooltip";

type Props = {
  tx: Transaction;
  isLoading?: boolean;
  tab: "evm" | "native";
};

const LatestTxsItem = ({ tx, isLoading, tab }: Props) => {
  const dataTo = tx.to ? tx.to : tx.created_contract;
  const timeAgo = useTimeAgoIncrement(tx.timestamp || "0", true);

  return (
    <GridItem
      maxWidth="full"
      borderBottom="1px solid"
      _last={{ border: "none" }}
      borderRightWidth={0}
      borderLeftWidth={0}
      borderColor="divider"
      paddingX={{ base: 4, lg: 4 }}
      paddingY={{ base: 4, lg: 3 }}
      display="flex"
      alignItems={{ base: "flex-start", lg: "center" }}
      justifyContent="space-between"
      width="100%"
      cursor="pointer"
      columnGap={2}
      _hover={{
        bg: "primary.light.1",
      }}
      flexDirection={{ base: "column", lg: "row" }}
      role="group"
    >
      <Flex
        flex={{ lg: 1 }}
        display="flex"
        height={{ lg: "full" }}
        columnGap={{ base: 2, lg: 4 }}
        alignItems="center"
        width={{ base: "full", lg: "unset" }}
      >
        <IconSvg
          name="exchange-rectangle"
          boxSize={5}
          color="secondary.03"
          isLoading={isLoading}
        />
        <Flex
          alignItems={{ base: "center", lg: "flex-start" }}
          flexDirection={{ base: "row", lg: "column" }}
          rowGap={1}
          columnGap={2}
          width={{ base: "full", lg: "6rem" }}
        >
          <TxEntity
            noIcon
            isLoading={isLoading}
            headLength={6}
            tailLength={4}
            hash={tx.hash || "0x000"}
            fontSize="0.875rem"
            lineHeight={5}
            fontWeight={400}
            color="accent.blue"
            truncation="constant"
          />

          <Skeleton
            isLoaded={!isLoading}
            color="neutral.light.6"
            textStyle="8125"
            flexShrink={0}
          >
            <span>{timeAgo}</span>
          </Skeleton>
        </Flex>
      </Flex>
      {tab === "native" && (
        <Flex
          alignItems="center"
          width={{ base: "full", lg: "13rem", "2lg": "16rem", xl: "20rem" }}
        >
          <TruncatedTextTooltip label={tx.method}>
            <Tag
              isTruncated
              isLoading={isLoading}
              maxWidth={{ base: "9.75rem", lg: "10.6875rem", xl: "14.4375rem" }}
            >
              {tx.method}
            </Tag>
          </TruncatedTextTooltip>
        </Flex>
      )}
      {tab === "evm" && (
        <Grid
          columnGap={2}
          alignItems="center"
          templateRows={{ base: "1fr", lg: "1fr 1fr" }}
          templateColumns={{
            base: "1fr max-content 1fr",
            lg: "max-content 1fr",
          }}
          rowGap={1}
          overflow="hidden"
          width={{ base: "full", lg: "13rem" }}
        >
          <GridItem rowSpan={{ base: 1, lg: 2 }} order={{ base: 2, lg: 1 }}>
            <FromTo isLoading={isLoading} />
          </GridItem>
          <GridItem order={{ base: 1, lg: 2 }} overflow="hidden">
            <AddressV2
              isLoading={isLoading}
              address={tx.from}
              width="full"
              truncation="constant"
              headLength={6}
              tailLength={4}
              textStyle="875"
            />
          </GridItem>
          {dataTo && (
            <GridItem order={3} overflow="hidden">
              <AddressV2
                isLoading={isLoading}
                address={dataTo}
                textStyle="875"
                truncation="constant"
                headLength={6}
                tailLength={4}
              />
            </GridItem>
          )}
        </Grid>
      )}
      <Flex
        flex={{ lg: 1 }}
        rowGap={1}
        alignItems={{ base: "center", lg: "flex-start" }}
        flexDirection={{ base: "row", lg: "column" }}
        position="relative"
        columnGap={2}
        width={{ base: "full", lg: "unset" }}
      >
        <Skeleton isLoaded={!isLoading} borderRadius="4px" textStyle="875">
          <Text as="span" whiteSpace="pre" color="neutral.light.6">
            {config.chain.currency.symbol}{" "}
          </Text>
          <CurrencyValue
            value={tx.value}
            color="neutral.light.7"
            osType={tx.__transactionType}
          ></CurrencyValue>
        </Skeleton>

        <Skeleton
          isLoaded={!isLoading}
          display="flex"
          whiteSpace="pre"
          borderRadius="4px"
          alignItems="center"
          textStyle="875"
        >
          <Text as="span" color="neutral.light.6">
            Fee{" "}
          </Text>

          <CurrencyValue
            color="neutral.light.7"
            value={tx.fee?.value}
            osType={tab === "native" ? "Cosmos" : "EVM"}
          ></CurrencyValue>
        </Skeleton>
      </Flex>
    </GridItem>
  );
};

export default memo(LatestTxsItem, (prev, next) => {
  return prev.tx.hash === next.tx.hash && prev.isLoading === next.isLoading;
});
