import { chakra, Grid, HStack } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { chainKey } from "configs/frontend/chain/utils";
import useApiQuery from "lib/api/useApiQuery";
import { formatLargeNumber } from "lib/utils/formatLargeNumber";
import _ from "lodash";
import { route } from "nextjs-routes";
import { memo } from "react";
import { HOMEPAGE_STATS, STATS_COUNTERS } from "stubs/stats";
import type { IStatsCounters } from "types/api/stats";
import GasInfoTooltipContent from "ui/shared/GasInfoTooltipContent/GasInfoTooltipContent";
import StatsItem from "./StatsItem";

const mapCounters = (data: IStatsCounters) => {
  return _.chain({
    averageBlockTime: "",
    totalTxns: "",
  })
    .tap((store) => {
      data.forEach((item) => {
        if (item.id === "averageBlockTime") {
          store.averageBlockTime = item.value;
        }
        if (item.id === "totalTxns") {
          store.totalTxns = item.value;
        }
      });
    })
    .thru((store) => {
      const averageBlockTimeBn = BigNumber(store.averageBlockTime);
      return {
        averageBlockTime: averageBlockTimeBn.gt(0)
          ? `${averageBlockTimeBn.toFixed(1)}s`
          : "",
        totalTxns: formatLargeNumber(store.totalTxns, {
          accuracy: {
            base: 3,
            pivot: 1e7,
            upper: 0,
          },
          sticky: true,
        }),
      };
    })
    .value();
};

const mapStats = (data: any) => {
  const gasPrices = BigNumber(data.gas_prices?.average);
  return {
    totalAddress: formatLargeNumber(data.total_addresses, {
      accuracy: {
        base: 3,
        pivot: 1e7,
        upper: 0,
      },
      sticky: true,
    }),
    gas_prices: {
      average: data.gas_prices?.average,
      fast: data.gas_prices?.fast,
      slow: data.gas_prices?.slow,
    },
    gasPrices: gasPrices.gte(0) ? gasPrices.toFormat() : "N/A",
  };
};

const Stats = () => {
  const {
    data: counters,
    isError: isErrorCounters,
    isPlaceholderData: isPlaceholderDataCounters,
  } = useApiQuery("gateway_stats_counters", {
    queryOptions: {
      placeholderData: STATS_COUNTERS,
      select: mapCounters,
    },
  });

  const {
    data: stats,
    isPlaceholderData: isPlaceholderDataStats,
    isError: isErrorStats,
  } = useApiQuery("homepage_stats", {
    queryOptions: {
      placeholderData: HOMEPAGE_STATS,
      select: mapStats,
    },
  });

  const isError = isErrorCounters || isErrorStats;
  const isLoading = isPlaceholderDataCounters || isPlaceholderDataStats;

  if (isError || !counters || !stats) {
    return null;
  }

  return (
    <Grid
      flex={1}
      height={{ base: "unset", md: "10.75rem" }}
      gridTemplateColumns={{
        base: "1fr 1fr",
        md: "repeat(4, 1fr)",
      }}
      gridTemplateRows={{ base: "50% 50%", lg: "100%" }}
      backgroundColor="white"
      border="1px solid"
      borderColor="neutral.light.3"
      rounded="0.5rem"
      paddingY={{ base: 0, md: 5 }}
    >
      <StatsItem
        icon="average-time"
        title="Average block time"
        value={counters.averageBlockTime}
        isLoading={isLoading}
      />

      <StatsItem
        icon="total-transaction"
        title="Total transactions"
        value={counters.totalTxns}
        url={route({
          pathname: "/txs",
          query: {
            chain: chainKey,
          },
        })}
        isLoading={isLoading}
      />
      <StatsItem
        icon="wallet-green"
        title="Wallet addresses"
        value={stats.totalAddress}
        isLoading={isLoading}
      />

      <StatsItem
        icon="gas-tracker"
        title="Gas tracker"
        value={
          <HStack display="inline-flex" spacing={1}>
            <span>{stats.gasPrices}</span>
            <chakra.span
              textStyle="125400"
              lineHeight="2.25rem"
              color="neutral.light.5"
              verticalAlign="bottom"
            >
              (nsei)
            </chakra.span>
          </HStack>
        }
        tooltipLabel={<GasInfoTooltipContent gasPrices={stats.gas_prices!} />}
        isLoading={isLoading}
      />
    </Grid>
  );
};

export default memo(Stats, () => true);
