/* eslint-disable @tanstack/query/exhaustive-deps */
import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import { useMemo } from "react";
import type { IResourceError, IResourceName, IResponse } from "./resources";
import type { Params as ApiFetchParams } from "./useApiFetch";
import useApiFetch from "./useApiFetch";

export interface Params<R extends IResourceName, I = IResponse<R>, O = I>
  extends ApiFetchParams<R> {
  queryOptions?: Omit<
    UseQueryOptions<I, IResourceError, O, [string, ...string[]]>,
    "queryKey" | "queryFn"
  >;
  noMixing?: boolean;
  onStart?: AnyFunction;
  onSuccess?: (success?: I) => void;
  onError?: (error: Error) => void;
  onFinally?: AnyFunction;
  page?: number;
}

export function getResourceKey<R extends IResourceName>(
  resource: R,
  params?: {
    pathParams?: Record<
      string,
      Exclude<Primitive, object | Date> | string[] | undefined | null
    >;
    queryParams?: Record<
      string,
      Exclude<Primitive, object | Date> | string[] | undefined | null
    >;
    noMixing?: boolean;
    isInfinite?: boolean;
    page?: number;
    chain?: string;
  },
): [string, ...string[]] {
  // Create a chain of key parts
  return _.chain<string[]>([resource])
    .thru((keys) => {
      if (params?.noMixing) {
        return keys;
      }

      return _.chain(keys)
        .tap((keys) => {
          if (params?.chain) {
            keys.push(params.chain);
          }
        })
        .tap((keys) => {
          // Process pathParams if any
          if (params?.pathParams) {
            const pathKeyValuePairs = _.chain(
              params.pathParams as Record<string, string>,
            )
              .toPairs() // Convert to array of [key, value]
              .filter(
                ([key, value]) => Boolean(key) && Boolean(String(value ?? "")),
              ) // Filter out empty keys and values
              .sortBy(0) // Sort by key
              .map(([key, value]) =>
                [
                  "",
                  "path",
                  key,
                  _.castArray(value)
                    .map((x) => String(x ?? ""))
                    .join(","),
                ].join("_"),
              ) // Add prefix '_path_' to key
              .value(); // Get the result

            // Add pathKeyValuePairs to keyParts

            keys.push(...pathKeyValuePairs);
          }
        })
        .tap((keys) => {
          // Process queryParams if any
          if (params?.queryParams) {
            const queryKeyValuePairs = _.chain(params.queryParams)
              .toPairs() // Convert to array of [key, value]
              .filter(
                ([key, value]) => Boolean(key) && Boolean(String(value ?? "")),
              ) // Filter out empty keys and values
              .sortBy(0) // Sort by key
              .map(([key, value]) =>
                [
                  "",
                  "query",
                  key,
                  _.castArray(value)
                    .map((x) => String(x ?? ""))
                    .join(","),
                ].join("_"),
              ) // Add prefix '_query_' to key
              .value(); // Get the result

            // Add queryKeyValuePairs to keyParts

            keys.push(...queryKeyValuePairs);
          }
        })
        .tap((keys) => {
          if (!params?.page) return;

          keys.push(`_page_${params.page}_`);
        })
        .tap((keys) => {
          if (params?.isInfinite) {
            keys.push("_infinite_");
          }
        })
        .value();
    })
    .value() as [string, ...string[]];
}

export default function useApiQuery<
  R extends IResourceName,
  I extends IResponse<R> = IResponse<R>,
  O = I,
>(
  resource: R,
  {
    queryOptions,
    pathParams,
    queryParams,
    fetchParams,
    configs,
    page,
    noMixing,
    onFinally,
    onStart,
    onSuccess,
    onError,
  }: Params<R, I, O> = {},
) {
  const fetch = useApiFetch();

  const queryKey = useMemo(
    () =>
      getResourceKey(resource, {
        pathParams,
        queryParams,
        noMixing,
        page,
      }),
    [resource, pathParams, queryParams, noMixing, page],
  );

  // @ts-ignore
  const query = useQuery<unknown, undefined, unknown>({
    queryKey: queryKey,
    queryFn: async ({ signal }) => {
      onStart?.();
      // all errors and error typing is handled by react-query
      // so error response will never go to the data
      // that's why we are safe here to do type conversion "as Promise<T>"
      // @ts-ignore
      return fetch(resource, {
        pathParams,
        queryParams,
        fetchParams,
        configs: { signal, ...configs },
      } as never)
        .then((success) => {
          onSuccess?.(success as never);
          return success as never;
        })
        .catch((error) => {
          onError?.(error as never);
          return Promise.reject(error);
        })
        .finally(onFinally) as never;
    },
    staleTime: 1000 * 60 * 3, // 3 minutes
    ...(queryOptions as object),
    // placeholderData: (previousData: any) => {
    //   return previousData || queryOptions?.placeholderData;
    // },
    // enabled: freezeContext?.isFreeze ? false : (queryOptions?.enabled as any),
  });

  return query as UseQueryResult<O, IResourceError>;
}

// export async function requestApiFetch<R extends ResourceName, E = unknown>(
//   resource: R,
//   { pathParams, queryParams, fetchParams }: Params<R, E> = {},
// ) {
//   const apiFetch = useApiFetch();

//   return apiFetch(resource, {
//     pathParams,
//     queryParams,
//     fetchParams,
//   });
// }

// export function useRequest<R extends IResourceName>(
//   resource: R,
//   {
//     pathParams,
//     queryParams,
//     fetchParams,
//     disabled = false,
//     queryOptions,
//   }: Params<R> & { disabled?: boolean } = {},
//   watchedData: any[] = [],
//   delay = 2000,
// ): UseQueryResult<IResponse<R>> {
//   const isDisabled = disabled || queryOptions?.enabled === false;
//   const apiFetch = useApiFetch();
//   const memonizedData = useRef<any[]>([]);
//   const [queryResultData, setQueryResultData] = useState<
//     UseQueryResult<IResponse<R>, undefined>
//   >({
//     data: queryOptions?.placeholderData || null,
//     isPlaceholderData: true,
//   } as any);

//   const get = async () => {
//     const queryResult = await apiFetch(resource, {
//       pathParams,
//       queryParams,
//       fetchParams,
//     }).catch((error) => console.log(error));

//     setQueryResultData({
//       data: queryResult as any,
//       isPlaceholderData: false,
//     } as any);
//     return queryResult;
//   };

//   useEffect(() => {
//     if (isDisabled) return;
//     let handler: Timer | null = null;
//     if (JSON.stringify(watchedData) !== JSON.stringify(memonizedData.current)) {
//       handler = setTimeout(() => {
//         get();
//         memonizedData.current = watchedData;
//       }, delay);
//     }
//     return () => {
//       handler && clearTimeout(handler);
//     };
//   }, [watchedData, disabled, queryOptions?.enabled]);

//   return useMemo(
//     () =>
//       ({
//         ...queryResultData,
//         isPlaceholderData: isDisabled
//           ? false
//           : queryResultData.isPlaceholderData,
//         refetch: get,
//       }) as never,
//     [queryResultData],
//   );
// }
