"use client";

import type { IChainKey } from "configs/server/chain";
import isBrowser from "lib/isBrowser";
import { getEnvValueV2 } from "./configs";

export const chainKey = (
  isBrowser() ? globalThis.chain || "pacific-1" : "pacific-1"
) as IChainKey;

export const getEnvValueV2CommonByChainKey = (path: string) => {
  return getEnvValueV2(`common.${chainKey}.${path}`)!;
};
