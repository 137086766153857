import { Center, Flex, Text } from "@chakra-ui/react";
import React from "react";

import type { SearchResultToken } from "types/api/search";

import { TokenIcon } from "ui/shared/entities/token/TokenEntityV2";

interface Props {
  data: SearchResultToken;
}

const SearchBarSuggestToken = ({ data }: Props) => {
  // const contractVerifiedIcon = data.is_smart_contract_verified && (
  //   <IconSvg
  //     name="status/success"
  //     boxSize="14px"
  //     color="green.500"
  //     ml={1}
  //     flexShrink={0}
  //   />
  // );
  // const additionalInfo = (
  //   <Text overflow="hidden" whiteSpace="nowrap" fontWeight={700}>
  //     {data.token_type === "ERC-20" &&
  //       data.exchange_rate &&
  //       `$${Number(data.exchange_rate).toLocaleString()}`}
  //     {data.token_type !== "ERC-20" &&
  //       data.total_supply &&
  //       `Items ${Number(data.total_supply).toLocaleString()}`}
  //   </Text>
  // );

  return (
    <Flex alignItems="center" gap={5} overflow="hidden">
      <Center boxSize={8} position="relative">
        <TokenIcon
          boxSize={8}
          token={{ ...data, type: data.token_type }}
          confirmIconProps={{ boxSize: 5 }}
        />
      </Center>

      <Flex flexDirection="column" gap={1} flex={1} overflow="hidden">
        <Text
          width="full"
          isTruncated
          color="neutral.light.7"
          fontSize="0.875rem"
          fontWeight={400}
          lineHeight="1.25rem"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {data.name}
        </Text>
        <Text
          width="full"
          isTruncated
          color="neutral.light.6"
          fontSize="0.8125rem"
          fontWeight={400}
          lineHeight="1rem"
        >
          {data.address}
        </Text>
      </Flex>
    </Flex>
  );
};

export default React.memo(SearchBarSuggestToken);
