import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { queryClientConfig } from "lib/api/useQueryClientConfig";
import AppTrigger from "lib/contexts/AppTrigger";
import ChakraProvider from "lib/contexts/chakra";
import CSRFTrigger from "lib/contexts/CSRFTrigger";
import LogtoProvider from "lib/contexts/LogtoProvider";
import ReduxTrigger from "lib/contexts/ReduxTrigger";
import SocketProvider from "lib/socket/SocketProvider";
import dynamic from "next/dynamic";
import { memo, type ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import AppErrorDisruption from "ui/shared/AppError/AppErrorDisruption";
import GoogleAnalytics from "ui/shared/GoogleAnalytics";
import AppKitProvider from "ui/shared/wallet/AppKitProvider";
import CosmoskitProvider from "ui/shared/wallet/CosmoskitProvider";
import FormToast from "../shared/forms/FormToast";

const BuildHead = dynamic(() => import("ui/meta/BuildHead"), {
  ssr: false,
});

const ReduxProvider = dynamic(() => import("lib/contexts/ReduxContext"), {
  ssr: false,
});

type Props = {
  children: ReactNode;
  chain: string;
};

function InitContexts({ children, chain }: Props) {
  return (
    <ErrorBoundary
      onError={console.error}
      FallbackComponent={AppErrorDisruption}
    >
      <AppTrigger chain={chain} />

      <ReduxProvider>
        <ReduxTrigger />
        <ChakraProvider>
          <AppKitProvider>
            <CosmoskitProvider>
              <LogtoProvider>
                <QueryClientProvider client={queryClientConfig}>
                  <BuildHead />

                  <FormToast />
                  <CSRFTrigger />
                  <SocketProvider>{children}</SocketProvider>
                  <ReactQueryDevtools
                    buttonPosition="bottom-left"
                    position="left"
                  />

                  <GoogleAnalytics />
                </QueryClientProvider>
              </LogtoProvider>
            </CosmoskitProvider>
          </AppKitProvider>
        </ChakraProvider>
      </ReduxProvider>
    </ErrorBoundary>
  );
}

export default memo(InitContexts, (prev, next) => {
  return prev.chain === next.chain && prev.children === next.children;
});
