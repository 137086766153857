// lib/memory.ts

import { LRUCache } from "lru-cache";

// Create a new cache instance
const cache = new LRUCache<string, any>({
  max: 500, // Maximum 500 items
  ttl: 1000 * 60 * 5, // TTL: 5 minutes
  allowStale: false, // Do not return stale data
});

export const getCache = async (
  cacheKey: string,
  fallback: () => any,
  ttl?: number,
  options?: LRUCache.SetOptions<string, any, unknown>,
) => {
  // Check if the data is already in the cache
  const data = cache.get(cacheKey);

  if (!data) {
    console.log("Cache miss:", cacheKey);
    const next = await Promise.resolve(fallback()).catch((error) => {
      console.error(error.message);
      return undefined;
    });
    cache.set(cacheKey, next, { ...(options || {}), ttl: ttl ?? undefined });
    console.log("Data cached for:", cacheKey);
    return next;
  }

  console.log("Cache hit:", cacheKey);

  return data;
};

export const deleteCache = (cacheKey: string) => {
  cache.delete(cacheKey);
  console.log("Cache deleted:", cacheKey);
};
