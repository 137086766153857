import { getEnvValueV2 } from "configs/frontend/chain/configs";
import type { IPromotion } from "types/api/promotion";
import { create } from "zustand";

function weightedRandomSelect(arr: IPromotion[] | undefined = []): IPromotion {
  // Calculate the total sum of all ratios
  const totalWeight = arr.reduce((sum, item) => sum + item.ratio, 0);

  // Generate a random number between 0 and totalWeight
  let random = Math.random() * totalWeight;

  // Loop through the array to find the item that corresponds to the random value
  for (let i = 0; i < arr.length; i++) {
    random -= arr[i].ratio;
    if (random <= 0) {
      return arr[i]; // Return the selected item
    }
  }

  // In case no item is found, return the last one (shouldn't happen if ratios are valid)
  return arr[arr.length - 1];
}

const configs = getEnvValueV2<IPromotion[]>(`common.promotion`);

export const usePromotion = create(() => ({
  promotion: weightedRandomSelect(configs),
}));
