export const POPOVER_PROPS = [
  "ref",
  "id",
  "isOpen",
  "defaultIsOpen",
  "initialFocusRef",
  "returnFocusOnClose",
  "autoFocus",
  "closeOnBlur",
  "closeOnEsc",
  "onOpen",
  "onClose",
  "arrowSize",
  "arrowShadowColor",
  "trigger",
  "openDelay",
  "closeDelay",
  "isLazy",
  "lazyBehavior",
  "computePositionOnMount",
  "offset",
  "gutter",
  "preventOverflow",
  "flip",
  "matchWidth",
  "boundary",
  "eventListeners",
  "arrowPadding",
  "strategy",
  "placement",
  "modifiers",
  "direction",
  "borderRadius",
  "border",
];
