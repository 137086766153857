import { Button, Flex, Skeleton, type ButtonProps } from "@chakra-ui/react";
import { workspaceCallback } from "configs/frontend/chain/auth";
import useFetchProfileInfo from "lib/hooks/useFetchProfileInfo";
import { memo } from "react";

const ProfileSignIn = (props: ButtonProps) => {
  const { isLoading, data, signIn } = useFetchProfileInfo();
  if (data) return <></>;
  if (isLoading)
    return (
      <Flex
        alignItems="stretch"
        justifyContent="stretch"
        isLoaded={!isLoading}
        width="10rem"
        height="2.25rem"
        overflow="hidden"
        {...(props as any)}
      >
        <Skeleton width="full" height="full" />
      </Flex>
    );
  return (
    <Button
      variant="solid"
      width="10rem"
      height="2.25rem"
      isTruncated
      onClick={() => {
        signIn(workspaceCallback);
      }}
      {...props}
    >
      Sign In
    </Button>
  );
};

export default memo(ProfileSignIn, () => true);
