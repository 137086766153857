import { useLogto } from "@logto/react";
import { workspaceCallback } from "configs/frontend/chain/auth";
import * as cookies from "lib/cookies";
import { memo, useCallback } from "react";
import ProfileNavButton from "./ProfileNavButton";
type Props = {
  isLoading?: boolean;
  onClose?: () => void;
};

const ProfileSignOutButton = ({ isLoading, onClose }: Props) => {
  const { signOut } = useLogto();

  const handleSingOutClick = useCallback(() => {
    cookies.deleteKey(cookies.NAMES.API_TOKEN);
    signOut(workspaceCallback);
  }, [signOut]);

  return (
    <ProfileNavButton
      title="Sign out"
      icon="sign-out"
      height="3rem"
      padding={3}
      gap={3}
      variant="unstyled"
      color="accent.red"
      borderRadius="0.5rem"
      display="flex"
      alignItems="center"
      _hover={{
        backgroundColor: "primary.light.1",
      }}
      isLoading={isLoading}
      onClick={() => {
        handleSingOutClick();
        onClose?.();
      }}
    ></ProfileNavButton>
  );
};

export default memo(ProfileSignOutButton, (prev, next) => {
  return prev.isLoading === next.isLoading && prev.onClose === next.onClose;
});
