import { useMemo } from "react";

import useApiQuery from "lib/api/useApiQuery";
import type { UHomepageIndicatorId } from "./types";
import indicators from "./utils/indicators";

export default function useFetchChartData(indicatorId: UHomepageIndicatorId) {
  const homepageChartTxsQuery = useApiQuery("homepage_chart_txs", {
    queryOptions: { enabled: indicatorId === "daily_txs" },
  });
  const homepageChartMarketQuery = useApiQuery("homepage_chart_market", {
    queryOptions: {
      enabled: indicatorId === "coin_price" || indicatorId === "market_cap",
    },
  });

  return (
    (indicatorId === "coin_price" &&
      useMemo(() => {
        return {
          ...homepageChartMarketQuery,
          data: homepageChartMarketQuery.data
            ? indicators["coin_price"].api.dataFn(homepageChartMarketQuery.data)
            : undefined,
        };
      }, [homepageChartMarketQuery.data, indicatorId])) ||
    (indicatorId === "market_cap" &&
      useMemo(() => {
        return {
          ...homepageChartMarketQuery,
          data: homepageChartMarketQuery.data
            ? indicators["market_cap"].api.dataFn(homepageChartMarketQuery.data)
            : undefined,
        };
      }, [homepageChartMarketQuery.data, indicatorId])) ||
    useMemo(() => {
      return {
        ...homepageChartTxsQuery,
        data: homepageChartTxsQuery.data
          ? indicators["daily_txs"].api.dataFn(homepageChartTxsQuery.data)
          : undefined,
      };
    }, [homepageChartTxsQuery.data, indicatorId])
  );
}
