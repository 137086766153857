import {
  Box,
  Button,
  Flex,
  forwardRef,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import useApiFetch from "lib/api/useApiFetch";
import highlightText from "lib/highlightText";
import type { ForwardedRef } from "react";
import { useCallback, useEffect, useRef } from "react";
import type { SubmitErrorHandler, SubmitHandler } from "react-hook-form";
import { Controller, FormProvider, useForm } from "react-hook-form";
import PhotoViewer from "ui/shared/PhotoViewer";
import UserAvatar from "ui/shared/UserAvatar";
import type { IProfilePhotoForm } from "./account/types";
import { userSettingsSchema } from "./account/utils";
import ProfileAccountSection from "./ProfileAccountSection";

type Props = {
  src?: string;
  isLoading?: boolean;
  isSocialLogin?: boolean;
  onRemove?: () => void;
  onUpload?: (file: File | undefined) => void;
  onSubmitted?: (data: any) => void;
};

const ProfilePhoto = (
  { isLoading, isSocialLogin, src, onUpload, onSubmitted, ...props }: Props,
  ref?: ForwardedRef<HTMLDivElement>,
) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const formApi = useForm<IProfilePhotoForm>({
    defaultValues: {
      files: [],
    },
    resolver: zodResolver(userSettingsSchema),
  });
  const toast = useToast();

  const { control } = formApi;
  const { handleSubmit, reset, setValue, setError, formState } = formApi;
  const apiFetch = useApiFetch();
  useEffect(() => {
    reset({
      files: [],
    });
  }, [src]);

  const onValid: SubmitHandler<IProfilePhotoForm> = useCallback(
    async (data) => {
      const body = new FormData();
      body.append("file", data.files?.[0]);

      await apiFetch("workspace_update_avatar", {
        fetchParams: {
          method: "PATCH",
          body: body,
        },
      })
        .then((success) => {
          Promise.resolve(onSubmitted?.(success))?.catch(console.log);
          toast({
            position: "top-right",
            title: "Success",
            description: "Updated your avatar successfully.",
            status: "success",
            variant: "subtle",
            isClosable: true,
          });
        })
        .catch((error) => {
          console.warn("Error updating avatar", error);
          toast({
            position: "top-right",
            title: "Error",
            description: (
              <>
                Can not update your avatar.
                <br />
                Please try again.
              </>
            ),
            status: "error",
            variant: "subtle",
            isClosable: true,
          });
        });
    },
    [],
  );
  const onError: SubmitErrorHandler<IProfilePhotoForm> = useCallback(
    (errors) => {
      console.log(errors);
    },
    [],
  );

  const handleRemove = useCallback(async () => {
    await apiFetch("workspace_update_avatar", {
      fetchParams: {
        method: "DELETE",
      },
    })
      .then((success) => {
        Promise.resolve(onSubmitted?.(success))?.catch(console.log);
        toast({
          position: "top-right",
          title: "Success",
          description: "Updated your avatar successfully.",
          status: "success",
          variant: "subtle",
          isClosable: true,
        });
      })
      .catch((error) => {
        console.warn("Error updating avatar", error);
        toast({
          position: "top-right",
          title: "Error",
          description: (
            <>
              Can not update your avatar.
              <br />
              Please try again.
            </>
          ),
          status: "error",
          variant: "subtle",
          isClosable: true,
        });
      });
  }, []);

  return (
    <FormProvider {...formApi}>
      <PhotoViewer isOpen={isOpen} onClose={onClose}>
        <Box
          position="relative"
          cursor="pointer"
          aspectRatio={1}
          maxHeight="60dvh"
          maxWidth="60vw"
          minWidth="300px"
          minHeight="300px"
          backgroundColor="neutral.light.1"
        >
          <UserAvatar
            boxSize="full"
            maxHeight="full"
            maxWidth="full"
            hasWrapper={false}
            src={src}
            alt="profile_avatar_photo_fullscreen"
            objectFit="scale-down"
          ></UserAvatar>
          <Box
            position="absolute"
            inset={0}
            boxSize="full"
            background="radial-gradient(circle, transparent 70.65%, rgba(0, 0, 0, 0.4) 0%)"
          ></Box>
        </Box>
      </PhotoViewer>

      <ProfileAccountSection position="relative">
        <Flex flexDirection="column" gap={4} {...props} ref={ref}>
          <UserAvatar
            src={src}
            isLoading={isLoading || formState.isSubmitting}
            wrapperProps={{
              _hover: { transform: "scale(1.1)" },
              boxSize: "7.5rem",
              cursor: "pointer",
              onClick: () => {
                if (isLoading) return;
                onOpen();
              },
            }}
          ></UserAvatar>

          <Flex flexDirection="column" gap={3}>
            <Flex alignItems="stretch" gap={3}>
              <input
                accept=".jpeg, .jpg, .png"
                ref={fileInputRef}
                type="file"
                multiple={false}
                hidden
                style={{ display: "none" }}
                onChange={(event) => {
                  try {
                    const file = event.target.files?.[0];
                    if (!file) return;

                    if (file.size > 5 * 1024 * 1024) {
                      // 5MB size limit
                      setError("files", { message: "Max: 5 Mb" });
                      return;
                    }

                    const img = new Image();
                    img.src = URL.createObjectURL(file);

                    img.onload = () => {
                      if (img.width < 240 || img.height < 240) {
                        setError("files", {
                          message: "(Minimum size: 240px x 240px)",
                        });
                        return false;
                      }
                      const files = [file];
                      setValue("files", files);

                      Promise.resolve(onUpload?.(file))?.catch(console.log);
                      handleSubmit(() => onValid({ files }), onError)(event);
                    };
                  } catch (error) {
                    toast({
                      position: "top-right",
                      title: "Error",
                      description: (
                        <>
                          Can not update your avatar.
                          <br />
                          Please try again.
                        </>
                      ),
                      status: "error",
                      variant: "subtle",
                      isClosable: true,
                    });
                  } finally {
                    event.target.value = null as any;
                  }
                }}
              />

              <Button
                variant="tertiary"
                width="fit-content"
                order={1}
                onClick={() => !isSocialLogin && fileInputRef.current?.click()}
                isLoading={isLoading || formState.isSubmitting}
                cursor={isSocialLogin ? "not-allowed" : "pointer"}
                {...(isSocialLogin
                  ? {
                      _hover: { bg: "neutral.light.3" },
                    }
                  : {})}
              >
                {isSocialLogin ? (
                  <Tooltip label="Not support for social login">
                    Upload photo
                  </Tooltip>
                ) : (
                  "Upload photo"
                )}
              </Button>
              <Button
                variant="tertiary"
                width="fit-content"
                order={2}
                onClick={handleSubmit(handleRemove)}
                isLoading={isLoading || formState.isSubmitting}
                isDisabled={Boolean(!src)}
                _hover={{ bg: "neutral.light.4" }}
              >
                Remove photo
              </Button>
            </Flex>
            <Controller
              name="files"
              control={control}
              render={({ fieldState: { error } }) => {
                return (
                  <Text
                    textStyle="8125"
                    color="neutral.light.6"
                    sx={{
                      "& mark": {
                        backgroundColor: "unset",
                        color: "accent.red",
                      },
                    }}
                    dangerouslySetInnerHTML={{
                      __html: highlightText(
                        "Max: 5 Mb (Minimum size: 240px x 240px)",
                        error?.message || "",
                      ),
                    }}
                  ></Text>
                );
              }}
            ></Controller>
          </Flex>
        </Flex>
      </ProfileAccountSection>
    </FormProvider>
  );
};

export default forwardRef(ProfilePhoto);
