import { useShallowEffect, useShallowIsMounted } from "lib/hooks/useShallow";
import { useRef, type ComponentType } from "react";
import ConsumerLazy from "./ConsumerLazy";

type Props = {
  isActive: boolean;
  inputs: Record<string, any>;
  view: ComponentType;
};

const DistributeLazy = ({ isActive, inputs, view }: Props) => {
  const isMounted = useShallowIsMounted([isActive]);
  const registerRef = useRef<{
    initProps: Record<string, unknown>;
    setProps: AnyFunction | null;
  }>({
    initProps: inputs,
    setProps: null,
  });

  useShallowEffect(() => {
    if (!isActive) return;

    registerRef.current?.setProps?.(inputs);
  }, [inputs, isActive]);

  if (!isMounted) return <></>;

  return (
    <ConsumerLazy registerRef={registerRef} view={view} isActive={isActive} />
  );
};
export default DistributeLazy;
