import React from "react";

import type { SearchResultItem } from "types/api/search";

import { route } from "nextjs-routes";

import LinkInternal from "ui/shared/LinkInternal";
import SearchBarSuggestAddress from "./SearchBarSuggestAddress";
import SearchBarSuggestBlock from "./SearchBarSuggestBlock";
import SearchBarSuggestLabel from "./SearchBarSuggestLabel";
import SearchBarSuggestToken from "./SearchBarSuggestToken";
import SearchBarSuggestTx from "./SearchBarSuggestTx";

interface Props {
  data: SearchResultItem;
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

const SearchBarSuggestItem = ({ data, onClick }: Props) => {
  const url = (() => {
    switch (data.type) {
      case "token": {
        return route({
          pathname: "/token/[...slug]",
          query: { slug: [data.address] },
        });
      }
      case "contract":
      case "address":
      case "label": {
        return route({
          pathname: "/address/[hash]",
          query: {
            hash: data.address?.startsWith("/factory")
              ? data.address.split("/")[1]
              : data.address,
          },
        });
      }
      case "transaction": {
        return route({ pathname: "/tx/[hash]", query: { hash: data.tx_hash } });
      }
      case "block": {
        return route({
          pathname: "/block/[height_or_hash]",
          query: { height_or_hash: String(data.block_number) },
        });
      }
    }
  })();

  const content = (() => {
    switch (data.type) {
      case "token": {
        return <SearchBarSuggestToken data={data} />;
      }
      case "contract":
      case "address": {
        return <SearchBarSuggestAddress data={data} />;
      }
      case "label": {
        return <SearchBarSuggestLabel data={data} />;
      }
      case "block": {
        return <SearchBarSuggestBlock data={data} />;
      }
      case "transaction": {
        return <SearchBarSuggestTx data={data} />;
      }
    }
  })();

  return (
    <LinkInternal
      href={url}
      onClick={onClick}
      paddingY={3}
      paddingX={4}
      display="flex"
      rowGap={2}
      _hover={{
        backgroundColor: "primary.light.1",
      }}
    >
      {content}
    </LinkInternal>
  );
};

export default React.memo(SearchBarSuggestItem);
