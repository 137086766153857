import { chainKey } from "configs/frontend/chain/utils";
import { rawFetch, type IFetchError } from "lib/hooks/useFetch";
import { memo, type ErrorInfo, type ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import AppError from "./AppError";

interface Props {
  children: ReactNode;
}

const logError = (
  error: Error | IFetchError | undefined,
  info: ErrorInfo | undefined,
) => {
  // Do something with the error, e.g. log to an external API
  console.log("error:", error);
  console.log("info:", info);
  rawFetch("/api/log/collect", {
    method: "POST",
    body: {
      chain: chainKey,
      url: (error as IFetchError)?.config?.url,
      payload: (error as IFetchError)?.payload,
      status: (error as IFetchError)?.status,
      info: info?.componentStack,
    },
  });
};

const AppErrorBoundary = ({ children }: Props) => {
  return (
    <ErrorBoundary FallbackComponent={AppError} onError={logError}>
      {children}
    </ErrorBoundary>
  );
};

export default memo(AppErrorBoundary, (prev, next) => {
  return prev.children === next.children;
});
