import type { Route } from "nextjs-routes";

export const DEFAULT_TEMPLATE =
  "sei, explorer, sei coin, seiscan, blockchain, crypto, currency, sei blockchain explorer";

export const KEYWORD_MAP: Partial<Record<Route["pathname"], string>> = {
  "/": DEFAULT_TEMPLATE,
  "/validators":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, currency, validator, sei blockchain explorer",
  get "/validator/[hash]"() {
    return this["/validators"];
  },

  "/proposals":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, currency, governance, proposals, sei blockchain explorer",
  get "/proposal/[id]"() {
    return this["/proposals"];
  },
  "/txs":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, currency, transactions, sei blockchain explorer",
  "/tx/[hash]":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, currency, transaction detail, txhash, {{hash}}, sei blockchain explorer",
  get "/blocks"() {
    return this["/txs"];
  },
  "/block/[height_or_hash]":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, currency, transaction details, {{height_or_hash}}, sei blockchain explorer",
  "/ibc-relayers":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, currency, ibc relayer, sei blockchain explorer",
  "/ibc-relayer/[channel_id]/counterparty/[counterparty_channel_id]":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, currency, ibc relayer, channel, sei blockchain explorer",

  "/address/[hash]":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, currency, top account, channel, {{hash}}, sei blockchain explorer",
  "/accounts":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, currency, top account, channel, sei blockchain explorer",
  "/verified-contracts":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, currency, verified contracts, sei blockchain explorer",
  get "/verified-contracts-native"() {
    return this["/verified-contracts"];
  },
  "/code-ids":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, currency, contracts, code ids, sei blockchain explorer",
  get "/code-id/[id]"() {
    return this["/code-ids"];
  },

  "/tokens":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, currency, tokens, sei blockchain explorer",
  "/nfts":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, currency, nfts, sei blockchain explorer",
  "/hybrids":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, erc-404, currency, tokens, sei blockchain explorer",

  "/insights":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, currency, apis, api, insights, sei blockchain explorer",
  get "/insights-docs"() {
    return this["/insights"];
  },
  "/stats":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, currency, charts, statistics, sei blockchain explorer",
  "/contract-verification":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, currency, verify contract, contract, sei blockchain explorer",
  "/parameters":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, currency, parameters, sei blockchain explorer",
  "/claim-reward":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, currency, rewards, airdrop, sei blockchain explorer",
  get "/claim-reward/[id]"() {
    return this["/claim-reward"];
  },
  "/token/[...slug]":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, currency, tokens, nfts, token, nft, collection, {{token_name}}, {{token_symbol}}, sei blockchain explorer",

  "/verified-assets":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, currency, tokens, verified assets",

  "/tool/wallet-profile":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, wallet profile, sei blockchain explorer",
  "/tool/multisender":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, multisender, distribute tokens, sei blockchain explorer",

  "/about":
    "sei, explorer, sei coin, seiscan, blockchain, crypto, about us, seitrace sei blockchain explorer",
};
