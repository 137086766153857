import type {
  FlexProps,
  TextProps,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { Box, Flex, Skeleton, Text } from "@chakra-ui/react";
import { memo } from "react";
import type { WorkspaceUserInfo } from "types/api/account";
import ActionToggle from "ui/shared/button/ActionToggle";
import type { OptimizationImageProps } from "ui/shared/OptimizationImage";
import UserAvatar from "ui/shared/UserAvatar";

type Props = {
  isLoading?: boolean;
  data: WorkspaceUserInfo | undefined;
  avatarProps?: Partial<OptimizationImageProps>;
  hasToggle?: boolean;
  nameProps?: Partial<TextProps>;
  emailProps?: Partial<TextProps>;
  onClickName?: TextProps["onClick"];
} & Partial<FlexProps> &
  Partial<Pick<UseDisclosureReturn, "isOpen" | "onClose">>;

const ProfileAvatar = ({
  isLoading,
  avatarProps,
  data,
  hasToggle = true,
  nameProps,
  emailProps,
  isOpen,
  onClose,
  onClickName,
  ...props
}: Props) => {
  return (
    <Flex
      gap={4}
      paddingX={{ base: 2, lg: 3 }}
      width="full"
      justifyContent="space-between"
      alignItems="center"
      overflow="hidden"
      {...props}
    >
      <Flex
        gap={4}
        overflow="hidden"
        className="profile-sidebar-header_container"
        maxWidth="full"
      >
        <UserAvatar
          {...(avatarProps as any)}
          wrapperProps={{
            boxSize: "2.5rem",
            display: { lg: "none" },
            borderWidth: "0.5px",
            borderRadius: "full",
            overflow: "hidden",
            borderColor: "accent.orange",
            ...((avatarProps?.wrapperProps || {}) as any),
          }}
          user={data}
        ></UserAvatar>

        <Flex
          flexDirection="column"
          gap={1}
          overflow="hidden"
          maxWidth="100%"
          className="profile-sidebar-header_text"
        >
          {(data?.name || data?.username || data?.email || isLoading) && (
            <Skeleton
              isLoaded={!isLoading}
              className="profile-sidebar-header-name_skeleton"
              overflow="hidden"
              maxWidth="full"
            >
              <Text
                isTruncated
                cursor="pointer"
                color="neutral.light.8"
                textStyle="87500"
                fontWeight={500}
                onClick={onClickName}
                {...nameProps}
              >
                {data?.name || data?.username}
              </Text>
            </Skeleton>
          )}

          {(data?.email || isLoading) && (
            <Skeleton isLoaded={!isLoading}>
              <Text color="primary.light.7" textStyle="875" {...emailProps}>
                {data?.email}
              </Text>
            </Skeleton>
          )}
        </Flex>
      </Flex>

      {hasToggle && (
        <Box display={{ lg: "none" }} height="full">
          <ActionToggle
            display={{ lg: "none" }}
            isOpen={isOpen}
            onClick={() => {
              onClose?.();
            }}
            backgroundColor="neutral.light.3"
          ></ActionToggle>
        </Box>
      )}
    </Flex>
  );
};

export default memo(ProfileAvatar);
