export function isElementInContainer(
  containerTop: number,
  containerBottom: number,
  elementTop: number,
  elementBottom: number,
) {
  return !(elementBottom < containerTop || elementTop > containerBottom);
}

export function canScrollY(container: HTMLElement) {
  return (
    container.scrollTop + container.clientHeight < container.scrollHeight - 5
  );
}

export const regexPositiveString = /^[1-9]\d*$/;
