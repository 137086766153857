import { useLogto } from "@logto/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { workspaceResource } from "configs/frontend/chain/auth";
import buildUrl from "lib/api/buildUrl";
import type { IResourceError } from "lib/api/types";
import * as cookies from "lib/cookies";
import Router from "next/router";
import { route } from "nextjs-routes";
import { useMemo } from "react";
import type { WorkspaceUserInfo } from "types/api/account";
import { rawFetch } from "./useFetch";
import { useCacheMemo } from "./useShallow";

const STALE_TIME = 30 * 60 * 1000;

export default function useFetchProfileInfo() {
  const { isAuthenticated, getAccessToken, isLoading, clearAllTokens, signIn } =
    useLogto();

  const queryClient = useQueryClient();

  const isLogged = useCacheMemo(
    (prev) => {
      if (prev === true) return true;
      if (isLoading) return prev;
      return isAuthenticated;
    },
    [isLoading, isAuthenticated],
    {
      initial: "loading" as boolean | "loading",
    },
  );

  const response = useQuery<
    {
      accessToken: string;
      userInfo: WorkspaceUserInfo;
      isLogged: true;
    },
    IResourceError
  >({
    queryKey: ["user_info"],
    queryFn: async ({ signal }) => {
      const cached = queryClient.getQueryData(["user_info"]);
      if (cached) return cached as any;

      const accessToken = await getAccessToken(workspaceResource).catch(
        (error) => {
          clearAllTokens();
          throw error;
        },
      );

      const userInfo = await rawFetch(
        buildUrl(
          "workspace_userinfo",
          {},
          {
            access_token: accessToken,
          },
        ),
        {
          signal,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
        .then((data) => {
          queryClient.setQueryData(["workspace_userinfo"], data);
          return data;
        })
        .catch((error) => {
          if (
            error?.status === 401 &&
            (error?.payload as any)?.message === "JWT:USER::SUSPENDED"
          ) {
            clearAllTokens().then(() => {
              cookies.deleteKey(cookies.NAMES.API_TOKEN);

              Router.push(
                route({
                  pathname: "/unauthorization",
                  query: { message: error?.payload?.message },
                }),
              );
            });
          }
          return Promise.reject(error);
        });

      const options = {
        signal,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      } as const;

      await Promise.all([
        rawFetch(
          buildUrl("issue_workspace_token", {}, { access_token: accessToken }),
          options,
        ).then((data) => {
          queryClient.setQueryData(["issue_workspace_token"], data);
          return data;
        }),
        rawFetch(
          buildUrl("my_quota", {}, { access_token: accessToken }),
          options,
        ).then((data) => {
          queryClient.setQueryData(["my_quota"], data);
          return data;
        }),
      ]);

      return {
        accessToken,
        userInfo,
      } as any;
    },
    // jwt 60 mins -> stale in 30 mins -> refetch in 30 mins
    staleTime: STALE_TIME,
    enabled: isLogged === true,
    retry: false,
  });

  return useMemo(
    () => ({
      ...response,
      accessToken: response.data?.accessToken,
      data: response.data?.userInfo,
      isLogged,
      isFetched: isLogged === true && response.isFetched,
      isLoading: isLogged === "loading" || response.isLoading,
      isAuthenticated,
      signIn,
    }),
    [response, isLogged, isAuthenticated, signIn],
  );
}
