"use client";

import { getHost } from "configs/hydration/api";
import { getEnvValueV2 } from "./configs";
import { chainKey } from "./utils";

export const apiHost = getEnvValueV2(`${chainKey}.API_HOST`);
export const apiPort = getEnvValueV2(`${chainKey}.API_PORT`);
export const appHost = getHost();
export const socketEndpoint = `wss://${apiHost}${apiPort ? `:${apiPort}` : ""}`;
