import type { ChainKey } from "configs/frontend/chain/data";
import type { NextPageContext } from "next";
import type { GetServerSidePropsContext } from "nextjs-routes";
import { compile } from "path-to-regexp";
import querystring from "querystring";
import { RESOURCES } from "./mapping";
import {
  type IResourceName,
  type IResourcePathParam,
  type IResourceRootItem,
} from "./resources";

const appendNodeSlud = (resource: string, chainKey: ChainKey) => {
  const aliasSlugs = ["/api", "/gateway", "/auth", "/workspace"];

  if (aliasSlugs.some((slug) => resource.startsWith(slug))) {
    return `/${chainKey}${resource}`;
  }

  return resource;
};

export const buildUrlServer = <R extends IResourceName>(
  context: GetServerSidePropsContext | NextPageContext,
  resourceName: R,
  chainKey: ChainKey,
  pathParams?: IResourcePathParam<R>,
  queryParams?: Record<
    string,
    string | Array<string> | number | null | undefined
  >,
): string => {
  const resource = RESOURCES[resourceName] as IResourceRootItem;

  const baseUrl = resource.endpoint || context.req?.headers.host || "";
  const protocol = context.req?.headers["x-forwarded-proto"] || "http";

  const basePath = resource.basePath || "";

  const path = basePath + appendNodeSlud(resource.path, chainKey);

  const queryString = querystring.stringify(queryParams);
  const url =
    protocol +
    "://" +
    baseUrl +
    compile(path)(pathParams as any) +
    "?" +
    queryString;
  return url;
};
