"use client";

import { chainKey } from "configs/frontend/chain/utils";
import { compile } from "path-to-regexp";
import { RESOURCES } from "./mapping";
import {
  type IResourceName,
  type IResourcePathParam,
  type IResourceRootItem,
} from "./resources";

export const appendNodeSlud = (resource: string) => {
  const aliasSlugs = ["/api", "/gateway", "/auth", "/workspace"];

  if (!aliasSlugs.some((slug) => resource.startsWith(slug))) {
    return resource;
  }

  return `/${chainKey}${resource}`;
};

export default function buildUrl<R extends IResourceName>(
  resourceName: R,
  pathParams?: IResourcePathParam<R>,
  queryParams?: Record<
    string,
    string | Array<string> | number | null | undefined
  >,
): string {
  const resource = RESOURCES[resourceName] as IResourceRootItem;

  const baseUrl = window.location.origin;
  const basePath = resource.basePath || "";
  const path =
    basePath +
    (!resource.basePath?.includes("stats")
      ? appendNodeSlud(resource.path)
      : resource.path);

  // Compile the path using pathParams (if any)
  const compiledPath = compile(path)(pathParams || {});

  const url = new URL(compiledPath, baseUrl);

  // Append queryParams to the URL
  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      if (!value) return; // Skip null, undefined, or false values
      if (Array.isArray(value)) {
        value.forEach((v) => url.searchParams.append(key, String(v)));
        return;
      }
      url.searchParams.append(key, String(value));
    });
  }

  return url.toString();
}
