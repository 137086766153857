import { chakra, HStack, type StackProps } from "@chakra-ui/react";
import { keyframes } from "@chakra-ui/system";
import useApiQuery from "lib/api/useApiQuery";
import _ from "lodash";
import { useRouter } from "next/router";
import { memo } from "react";
import GasInfoTooltipContent from "ui/shared/GasInfoTooltipContent/GasInfoTooltipContent";
import IconSvg from "ui/shared/IconSvg";
import SkeletonText from "ui/shared/text/SkeletonText";
import TooltipV2 from "ui/shared/tootltip/TooltipV2";

type Props = StackProps;

const heartBeatKf = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const TopBarStats = (props: Props) => {
  const {
    data: stats,
    isFetching: statsIsFetching,
    isError: statsIsError,
  } = useApiQuery("homepage_stats", {
    queryOptions: {
      refetchOnMount: false,
    },
  });

  const {
    data: checkpoint,
    isFetching: checkpointIsFetching,
    isError: checkpointIsError,
  } = useApiQuery("checkpoints", {
    queryOptions: {
      refetchOnMount: false,
    },
  });

  const router = useRouter();
  const homePage = router.pathname === "/";

  return (
    <HStack
      data-home={homePage}
      sx={{
        "&[data-home=true]": {
          display: {
            base: "flex",
          },
        },
        display: {
          base: "none",
          lg: "flex",
        },
      }}
      color="neutral.light.6"
      textStyle="8125"
      spacing={4}
      {...props}
    >
      <HStack spacing={1}>
        <SkeletonText isLoading={checkpointIsFetching}>
          Checkpoint:{" "}
        </SkeletonText>
        <SkeletonText
          isLoading={checkpointIsFetching}
          color="secondary.03"
          textStyle="75"
          display="flex"
          alignItems="center"
          gap={1}
        >
          <span>{checkpoint?.tx_checkpoint.toLocaleString()}</span>
          <chakra.span animation={`${heartBeatKf} 3s ease-in-out infinite`}>
            {_.chain(checkpoint)
              .thru((checkpoint) => {
                if (checkpointIsError) return "🔴";
                if (!checkpoint) return "-";
                if (checkpoint.latest_block - checkpoint.tx_checkpoint >= 200)
                  return "🟠";
                if (checkpoint.latest_block - checkpoint.tx_checkpoint < 200)
                  return "🟢";
                return "-";
              })
              .value()}
          </chakra.span>
        </SkeletonText>
      </HStack>

      <HStack
        display={{
          base: "none",
          lg: "flex",
        }}
        spacing={1}
      >
        <SkeletonText isLoading={statsIsFetching}>SEI Price:</SkeletonText>
        <SkeletonText
          color="secondary.03"
          textStyle="75"
          isLoading={statsIsFetching}
        >
          $
          {Number(stats?.coin_price).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 6,
          })}
        </SkeletonText>
      </HStack>

      <HStack
        display={{
          base: "none",
          md: "flex",
        }}
        spacing={1}
      >
        <IconSvg
          isLoading={statsIsFetching}
          color="inherit"
          name="gas"
          boxSize={3}
          flexShrink={0}
        />
        <SkeletonText isLoading={statsIsFetching}>Gas: </SkeletonText>
        <SkeletonText
          isLoading={statsIsFetching}
          color="secondary.03"
          textStyle="75"
        >
          <TooltipV2
            isDisabled={
              statsIsFetching || statsIsError || !stats?.gas_prices?.average
            }
            label={<GasInfoTooltipContent gasPrices={stats?.gas_prices} />}
          >
            <span>{stats?.gas_prices?.average} nsei</span>
          </TooltipV2>
        </SkeletonText>
      </HStack>
    </HStack>
  );
};

export default memo(TopBarStats, () => true);
